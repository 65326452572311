import React from 'react';
import ReactTable from 'react-table';
import withFixedColumns from "react-table-hoc-fixed-columns";
import 'react-table/react-table.css';
import '../../styles/tables.css';
import 'react-table-hoc-fixed-columns/lib/styles.css';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const shouldShowPagination = (showPagination, resultCount, pageSize) => {
  if (showPagination !== undefined ? showPagination : true) {
    return resultCount > pageSize;
  }
  return false;
};

const DeprecatedBaseTable = ({
  className,
  columns,
  data,
  defaultFilterMethod,
  defaultSorted,
  defaultSortDesc,
  filterable,
  filtered,
  filteredArgs,
  fixed,
  loading,
  onFetchData,
  onFilteredChange,
  pages,
  resizable,
  resultCount,
  showPagination,
  style,
  tableRef,
}) => {
  const pageSize = 20;

  return (
    fixed
      ? (
        <ReactTableFixedColumns
          manual
          className={`-highlight ${className}`}
          loadingText="Cargando..."
          pageSize={pageSize}
          defaultPageSize={10}
          minRows={1}
          pageText="Página"
          previousText="Anterior"
          nextText="Siguiente"
          noDataText="Sin resultados"
          rowsText="Filas"
          multiSort={false}
          showPageSizeOptions={false}
          showPagination={shouldShowPagination(showPagination, resultCount, pageSize)}
          ref={tableRef}
          resizable={resizable}
          data={data}
          loading={loading}
          pages={pages}
          filterable={filterable}
          filtered={filtered}
          filteredArgs={filteredArgs}
          columns={columns}
          defaultFilterMethod={defaultFilterMethod}
          defaultSorted={defaultSorted}
          defaultSortDesc={defaultSortDesc || false}
          onFetchData={onFetchData}
          onFilteredChange={onFilteredChange}
          getTheadProps={() => ({
            style: { background: '#EFEFEF' },
          })}
          getTheadThProps={(state, rowInfo, column) => (
            column.id !== 'tipo_endoso' ?
              { style: { border: 'none', padding: '12px' }}
              : { style: { padding: '12px' }}
          )}
          getTrGroupProps={() => ({
            style: { borderBottom: '1px solid #CCCCCC' },
          })}
          getTdProps={(state, rowInfo, column) => (
            column.id !== 'tipo_endoso' ? { style: { border: 'none' }} : {}
          )}
          getTableProps={() => ({
            style: { overflowX: 'scroll' },
          })}
        />
      ) : (
        <ReactTable
          manual
          className={`-highlight ${className}`}
          loadingText="Cargando..."
          pageSize={pageSize}
          defaultPageSize={10}
          minRows={1}
          pageText="Página"
          previousText="Anterior"
          nextText="Siguiente"
          noDataText="Sin resultados"
          rowsText="Filas"
          multiSort={false}
          showPageSizeOptions={false}
          showPagination={shouldShowPagination(showPagination, resultCount, pageSize)}
          ref={tableRef}
          resizable={resizable}
          data={data}
          loading={loading}
          pages={pages}
          filterable={filterable}
          filtered={filtered}
          filteredArgs={filteredArgs}
          columns={columns}
          defaultFilterMethod={defaultFilterMethod}
          defaultSorted={defaultSorted}
          defaultSortDesc={defaultSortDesc || false}
          onFetchData={onFetchData}
          onFilteredChange={onFilteredChange}
          getTheadProps={() => ({
            style: { background: '#EFEFEF' },
          })}
          getTheadThProps={() => ({
            style: {
              border: 'none',
              padding: '12px',
            },
          })}
          getTrGroupProps={() => ({
            style: { borderBottom: '1px solid #CCCCCC' },
          })}
          getTdProps={() => ({
            style: { border: 'none' },
          })}
          getTableProps={() => ({
            style: { overflowX: 'scroll' },
          })}
          style={style}
        />
      )
  );
};

DeprecatedBaseTable.propTypes = [];

export default DeprecatedBaseTable;
