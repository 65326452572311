import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileContract, faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';
import { fileDownload, getEstadosUrl } from '../../../helpers/helpers';
import { API, BASE_URL } from '../../../CONST';
import MovimientosTable from './MovimientoTable';

const EstadoDetailMovimientos = ({ id, date }) => {
  const downloadUrl = `${BASE_URL}${API.estados_diarios}`;
  return (
    <div id="movimientos" className="table-container">
      <Row>
        <Col md="7" className="title-container">
          <h5>Movimientos</h5>
        </Col>
        <Col className="d-flex justify-content-end">
          <div className="button-container contable">
            <Button
              className="btn btn-orsan"
              onClick={() => fileDownload(getEstadosUrl(downloadUrl, id, 'download'))}
            >
              <FontAwesomeIcon icon={faFileInvoiceDollar} />
              <span className="text">Descargar Libro Prod. Contable</span>
            </Button>
          </div>
          <div className="button-container">
            <Button
              className="btn btn-orsan"
              onClick={() => fileDownload(getEstadosUrl(downloadUrl, id, 'download_libro_prod_op'))}
            >
              <FontAwesomeIcon icon={faFileContract} />
              <span className="text">Descargar Libro Prod. Operaciones</span>
            </Button>
          </div>
        </Col>
      </Row>
      <Row>
        <MovimientosTable date={date} />
      </Row>
    </div>
  );
};

EstadoDetailMovimientos.propTypes = {
  id: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
};

export default EstadoDetailMovimientos;
