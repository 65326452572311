import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ResumenContainer from '../../UI/ResumenContainer';
import Estado from '../../UI/Estado';
import { formatValue } from '../../../helpers/tables';

const moment = require('moment');

const EstadoMDetailResumen = ({ detalleEstado }) => (
  <ResumenContainer mensuales>
    <div className="resumen-column principal">
      <div className="resumen-row">
        <div className="label">Estado:</div>
        <div className="value">
          <Estado estado={detalleEstado ? detalleEstado.estado : ''} />
        </div>
      </div>
      <div className="resumen-row">
        <div className="label">Sincronización:</div>
        <div className="value">
          {detalleEstado &&
          (moment(detalleEstado.actualizacion_ts).format('DD-MM-YYYY HH:mm'))}
        </div>
      </div>
    </div>
    <div className="resumen-column">
      <div className="resumen-row">
        <div className="label">
          {`${detalleEstado && detalleEstado.moneda}:`}
        </div>
        <div className="value">
          {detalleEstado && formatValue(detalleEstado.tipo_cambio)}
        </div>
      </div>
    </div>
    <div className="resumen-column">
      <div className="resumen-row">
        <div className="label">Fecha Tasa:</div>
        <div className="value">
          {detalleEstado&&(detalleEstado.fecha_tasa)}
        </div>
      </div>
    </div>
    <div className="resumen-column">
      <div className="resumen-row">
        <div className="label">Válido:</div>
        <div className="value">
          {detalleEstado&&(detalleEstado.valido ? 'Sí' : 'No')}
        </div>
      </div>
    </div>
  </ResumenContainer>
);

const mapStateToProps = state => {
  return {
    detalleEstado: state.estadosMensuales.detalleEstado,
  }
};

EstadoMDetailResumen.propTypes = {
  detalleEstado: PropTypes.shape({
    actualizacion_ts: PropTypes.string,
    estado: PropTypes.string,
    eur: PropTypes.number,
    fecha_tasa: PropTypes.string,
    moneda: PropTypes.string,
    prima_eur: PropTypes.number,
    prima_total_clp: PropTypes.number,
    prima_uf: PropTypes.number,
    prima_usd: PropTypes.number,
    sincronizacion: PropTypes.string,
    tipo_cambio: PropTypes.number,
    uf: PropTypes.number,
    usd: PropTypes.number,
    valido: PropTypes.bool,
  }),
};

EstadoMDetailResumen.defaultProps = {
  detalleEstado: undefined,
};

export default connect(mapStateToProps)(EstadoMDetailResumen)

