import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import BaseTable from 'components/UI/BaseTable';
import Estado from 'components/UI/Estado';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { headerOperation, cellOperation, cellNumOperation } from 'helpers/tables';
import { get } from 'helpers/requests';
import { fdate } from 'helpers/helpers';
import moment from 'moment';
import EmisionesDiariasDateFilter from './EmisionesDiariasDateFilter';

const EmisionesDiariasTable = ({ endpoint, garantia }) => {
  const currentMoment = moment();
  const [tableData, setTableData] = useState([]);
  const [filterDate, setFilterDate] = useState(currentMoment);

  useEffect(() => {
    get(`${endpoint}?month=${filterDate.format('YYYY-MM')}`).then((response) =>
      setTableData(response),
    );
  }, [filterDate, endpoint]);

  const columns = [
    {
      Header: headerOperation('Fecha'),
      id: 'fecha',
      accessor: 'fecha',
      Cell: (row) => cellOperation(fdate(row.value)),
      sortable: false,
      filterable: false,
      minWidth: 100,
    },
    {
      Header: headerOperation('Emisiones'),
      id: 'emisiones',
      accessor: 'emisiones',
      Cell: (row) => cellNumOperation(row.value),
      sortable: false,
      filterable: false,
      minWidth: 100,
    },
    {
      Header: headerOperation('Estado'),
      id: 'estado',
      accessor: (i) =>
        cellOperation(
          <Col>
            <div style={{ width: '75%', margin: 'auto' }}>
              <Estado estado={i.estado} />
            </div>
          </Col>,
        ),
      sortable: false,
      filterable: false,
      minWidth: 100,
    },
    {
      Header: headerOperation('Acciones'),
      id: 'actions',
      accessor: (i) => (
        <div
          style={{ display: 'grid', gridTemplateColumns: '1fr', gridGap: '20px' }}
          className="table-cell"
        >
          <div style={{ margin: 'auto' }}>
            {garantia ? (
              <Link
                to={`/reaseguros/garantia/emisiones_diarias/editar/${i.id}/${i.fecha}`}
                className="table-link"
              >
                <FontAwesomeIcon icon={faEdit} />
              </Link>
            ) : (
              <Link
                to={`/reaseguros/credito/emisiones_diarias/editar/${i.id}/${i.fecha}`}
                className="table-link"
              >
                <FontAwesomeIcon icon={faEdit} />
              </Link>
            )}
          </div>
        </div>
      ),
      filterable: false,
      sortable: false,
      minWidth: 80,
    },
  ];

  return (
    <div>
      <EmisionesDiariasDateFilter date={filterDate} handleDate={setFilterDate} />
      <div
        className="table-container"
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr',
          gridGap: '10px',
          marginTop: '15px',
          marginBottom: '6px',
        }}
      >
        <BaseTable
          columns={columns}
          data={tableData}
          filterable
          resizable={false}
          style={{ height: '50vh' }}
        />
      </div>
    </div>
  );
};

EmisionesDiariasTable.propTypes = {
  garantia: PropTypes.bool.isRequired,
  endpoint: PropTypes.string.isRequired,
};

export default EmisionesDiariasTable;
