import React, { useState } from 'react';
import { API, BASE_URL, PROFILES } from 'CONST';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Col } from 'reactstrap';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DeprecatedBaseTable from '../../UI/DeprecatedBaseTable';
import {
  cellNumOperation,
  cellDobleRowOperation,
  cellOperation,
  headerOperation,
} from '../../../helpers/tables';
import { patch } from '../../../helpers/requests';
import EditMovimientoModal from './EditMovimientoModal';
import isUserHasProfile from '../../../helpers/profileValidator';
import WarningModal from '../../UI/WarningModal';

const MovimientoTable = ({ estados, date }) => {
  const [modalEditOpen, setModalEditOpen] = useState(false);
  const [movimiento, setMovimiento] = useState(null);
  const [openWarningModal, setOpenWarningModal] = useState(false);
  const [warningModalMessage, setWarningModalMessage] = useState('');

  const toggleModalEdit = (row) => {
    setMovimiento(row);
    setModalEditOpen(!modalEditOpen);
  }

  const toggleWarningModal = () => {
    setOpenWarningModal(false);
  }

  const saveChanges = (monto) => {
    patch(`${BASE_URL}${API.movimientoDiarioBase}${movimiento.id}/`, { tipo_cambio: monto })
    .then(() => {
      window.location.reload()
    })
    .catch((error) => {
      if (error) {
        error.json().then((data) => {
          if ('tipo_cambio' in data) {
            setOpenWarningModal(true);
            setWarningModalMessage(data.tipo_cambio[0]);
          }
          else {
            setOpenWarningModal(true);
            setWarningModalMessage('Error al guardar cambios');
          }
        })
      }
    })
    setMovimiento(null);
  }

  const columns = [
    {
      Header: '',
      fixed: 'left',
      headerClassName: 'principal',
      columns: [
        {
          Header: headerOperation('Emisión'),
          id: 'emision',
          accessor: (i) => cellDobleRowOperation(i.fecha_emision, i.emisor_username),
          minWidth: 120,
          sortable: false,
        },
        {
          Header: headerOperation('Póliza'),
          id: 'poliza',
          accessor: (i) => cellOperation(i.poliza),
          minWidth: 120,
          sortable: false,
        },
        {
          Header: headerOperation('Tipo Endoso'),
          id: 'tipo_endoso',
          accessor: (i) => cellOperation(i.tipo_endoso),
          minWidth: 120,
          sortable: false,
        },
      ],
    },
    {
      Header: '',
      headerClassName: 'principal',
      columns: [
        {
          Header: headerOperation('Contratante'),
          id: 'contratante',
          accessor: (i) => cellDobleRowOperation(i.contratante_rut, i.contratante_nombre),
          minWidth: 240,
          sortable: false,
        },
        {
          Header: headerOperation('Inicio Vigencia'),
          id: 'inicio_vigencia',
          accessor: (i) => cellOperation(i.inicio_endoso),
          minWidth: 120,
          sortable: false,
        },
        {
          Header: headerOperation('Fin Vigencia'),
          id: 'fin_vigencia',
          accessor: (i) => cellOperation(i.fin_vigencia),
          minWidth: 120,
          sortable: false,
        },
        {
          Header: headerOperation('Moneda'),
          id: 'moneda',
          accessor: (i) => cellOperation(i.moneda),
          minWidth: 80,
          sortable: false,
        },
        {
          Header: headerOperation('Tipo Cambio'),
          id: 'tipo_cambio',
          accessor: (i) => cellNumOperation(i.tipo_cambio),
          minWidth: 120,
          sortable: false,
        },
        {
          Header: headerOperation('Capital Asegurado'),
          id: 'capital_asegurado',
          accessor: (i) => cellNumOperation(i.capital_asegurado),
          minWidth: 175,
          sortable: false,
        },
        {
          Header: headerOperation('Prima Neta Afecta'),
          id: 'prima_neta_afecta',
          accessor: (i) => cellNumOperation(i.prima_neta_afecta),
          minWidth: 175,
          sortable: false,
        },
        {
          Header: headerOperation('Prima Exenta Neta'),
          id: 'prima_exenta_neta',
          accessor: (i) => cellNumOperation(i.prima_exenta_neta),
          minWidth: 175,
          sortable: false,
        },
        {
          Header: headerOperation('IVA'),
          id: 'iva',
          accessor: (i) => cellNumOperation(i.iva),
          minWidth: 80,
          sortable: false,
        },
        {
          Header: headerOperation('Prima Neta Afecta Cedida'),
          id: 'prima_neta_afecta_cedida',
          accessor: (i) => cellNumOperation(i.prima_neta_afecta_cedida),
          minWidth: 175,
          sortable: false,
        },
        {
          Header: headerOperation('Descuento Cesión'),
          id: 'descuento_cesion',
          accessor: (i) => cellNumOperation(i.descuento_cesion),
          minWidth: 175,
          sortable: false,
        },
        {
          Header: headerOperation('Impuesto Cesión'),
          id: 'impuesto_cesion',
          accessor: (i) => cellNumOperation(i.impuesto_cesion),
          minWidth: 175,
          sortable: false,
        },
        {
          Header: headerOperation('Tipo Intermediario'),
          id: 'tipo_intermediario',
          accessor: (i) => cellOperation(i.tipo_intermediario),
          minWidth: 175,
          sortable: false,
        },
        {
          Header: headerOperation('Comisión'),
          id: 'comision',
          accessor: (i) => cellNumOperation(i.comision),
          minWidth: 175,
          sortable: false,
        },
        {
          Header: headerOperation('Intermediario'),
          id: 'intermediario',
          accessor: (i) => cellDobleRowOperation(i.intermediario_rut, i.intermediario_nombre),
          minWidth: 240,
          sortable: false,
        },
      ],
    },
  ];

  if (isUserHasProfile([PROFILES.ceo, PROFILES.gerenteOperaciones, PROFILES.analistaOperaciones])) {
    columns[0].columns.unshift({
      Header: headerOperation('Acciones'),
      id: 'acciones',
      accessor: (i) => cellOperation(
        <div style={{ textAlign: 'center' }} title="Editar tipo cambio">
          <Button
            className="table-link"
            id="editTasaCambio"
            onClick={() => toggleModalEdit(i)}
          >
            <FontAwesomeIcon icon={faEdit} />
          </Button>
        </div>
      ),
      minWidth: 80,
      sortable: false,
    });
  }

  return (
    <>
      <Col className="table-container">
        <DeprecatedBaseTable
          className="fixed-table movimientos-table"
          fixed
          columns={columns}
          data={estados}
          resizable={false}
        />
      </Col>
      <EditMovimientoModal
        openModal={modalEditOpen}
        toggleModal={toggleModalEdit}
        saveChanges={saveChanges}
        date={date}
      />
      <WarningModal
        cancelBtnText="OK"
        confirmMsg="OK"
        errorMsg={warningModalMessage}
        handleClearError={() => toggleWarningModal()}
        handleOk={() => {}}
        isOpen={openWarningModal}
        toggle={toggleWarningModal}
        onlyCancelButton
      />
    </>
  );
};

MovimientoTable.propTypes = {
  estados: PropTypes.arrayOf(PropTypes.object).isRequired,
  date: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  estados: state.estados.movimientosDiarios,
});

export default connect(mapStateToProps)(MovimientoTable);
