import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap';
import { DOCS_URL, POLIZA_URL, RISK_URL, USERS_URL, WORKFLOW_URL, ANALYST_URL } from '../../CONST';
import { signout } from '../../helpers/auth';
import '../../styles/navbar.css';
import isUserHasProfile from '../../helpers/profileValidator';
import {
  CIERRE_DIARIO_READ,
  CIERRE_OPERACIONAL_READ,
  REASEGURO_READ,
} from '../../helpers/profilePermission';

class ReaseguroNavBar extends Component {
  constructor(props) {
    super(props);

    this.toggleApps = this.toggleApps.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.state = {
      appsOpen: false,
      cierresDiarios: false,
    };
  }

  handleSignOut = (event) => {
    event.preventDefault();
    signout();
  };

  toggleApps() {
    const { appsOpen } = this.state;
    this.setState({
      appsOpen: !appsOpen,
    });
  }

  toggleDropdown(e) {
    e.preventDefault();
    const { cierresDiarios } = this.state;
    this.setState({
      cierresDiarios: !cierresDiarios,
    });
  }

  render() {
    const { user } = this.props;
    const { appsOpen } = this.state;
    let canAccesGarantia = false;

    if (user && user.profiles) {
      canAccesGarantia =
        isUserHasProfile(CIERRE_DIARIO_READ) ||
        isUserHasProfile(CIERRE_OPERACIONAL_READ) ||
        isUserHasProfile(CIERRE_OPERACIONAL_READ);
    }

    return (
      user && (
        <>
          <ul className="nav-links-group">
            <Dropdown nav className="nav-link-element" isOpen={appsOpen} toggle={this.toggleApps}>
              <DropdownToggle nav caret className="navbar-header">
                REASEGURO
              </DropdownToggle>
              <DropdownMenu>
                {user.apps.includes('users') && (
                  <DropdownItem>
                    <a className="nav-link navbar-header" href={USERS_URL}>
                      USUARIOS
                    </a>
                  </DropdownItem>
                )}
                {user.apps.includes('docs') && (
                  <DropdownItem>
                    <a className="nav-link navbar-header" href={DOCS_URL}>
                      DOCUMENTOS
                    </a>
                  </DropdownItem>
                )}
                {user.apps.includes('policies') && (
                  <DropdownItem>
                    <a className="nav-link navbar-header" href={POLIZA_URL}>
                      PÓLIZAS
                    </a>
                  </DropdownItem>
                )}
                {user.apps.includes('accountant') && (
                  <DropdownItem>
                    <a className="nav-link navbar-header" href='/reservas'>
                      RESERVAS
                    </a>
                  </DropdownItem>
                )}
                {user.apps.includes('risk') && (
                  <DropdownItem>
                    <a className="nav-link navbar-header" href={RISK_URL}>
                      RIESGO
                    </a>
                  </DropdownItem>
                )}
                {user.apps.includes('analista') && (
                  <DropdownItem>
                    <a className="nav-link navbar-header" href={ANALYST_URL}>
                      RIESGO DE CRÉDITO
                    </a>
                  </DropdownItem>
                )}
                {user.apps.includes('workflow') && (
                  <DropdownItem>
                    <a className="nav-link navbar-header" href={WORKFLOW_URL}>
                      WORKFLOW
                    </a>
                  </DropdownItem>
                )}
              </DropdownMenu>
            </Dropdown>
            {canAccesGarantia && (
              <li className="nav-link-element nav-item dropdown">
                <Link className="nav-link" to="/reaseguros/garantia/emisiones_diarias">
                  Emisiones Diarias Garantía
                </Link>
              </li>
            )}
            <li className="nav-link-element nav-item dropdown">
              <Link className="nav-link" to="/reaseguros/credito/emisiones_diarias">
                Emisiones Diarias Crédito
              </Link>
            </li>
            {user.profiles && isUserHasProfile(REASEGURO_READ) && (
              <li className="nav-link-element nav-item dropdown">
                <Link className="nav-link" to="/reaseguros/contratos">
                  Contratos
                </Link>
              </li>
            )}
            <li className="nav-link-element nav-item dropdown">
              <Link className="nav-link" to="/reaseguros/reportes">
                Reportes
              </Link>
            </li>
          </ul>
        </>
      )
    );
  }
}

ReaseguroNavBar.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string,
    username: PropTypes.string,
    full_name: PropTypes.string,
    has_access: PropTypes.bool,
    apps: PropTypes.arrayOf(PropTypes.string),
    organization: PropTypes.number,
    sub: PropTypes.string,
    profiles: PropTypes.array,
  }).isRequired,
};

const mapStateToProps = (state) => ({ user: state.login.user });

export default withRouter(connect(mapStateToProps)(ReaseguroNavBar));
