import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Button } from 'reactstrap';
import { useParams, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { get } from 'helpers/requests';
import { fdate } from 'helpers/helpers';
import DeprecatedBaseTable from 'components/UI/DeprecatedBaseTable';
import { ESTADOS } from 'CONST';
import {
  cellDobleRowOperation,
  headerDoubleRowOperation,
  cellNumOperation,
  cellOperation,
  headerOperation,
} from 'helpers/tables';
import EmisionesDiariasGarantiaModal from './EmisionesDiariasModal';

const EmisionesDiariasMovimientos = ({ garantia, endpoint }) => {
  const [detalles, setDetalles] = useState([]);
  const [displayModal, setDisplayModal] = useState(false);
  const [currentPoliza, setCurrentPoliza] = useState(false);
  const [estado, setEstado] = useState('');
  const { id } = useParams();
  const { date } = useParams();
  const history = useHistory();

  const getMovimientos = () =>
    get(`${endpoint}${id}${garantia ? '?tipo_endoso=tp-eg-emision' : ''}`).then((response) => {
      setDetalles(response.movimientos_diarios);
      setEstado(response.estado);
    });

  useEffect(() => {
    getMovimientos();
  }, [endpoint, id, garantia]);

  const handleClick = (i) => {
    setCurrentPoliza(i);
    setDisplayModal(true);
  };

  const handleCloseModal = () => {
    setDisplayModal(false);
  };

  const columns = [
    {
      Header: headerOperation('Emisión'),
      id: 'emision',
      accessor: (i) => cellDobleRowOperation(i.fecha_emision, i.emisor_username),
      minWidth: 120,
      sortable: false,
      filterable: false,
      fixed: 'left',
    },
    {
      Header: headerOperation('Póliza'),
      id: 'poliza',
      accessor: 'poliza',
      Cell: (row) => cellOperation(row.value),
      minWidth: 120,
      sortable: false,
      fixed: 'left',
    },
    {
      Header: headerOperation('Tipo Endoso'),
      id: 'tipo_endoso',
      accessor: 'tipo_endoso',
      Cell: (row) => cellOperation(row.value),
      minWidth: 120,
      sortable: false,
      fixed: 'left',
    },
    {
      Header: headerOperation(garantia ? 'Afianzado' : 'Asegurado'),
      id: 'contratante_nombre',
      accessor: 'contratante_nombre',
      Cell: (row) => cellOperation(row.value),
      sortable: false,
      minWidth: 200,
    },
  ];

  if (garantia){
    columns.push(
      {
        Header: headerOperation('Asegurado'),
        id: 'nombre_asegurado',
        accessor: 'nombre_asegurado',
        Cell: (row) => cellOperation(row.value),
        sortable: false,
        minWidth: 200,
      }
    )
  }
   
  columns.push(
    {
      Header: headerOperation('Inicio Vigencia'),
      id: 'inicio_vigencia',
      accessor: 'inicio_vigencia',
      Cell: (row) => cellOperation(fdate(row.value)),
      sortable: false,
      minWidth: 120,
    },
    {
      Header: headerOperation('Fin Vigencia'),
      id: 'fin_vigencia',
      accessor: 'fin_vigencia',
      Cell: (row) => cellOperation(fdate(row.value)),
      sortable: false,
      minWidth: 120,
    },
    {
      Header: headerOperation('Moneda'),
      id: 'moneda',
      accessor: 'moneda',
      Cell: (row) => cellOperation(row.value),
      sortable: false,
      minWidth: 100,
    },
    {
      Header: headerDoubleRowOperation('Monto', 'Garantizado'),
      id: 'capital_asegurado',
      accessor: 'capital_asegurado',
      Cell: (row) => cellNumOperation(row.value),
      sortable: false,
      minWidth: 150,
    },
    {
      Header: headerOperation('Prima'),
      id: 'prima_neta_afecta',
      accessor: 'prima_neta_afecta',
      Cell: (row) => cellNumOperation(row.value),
      sortable: false,
      filterable: false,
      minWidth: 100,
    },
    {
      Header: headerDoubleRowOperation('Contrato', 'Primera Capa'),
      id: 'contrato_reaseguro_nombre',
      accessor: 'contrato_reaseguro_nombre',
      Cell: (row) => cellOperation(row.value),
      minWidth: 180,
      sortable: false,
    },
    {
      Header: headerDoubleRowOperation('Contrato', 'Segunda Capa'),
      id: 'contratos_segunda_capa',
      accesor: 'contratos_segunda_capa',
      Cell: (row) =>
        row.original.contratos_segunda_capa.map((contrato) =>
          cellOperation(contrato.nombre_contrato),
        ),
      minWidth: 180,
      sortable: false,
    },
  );

  if ([ESTADOS.abierto, ESTADOS.enCurso].includes(estado)) {
    columns.push({
      Header: headerOperation('Acciones'),
      id: 'actions',
      accessor: (i) => (
        <div
          style={{ display: 'grid', gridTemplateColumns: '1fr', gridGap: '20px' }}
          className="table-cell"
        >
          <div style={{ margin: 'auto' }}>
            <Button
              style={{
                background: 'transparent',
                border: 'transparent',
              }}
              onClick={() => {
                handleClick(i);
              }}
            >
              <FontAwesomeIcon icon={faEdit} className="table-link" />
            </Button>
          </div>
        </div>
      ),
      filterable: false,
      sortable: false,
      minWidth: 80,
      fixed: 'right',
    });
  }

  return (
    <div className="table-container" style={{ margin: '35px 0px' }}>
      <Row>
        <Col md="7" className="title-container">
          <h5>Movimientos</h5>
        </Col>
      </Row>
      <DeprecatedBaseTable
        className="fixed-table movimientos-table"
        fixed
        data={detalles}
        columns={columns}
        resizable={false}
      />
      <EmisionesDiariasGarantiaModal
        handleOpen={displayModal}
        handleClose={() => handleCloseModal()}
        disabled={false}
        poliza={currentPoliza}
        history={history}
        id={id}
        date={date}
        garantia={garantia}
        getMovimientos={getMovimientos}
      />
    </div>
  );
};

EmisionesDiariasMovimientos.propTypes = {
  garantia: PropTypes.bool.isRequired,
  endpoint: PropTypes.string.isRequired,
};

export default EmisionesDiariasMovimientos;
