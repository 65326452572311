import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, Tooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEye,
  faFile,
  faFileAlt,
  faFileContract,
  faFileInvoice,
  faFileInvoiceDollar,
  faExclamation,
  faLock,
  faReceipt,
  faUnlockAlt,
} from '@fortawesome/free-solid-svg-icons';
import { fileDownload, getEstadosUrl, canChangeStatus } from '../../helpers/helpers';

class Acciones extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cesionOpen: false,
      contableOpen: false,
      detailOpen: false,
      lockOpen: false,
      operacionesOpen: false,
      oynrOpen: false,
      rrcOpen: false,
      voucherOpen: false,
    };
    this.toggle = this.toggle.bind(this);
    this.toggleLock = this.toggleLock.bind(this);
    this.toggleOperaciones = this.toggleOperaciones.bind(this);
    this.toggleOynr = this.toggleOynr.bind(this);
    this.toggleContable = this.toggleContable.bind(this);
    this.toggleRRC = this.toggleRRC.bind(this);
    this.toggleVoucher = this.toggleVoucher.bind(this);
    this.toggleCesion = this.toggleCesion.bind(this);
  }

  toggle() {
    const { detailOpen } = this.state;
    this.setState({
      detailOpen: !detailOpen,
    });
  }

  toggleLock() {
    const { lockOpen } = this.state;
    this.setState({
      lockOpen: !lockOpen,
    });
  }

  toggleOperaciones() {
    const { operacionesOpen } = this.state;
    this.setState({
      operacionesOpen: !operacionesOpen,
    });
  }

  toggleContable() {
    const { contableOpen } = this.state;
    this.setState({
      contableOpen: !contableOpen,
    });
  }

  toggleOynr() {
    const { oynrOpen } = this.state;
    this.setState({
      oynrOpen: !oynrOpen,
    });
  }

  toggleRRC() {
    const { rrcOpen } = this.state;
    this.setState({
      rrcOpen: !rrcOpen,
    });
  }

  toggleVoucher() {
    const { voucherOpen } = this.state;
    this.setState({
      voucherOpen: !voucherOpen,
    });
  }

  toggleCesion() {
    const { cesionOpen } = this.state;
    this.setState({
      cesionOpen: !cesionOpen,
    });
  }

  render() {
    const { handleChangeWarning, diario, node, warning, trimestral } = this.props;
    const {
      cesionOpen,
      contableOpen,
      detailOpen,
      lockOpen,
      operacionesOpen,
      oynrOpen,
      rrcOpen,
      voucherOpen,
    } = this.state;
    const estados = `estados_${diario ? 'diarios' : 'mensuales'}`;
    const fecha = diario ? node.fecha : node.periodo;

    if(trimestral) {
      return (
        <div className="actions-container">
          <div className={`button-container ${!trimestral && 'd-none'}`} id={`cuenta-tecnica-${node.id}`}>
            <Button
              className="table-link"
              onClick={() => fileDownload(getEstadosUrl(false, node.id, 'download_reporte_cuenta_tecnica', false))}
            >
              <FontAwesomeIcon icon={faFileContract} className="table-link" />
            </Button>
          </div>
          <Tooltip
            placement="right"
            isOpen={operacionesOpen}
            target={`cuenta-tecnica-${node.id}`}
            toggle={this.toggleOperaciones}
          >
            <div className="text-left">
              Descargar
              <br />
              Reporte
              <br />
              Cuenta Técnica
            </div>
          </Tooltip>
        </div>
      )
    }

    return (
      <div className="actions-container">
        <div className="button-container detail" id={`detail-${node.id}`}>
          <Link className="table-link" to={`/reservas/garantia/${estados}/${node.id}/${fecha}`}>
            <FontAwesomeIcon icon={faEye} className="table-link" />
          </Link>
        </div>
        <div
          className={`button-container lock ${!(
            node.estado === 'Cerrado' || node.estado === 'Abierto'
          ) && 'd-none'}`}
          id={`lock-${node.id}`}
        >
          {warning ? (
            <div className="warning-button">
              <FontAwesomeIcon icon={faExclamation} />
            </div>
          ) : (
            canChangeStatus(node, diario) && (
              <Button className="table-link" onClick={(e) => handleChangeWarning(e, node)}>
                <FontAwesomeIcon className="table-link" icon={node.activo ? faLock : faUnlockAlt} />
              </Button>
            )
          )}
        </div>
        <div className={`button-container ${!diario && 'd-none'}`} id={`operaciones-${node.id}`}>
          <Button
            className="table-link"
            onClick={() => fileDownload(getEstadosUrl(true, node.id, 'download_libro_prod_op'))}
          >
            <FontAwesomeIcon icon={faFileContract} className="table-link" />
          </Button>
        </div>
        <div className={`button-container ${!diario && 'd-none'}`} id={`contable-${node.id}`}>
          <Button
            className="table-link"
            onClick={() => fileDownload(getEstadosUrl(true, node.id))}
            id="libro"
          >
            <FontAwesomeIcon icon={faFileInvoiceDollar} className="table-link" />
          </Button>
        </div>
        <div className={`button-container ${diario && 'd-none'}`} id={`oynr-${node.id}`}>
          <Button
            className="table-link"
            onClick={() => fileDownload(getEstadosUrl(false, node.id, 'download_reporte_oynr'))}
          >
            <FontAwesomeIcon icon={faFileInvoice} className="table-link" />
          </Button>
        </div>
        <div className={`button-container ${diario && 'd-none'}`} id={`rrc-${node.id}`}>
          <Button
            className="table-link"
            onClick={() => fileDownload(getEstadosUrl(false, node.id, 'download_reporte_rrc'))}
          >
            <FontAwesomeIcon icon={faFileAlt} className="table-link" />
          </Button>
        </div>
        <div className={`button-container ${diario && 'd-none'}`} id={`voucher-${node.id}`}>
          <Button
            className="table-link"
            onClick={() => fileDownload(getEstadosUrl(false, node.id, 'download_voucher_contable'))}
          >
            <FontAwesomeIcon icon={faReceipt} className="table-link" />
          </Button>
        </div>
        <div className={`button-container ${diario && 'd-none'}`} id={`cesion-${node.id}`}>
          <Button
            className="table-link"
            onClick={() =>
              fileDownload(getEstadosUrl(false, node.id, 'download_reporte_desc_cesion'))
            }
          >
            <FontAwesomeIcon icon={faFile} className="table-link" />
          </Button>
        </div>
        <Tooltip
          placement="right"
          isOpen={detailOpen}
          target={`detail-${node.id}`}
          toggle={this.toggle}
        >
          Ver Detalles
        </Tooltip>
        <Tooltip
          placement="right"
          isOpen={lockOpen}
          target={`lock-${node.id}`}
          toggle={this.toggleLock}
        >
          {`${node.activo ? 'Cerrar' : 'Abrir'} ${diario ? 'día' : 'mes'}`}
        </Tooltip>
        <Tooltip
          placement="right"
          isOpen={operacionesOpen}
          target={`operaciones-${node.id}`}
          toggle={this.toggleOperaciones}
        >
          <div className="text-left">
            Descargar
            <br />
            Libro Prod.
            <br />
            Operaciones
          </div>
        </Tooltip>
        <Tooltip
          placement="right"
          isOpen={contableOpen}
          target={`contable-${node.id}`}
          toggle={this.toggleContable}
        >
          <div className="text-left">
            Descargar
            <br />
            Libro
            <br />
            Contable
          </div>
        </Tooltip>
        <Tooltip
          placement="right"
          isOpen={oynrOpen}
          target={`oynr-${node.id}`}
          toggle={this.toggleOynr}
        >
          <div className="text-left">
            Descargar
            <br />
            Reporte
            <br />
            OYNR
          </div>
        </Tooltip>
        <Tooltip
          placement="right"
          isOpen={rrcOpen}
          target={`rrc-${node.id}`}
          toggle={this.toggleRRC}
        >
          <div className="text-left">
            Descargar
            <br />
            Reporte
            <br />
            RRC
          </div>
        </Tooltip>
        <Tooltip
          placement="right"
          isOpen={voucherOpen}
          target={`voucher-${node.id}`}
          toggle={this.toggleVoucher}
        >
          <div className="text-left">
            Descargar
            <br />
            Voucher
            <br />
            Operaciones
          </div>
        </Tooltip>
        <Tooltip
          placement="right"
          isOpen={cesionOpen}
          target={`cesion-${node.id}`}
          toggle={this.toggleCesion}
        >
          <div className="text-left">
            Descargar
            <br />
            Reporte
            <br />
            Desc.
            <br />
            Cesión
          </div>
        </Tooltip>
      </div>
    );
  }
}

Acciones.propTypes = {
  diario: PropTypes.bool,
  handleChangeWarning: PropTypes.func.isRequired,
  node: PropTypes.shape({
    activo: PropTypes.bool,
    estado: PropTypes.string,
    fecha: PropTypes.string,
    id: PropTypes.number,
    periodo: PropTypes.string,
  }).isRequired,
  warning: PropTypes.bool,
  trimestral: PropTypes.bool,
};

Acciones.defaultProps = {
  diario: false,
  warning: false,
  trimestral: false,
};

export default Acciones;
