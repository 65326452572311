import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment'
import Estado from '../../UI/Estado';
import { formatValue } from '../../../helpers/tables';

const EstadoDetailResumen = ({ detalleEstado }) => (
  <div className="resumen-container">
    <h5 className="title">Resumen</h5>
    <div className="resumen-wrapper">
      <Row>
        <Col>
          <Row className="resumen-row">
            <Col xs="3" className="label">Estado:</Col>
            <Col className="value d-inline-flex">
              <Estado estado={detalleEstado ? detalleEstado.estado : ''} />
            </Col>
          </Row>
          <Row className="resumen-row">
            <Col xs="3" className="label">Sincronización:</Col>
            <Col className="value">
              {detalleEstado &&
              (`${detalleEstado.sincronizacion} - ${moment(detalleEstado.actualizacion_ts).format('DD-MM-YYYY HH:mm')}`)}
            </Col>
          </Row>
        </Col>
        <Col>
          <Row className="resumen-row">
            <Col xs="6">
              <Row>
                <Col className="resumen-flex">
                  <div className="label">Prima UF:</div>
                  <div className="value">
                    {detalleEstado &&
                    formatValue(detalleEstado.prima_uf, true)}
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs="6">
              <Row>
                <Col className="resumen-flex">
                  <div className="label">Prima EUR:</div>
                  <div className="value">
                    {detalleEstado &&
                    formatValue(detalleEstado.prima_eur, true)}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="resumen-row">
            <Col xs="6">
              <Row>
                <Col className="resumen-flex">
                  <div className="label">Prima USD:</div>
                  <div className="value">
                    {detalleEstado &&
                    formatValue(detalleEstado.prima_usd, true)}
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs="6">
              <Row>
                <Col className="resumen-flex">
                  <div className="label">Prima Total CLP:</div>
                  <div className="value">
                    {detalleEstado &&
                    formatValue(detalleEstado.prima_total_clp)}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col className="resumen-section">
          <Row className="resumen-row">
            <Col xs="6">
              <Row>
                <Col className="resumen-flex">
                  <div className="label">UF:</div>
                  <div className="value">
                    {detalleEstado &&
                    (detalleEstado.uf).toLocaleString('es-cl')}
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs="6">
              <Row>
                <Col className="resumen-flex">
                  <div className="label">EUR:</div>
                  <div className="value">
                    {detalleEstado &&
                    (detalleEstado.eur).toLocaleString('es-cl')}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="resumen-row">
            <Col xs="6">
              <Row>
                <Col className="resumen-flex">
                  <div className="label">USD:</div>
                  <div className="value">
                    {detalleEstado &&
                    (detalleEstado.usd).toLocaleString('es-cl')}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  </div>
);

EstadoDetailResumen.propTypes = {
  detalleEstado: PropTypes.shape({
    actualizacion_ts: PropTypes.string,
    estado: PropTypes.string,
    eur: PropTypes.string,
    prima_eur: PropTypes.number,
    prima_total_clp: PropTypes.number,
    prima_uf: PropTypes.number,
    prima_usd: PropTypes.number,
    sincronizacion: PropTypes.string,
    uf: PropTypes.number,
    usd: PropTypes.number,
  }),
};

EstadoDetailResumen.defaultProps = {
  detalleEstado: undefined,
};

const mapStateToProps = state => ({
  detalleEstado: state.estados.detalleEstado,
});

export default connect(mapStateToProps)(EstadoDetailResumen);
