import Numeral from 'numeral';
import moment from 'moment';

import store from '../redux-store/store/index';
import { API, BASE_URL, FRONT_DF_1} from '../CONST';
import isUserHasProfile from './profileValidator';
import {
  CIERRE_DIARIO_CEO,
  CIERRE_MENSUAL_CEO,
  CIERRE_DIARIO_WRITE,
  CIERRE_MENSUAL_WRITE,
} from './profilePermission';

export const fileDownload = (url, handleLoading) => {
  const { login } = store.getState();
  const xhr = new XMLHttpRequest();
  xhr.open('GET', url, true);
  xhr.responseType = 'blob';
  xhr.setRequestHeader('Authorization', `JWT ${login.id_token}`);
  xhr.onload = function onload() {
    if (this.status === 200) {
      const content = xhr.getResponseHeader('Content-Disposition');
      let filename = '';
      if (content.includes('filename=')) {
        filename = content.split('filename=')[1].replace(/^"+|"+$/g, '');
      } else {
        filename = decodeURI(content.split("filename*=utf-8''")[1].replace(/^"+|"+$/g, ''));
      }
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([this.response]));
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      setTimeout(() => {
        document.body.removeChild(link);
      }, 1000);
    }
    if (handleLoading) {
      handleLoading();
    }
  };
  xhr.send();
};

export const getEstadosUrl = (diario, id, type, mensual=true) => {
  let endpoint;
  if (diario) {
    endpoint = API.estados_diarios;
  } else if (mensual) {
    endpoint = API.estados_mensuales;
  } else {
    endpoint = API.estados_trimestrales;
  }
  const url = `${BASE_URL}${endpoint}`;
  let finalUrl = `${url}${id}/download/`;
  if (type) {
    finalUrl = `${url}${id}/${type}/`;
  }
  if (type === 'libro_produccion_operation_mensual' || type === 'libro_contable_mensual' || type === 'libro_contable_produccion_mensual') {
    finalUrl = `${url}${type}/${id}/`;
  }
  if (type === 'reporte_utilidades' && !mensual) {
    finalUrl = `${url}${type}`;
  }
  return finalUrl;
};

export const canChangeStatus = (node, diario) => {
  const fecha = diario ? node.fecha : node.periodo;
  const fechaMaxima = diario ? moment() : moment().startOf('month');
  const perfiles = diario ? CIERRE_DIARIO_WRITE : CIERRE_MENSUAL_WRITE;
  const perfilesCeo = diario ? CIERRE_DIARIO_CEO : CIERRE_MENSUAL_CEO;

  return moment(fecha).isBefore(fechaMaxima, 'days')
    ? isUserHasProfile(perfiles) && isUserHasProfile(perfilesCeo)
    : isUserHasProfile(perfiles);
};

export const fdate = (date, format = FRONT_DF_1) => date ? moment(date).format(format) : '';

export const roundFloat = (value, decimals = 2) => {
  const format = '00.'.padEnd(decimals + 3, '0');
  const strValue = Numeral(value).format(format).replace(',', '.');
  return parseFloat(strValue);
};
