import React from 'react';
import PropTypes from 'prop-types';

const ResumenContainer = ({ children, mensuales }) => (
  <div className="resumen-container ">
    <h5 className="title">Resumen</h5>
    <div className={`resumen-wrapper${mensuales ? ' mensuales' : ''}`}>
      {children}
    </div>
  </div>
);

ResumenContainer.propTypes = {
  children: PropTypes.node.isRequired,
  mensuales: PropTypes.bool,
};

ResumenContainer.defaultProps = {
  mensuales: false,
};

export default ResumenContainer;
