import SSO from '@unholster/sso-helper';
import store from '../redux-store/store/index';
import { LOGIN_SUCCESS, TOKEN_ERROR } from '../redux-store/constants/action-types';
import { API, BASE_OAUTH, CLIENT_ID, LOCAL_URL } from '../CONST';

const sso = new SSO({
  cookieName: 'auth_modulo_poliza',
  cookieOptions: {
    path: '/',
    secure: process.env.NODE_ENV === 'production'
  },
  issuer: BASE_OAUTH.substring(0, BASE_OAUTH.length - 1),
  authType: 'jwt',
  authPath: API.login,
  authParams: {
    scope: 'openid orsan',
    response_type: 'id_token token',
    client_id: CLIENT_ID,
    redirect_uri: LOCAL_URL,
    response_mode: 'query',
    nonce: '24i9ztwg1zi'
  },
  onExpired() {
    store.dispatch({ type: TOKEN_ERROR });
  },
  onInvalid() {
    sso.authenticate();
  },
  onAuthenticate(info) {
    store.dispatch(
      {
        type: LOGIN_SUCCESS,
        payload: {
          userToken: info.accessToken,
          idToken: info.idToken,
        },
      }
    );
  }
});

if (!sso.isAuthenticated) {
  sso.authenticate();
}

export function signout() {
  const url = `${BASE_OAUTH}${API.endSession}?id_token_hint=${sso.get().idToken}&post_logout_redirect_uri=${LOCAL_URL}&state=c91c03ea6c46a86`;
  localStorage.setItem('validToken', false);
  sso.clear();
  window.location.href = url;
}

export default sso;
