import { PROFILES } from '../CONST';

export const CIERRE_DIARIO_WRITE = [
  PROFILES.ceo,
  PROFILES.gerenteOperaciones,
  PROFILES.analistaOperaciones,
];
export const CIERRE_DIARIO_READ = [
  PROFILES.ceo,
  PROFILES.comercialGarantia,
  PROFILES.gerenteGarantia,
  PROFILES.suscripcionGarantia,
  PROFILES.gerenteRiesgoGarantia,
  PROFILES.analistaRiesgoGarantia,
  PROFILES.actuariado,
  PROFILES.gerenteOperaciones,
  PROFILES.analistaOperaciones,
  PROFILES.riesgoOperacional,
  PROFILES.siniestros,
  PROFILES.consultaBasico,
];

export const CIERRE_MENSUAL_WRITE = [PROFILES.ceo, PROFILES.actuariado];
export const CIERRE_MENSUAL_READ = [
  PROFILES.ceo,
  PROFILES.actuariado,
  PROFILES.gerenteOperaciones,
  PROFILES.siniestros,
];

export const CIERRE_DIARIO_CEO = [PROFILES.ceo];
export const CIERRE_MENSUAL_CEO = [PROFILES.ceo];

export const CIERRE_OPERACIONAL_READ = [
  PROFILES.ceo,
  PROFILES.comercialGarantia,
  PROFILES.gerenteGarantia,
  PROFILES.suscripcionGarantia,
  PROFILES.gerenteRiesgoGarantia,
  PROFILES.analistaRiesgoGarantia,
  PROFILES.actuariado,
  PROFILES.gerenteOperaciones,
  PROFILES.analistaOperaciones,
  PROFILES.riesgoOperacional,
  PROFILES.siniestros,
  PROFILES.consultaBasico,
];
export const CIERRE_OPERACIONAL_WRITE = [
  PROFILES.ceo,
  PROFILES.gerenteOperaciones,
  PROFILES.analistaOperaciones,
];

export const CIERRE_OPERACIONAL_CEO = [PROFILES.ceo];

export const REASEGURO_WRITE = [
  PROFILES.ceo,
  PROFILES.actuariado,
]

export const REASEGURO_READ = [
  PROFILES.ceo,
  PROFILES.gerenteRiesgoGarantia,
  PROFILES.analistaRiesgoGarantia,
  PROFILES.riesgoCredito,
  PROFILES.actuariado,
  PROFILES.gerenteOperaciones,
  PROFILES.analistaOperaciones,
  PROFILES.riesgoOperacional,
  PROFILES.siniestros,
]

