import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faFileInvoice, faFileAlt, faReceipt } from '@fortawesome/free-solid-svg-icons';
import { fileDownload, getEstadosUrl } from '../../../helpers/helpers';
import MovimientoMTable from './MovimientoMTable';

const EstadoMDetailMovimientos = ({ id }) => (
  <div id="movimientos" className="table-container mensuales">
    <Row>
      <Col md="2" className="title-container">
        <h5>Cuentas</h5>
      </Col>
      <Col className="d-flex justify-content-end">
        <div className="button-container contable">
          <Button
            className="btn btn-orsan"
            onClick={() => fileDownload(getEstadosUrl(false, id, 'download_reporte_oynr'))}
          >
            <FontAwesomeIcon icon={faFileInvoice} />
            <span className="text">Descargar Reporte OYNR</span>
          </Button>
        </div>
        <div className="button-container contable">
          <Button
            className="btn btn-orsan"
            onClick={() => fileDownload(getEstadosUrl(false, id, 'download_reporte_rrc'))}
          >
            <FontAwesomeIcon icon={faFileAlt} />
            <span className="text">Descargar Reporte RRC</span>
          </Button>
        </div>
        <div className="button-container contable">
          <Button
            className="btn btn-orsan"
            onClick={() => fileDownload(getEstadosUrl(false, id, 'download_reporte_desc_cesion'))}
          >
            <FontAwesomeIcon icon={faFile} />
            <span className="text">Descargar Reporte de Desc. Cesión</span>
          </Button>
        </div>
        <div className="button-container">
          <Button
            className="btn btn-orsan"
            onClick={() => fileDownload(getEstadosUrl(false, id, 'download_voucher_contable'))}
          >
            <FontAwesomeIcon icon={faReceipt} />
            <span className="text">Descargar Voucher Contable</span>
          </Button>
        </div>
      </Col>
    </Row>
    <Row>
      <MovimientoMTable />
    </Row>
  </div>
);

EstadoMDetailMovimientos.propTypes = {
  id: PropTypes.string.isRequired,
};

export default EstadoMDetailMovimientos;
