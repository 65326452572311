import React from 'react';
import PageContainer from 'components/UI/PageContainer';
import { BASE_URL, API } from 'CONST';
import EmisionesDiariasTable from '../EmisionesDiariasTable';

const EmisionesDiariasGarantiaContainer = () => {
  return (
    <PageContainer
      breadcrumbs={[
        { id: 0, name: 'GARANTÍA', url: '/reservas/garantia/estados_diarios' },
        { id: 1, name: 'EMISIONES DIARIAS' },
      ]}
    >
      <EmisionesDiariasTable endpoint={`${BASE_URL}${API.estados_diarios}`} garantia />
    </PageContainer>
  );
};

export default EmisionesDiariasGarantiaContainer;
