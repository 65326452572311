import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { cleanEstadoDiario, getData } from '../../../redux-store/actions';
import { GET_ESTADO } from '../../../redux-store/constants/action-types';
import { API, BASE_URL } from '../../../CONST';
import PageContainer from '../../UI/PageContainer';
import EstadoDetailResumen from './EstadoDetailResumen';
import EstadoDetailMovimientos from './EstadoDetailMovimientos';

const moment = require('moment');

function mapDispatchToProps(dispatch) {
  return {
    getEstados: policy => dispatch(getData(policy)),
    resetData: policy => dispatch(cleanEstadoDiario(policy)),
  }
}

class EstadoDetail extends Component {
  componentDidMount() {
    const { getEstados, match, resetData } = this.props;
    const { id } = match.params;
    resetData();
    getEstados({
      url:`${BASE_URL}${API.cierre_diario}${id}`,
      type: GET_ESTADO,
    });
  }

  render() {
    const { match } = this.props;
    return(
      <PageContainer
        breadcrumbs={[
          { id: 1 ,name: 'GARANTÍA', url: '/reservas/garantia/estados_diarios' },
          { id: 2 ,name: 'CIERRES DIARIOS', url: '/reservas/garantia/estados_diarios' },
          { id: 3 ,name: `CIERRE ${moment(match.params.date).format('DD-MM-YYYY')}`},
        ]}
      >
        <EstadoDetailResumen />
        <EstadoDetailMovimientos id={match.params.id} date={match.params.date} />
      </PageContainer>
    )
  }
}

const mapStateToProps = state => {
  return {
    login: state.login,
  }
};

const EstadoDetailView = connect(mapStateToProps, mapDispatchToProps)(EstadoDetail);

export default EstadoDetailView;

EstadoDetail.propTypes = {
  getEstados: PropTypes.func.isRequired,
  login: PropTypes.shape({
    user_token: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      date: PropTypes.string,
      id: PropTypes.string,
    }),
  }).isRequired,
  resetData: PropTypes.func.isRequired,
};
