import React from 'react';
import PropTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';

import {
  cellNumOperation,
  cellOperation,
  headerOperation,
} from '../../../helpers/tables';
import Acciones from '../../UI/Acciones';
import Estado from '../../UI/Estado';
import DeprecatedBaseTable from '../../UI/DeprecatedBaseTable';

const EstadoListTable = ({ activeEstado, estados, handleChangeWarning }) => {
  const columns = [
    {
      Header: headerOperation('Fecha'),
      id: 'fecha',
      accessor: i => cellOperation(i.fecha),
      sortable: false
    },
    {
      Header: headerOperation('Movimientos'),
      id: 'movimientos',
      accessor: i => cellNumOperation(i.movimientos),
      sortable: false

    },
    {
      Header: headerOperation('Emisiones'),
      id: 'emisiones',
      accessor: i => cellNumOperation(i.emisiones),
      sortable: false

    },
    {
      Header: headerOperation('Endosos'),
      id: 'endosos',
      accessor: i => cellNumOperation(i.endosos),
      sortable: false

    },
    {
      Header: headerOperation('Estado'),
      id: 'estado',
      accessor: i => cellOperation(
        <Estado estado={i.estado} />
      ),
      width: 180,
      sortable: false

    },
    {
      Header: headerOperation('Acciones'),
      id: 'acciones',
      accessor: (i => (i.estado !== 'Pendiente'
          ? (
            <Acciones
              diario
              handleChangeWarning={handleChangeWarning}
              node={i}
              warning={i === activeEstado}
            />
          ) : cellOperation()
        )
      ),
      sortable: false
    },
  ];
  return (
    <div className="table-container">
      <DeprecatedBaseTable
        className="estado-list"
        columns={columns}
        data={estados}
        resizable={false}
        style={{ height: "50vh" }}
      />
    </div>
  )
};

const mapStateToProps = state => {
  return {
    estados: state.estados.estadoList,
  }
};

const EstadoListTableView = connect(mapStateToProps)(EstadoListTable);

EstadoListTable.propTypes = {
  activeEstado: PropTypes.string,
  estados: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleChangeWarning: PropTypes.func.isRequired,
};

EstadoListTable.defaultProps = {
  activeEstado: '',
};

export default EstadoListTableView;
