import React from 'react';
import PropTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';
import Acciones from '../UI/Acciones';
import Estado from '../UI/Estado';
import BaseTable from '../UI/BaseTable';
import {
  cellOperation,
  headerOperation
} from '../../helpers/tables';

const moment = require('moment');

function formatPeriod(finalDate) {
  return `${moment(finalDate).format('MMM YYYY').replace(/\./g, '')}`;
}

const EstadosTrimestralesListTable = ({
  activeEstado,
  estadosTrimestrales,
  handleChangeWarning
}) => {
  const columns = [
    {
      Header: headerOperation('Inicio Periodo'),
      id: 'inicio_periodo',
      accessor: 'inicio_periodo',
      Cell: (row) => cellOperation(formatPeriod(row.value)),
      minWidth: 90,
      sortable: false,
    },
    {
      Header: headerOperation('Término Periodo'),
      id: 'termino_periodo',
      accessor: 'termino_periodo',
      Cell: (row) => cellOperation(formatPeriod(row.value)),
      minWidth: 90,
      sortable: false,
    },
    {
      Header: headerOperation('Estado'),
      id: 'estado',
      accessor: 'estado',
      Cell: (row) => cellOperation(
        <Estado trimestral estado={row.value} />,
        'center',
        true,
        true
      ),
      minWidth: 130,
      sortable: false
    },
    {
      Header: headerOperation('Acciones'),
      id: 'acciones',
      accessor: (i => (i.estado !== 'Pendiente'
          ? cellOperation(
            <Acciones
              handleChangeWarning={handleChangeWarning}
              node={i}
              warning={i === activeEstado}
              trimestral
            />,
            'center',
            true,
            true
            ) : cellOperation()
        )
      ),
      width: 220,
      sortable: false
    },
  ];

  return (
    <>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr auto auto',
          gridGap: '10px',
          marginTop: '15px',
          marginBottom: '6px',
        }}
      />
      <BaseTable
        columns={columns}
        data={estadosTrimestrales.estadoList}
        resizable={false}
        showPagination
        noBorders
      />
    </>
  );
};

const mapStateToProps = state => {
  return {
    estadosTrimestrales: state.estadosTrimestrales,
  }
};

const EstadosTrimestralesListTableView = connect(mapStateToProps)(EstadosTrimestralesListTable);

EstadosTrimestralesListTable.propTypes = {
  activeEstado: PropTypes.node,
  estadosTrimestrales: PropTypes.shape({
    estadoList: PropTypes.arrayOf(PropTypes.object)
  }).isRequired,
  handleChangeWarning: PropTypes.func.isRequired,
};

EstadosTrimestralesListTable.defaultProps = {
  activeEstado: undefined,
};

export default EstadosTrimestralesListTableView;
