const BASE_URL = process.env.REACT_APP_API;
const BASE_URL_FILE = process.env.REACT_APP_URL;
const BASE_OAUTH = process.env.REACT_APP_OAUTH;
const CLIENT_ID = process.env.REACT_APP_CLIENT;
const LOCAL_URL = process.env.REACT_APP_LOCAL;
const POLIZA_URL = process.env.REACT_APP_POLIZA;
const REASEGURO_URL = process.env.REACT_APP_REASEGURO;
const USERS_URL = process.env.REACT_APP_USERS;
const DOCS_URL = process.env.REACT_APP_DOCS;
const RISK_URL = process.env.REACT_APP_RISK;
const WORKFLOW_URL = process.env.REACT_APP_WORKFLOW;
const ANALYST_URL = process.env.REACT_APP_ANALYST;
const SEARCH_DELAY_MS = process.env.REACT_APP_SEARCH_DELAY_MS;
const ORSAN_LOGO_URL = "https://orsan-static.s3.amazonaws.com/public/logotipo_orsan_seguros.png";

const API = {
  cierre_diario: 'cierre_diario/',
  estados_diarios: 'estados_diarios/',
  estados_mensuales: 'estados_mensuales/',
  estados_trimestrales: 'estados_trimestrales/',
  endSession: 'end-session/',
  send_movs_diarios_to_folder: 'send_movs_diarios_to_folder/',
  login: 'authorize',
  contratos: 'contratos/',
  ramosFecu: 'ramos-fecu/',
  clientes: 'clientes/',
  capaContrato: 'capa-contrato',
  tipoContrato: 'tipo-contrato',
  clasificacionTecnica: 'clasificacion-tecnica',
  tipoReaseguro: 'tipo-reaseguro',
  movimientoDiarioBase: 'movimiento-diario-base/',
  movimientoDiarioCalculado: 'movimiento-diario-calculado',
  movimientoDiarioCreditoCalculado: 'movimiento-diario-credito-calculado',
  estadoDiarioCredito: 'estados_diarios_credito/',
  cierreDiarioCredito: 'cierre_diario_credito/',
};

export const CLASIFICACION_TECNICA = {
  noProporcional: 'no-proporcional',
  proporcional: 'proporcional',
};

export const TIPO_CONTRATO = {
  automatico: 'automatico',
  facultativo: 'facultativo',
}

export const ESTADO_DESCARGA = {
  PENDING: 'PENDING',
  FAILURE: 'FAILURE',
  SUCCESS: 'SUCCESS',
}

export const ROLES = {
  corredorReaseguroGarantia: 'corredordereasegurogarantia',
  corredorReaseguroCredito: 'corredordereasegurocredito',
  reaseguradorGarantia: 'reaseguradorgarantia',
  reaseguradorCredito: 'reaseguradorcredito',
};

export const PROFILES = {
  ceo: 'ceo',
  consultaBasico: 'consulta-basico',
  comercialGarantia: 'comercial-garantia',
  suscripcionGarantia: 'suscripcion-garantia',
  gerenteRiesgoGarantia: 'gerente-riesgo-garantia',
  analistaRiesgoGarantia: 'analista-riesgo-garantia',
  comercialCredito: 'comercial-credito',
  riesgoCredito: 'riesgo-credito',
  fiscalia: 'fiscalia',
  actuariado: 'actuariado',
  gerenteOperaciones: 'gerente-operaciones',
  analistaOperaciones: 'analista-operaciones',
  riesgoOperacional: 'riesgo-operacional',
  siniestros: 'siniestros',
  recuperoSiniestros: 'recupero-siniestros',
  administrador: 'administrador',
  consultaEspecial: 'consulta-especial',
  gerenteGarantia: 'gerente-garantia',
};

export const ESTADOS = {
  abierto: 'Abierto',
  enCurso: 'En_Curso',
};

const FRONT_DF_1 = 'DD-MM-YYYY';
const BACKEND_DF = 'YYYY-MM-DD';
const DEFAULT_PAGE_SIZE = 20;
const calculatePages = (rows) => Math.ceil(rows / DEFAULT_PAGE_SIZE);

export {
  API,
  BASE_URL,
  BASE_URL_FILE,
  BASE_OAUTH,
  CLIENT_ID,
  LOCAL_URL,
  RISK_URL,
  POLIZA_URL,
  USERS_URL,
  DOCS_URL,
  WORKFLOW_URL,
  ANALYST_URL,
  FRONT_DF_1,
  BACKEND_DF,
  SEARCH_DELAY_MS,
  calculatePages,
  DEFAULT_PAGE_SIZE,
  REASEGURO_URL,
  ORSAN_LOGO_URL
};
