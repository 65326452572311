import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import '../styles/breadcrumbs.css';

const Breadcrumbs = ({ arrayMap }) => {
  return (
    <div>
      <Breadcrumb tag="nav" listTag="div">
        {arrayMap.map((route, index) => (
          route.url ? (
            <span key={route.id} className="breadcrumb-item principal">
              <Link to={route.url}>
                {route.name}
              </Link>
            </span>
          ) : (
            <BreadcrumbItem
              active
              key={route.id}
              tag="span"
              className={index === 0 ? 'principal' : ''}
            >
              {route.name}
            </BreadcrumbItem>
          )
        ))}
      </Breadcrumb>
    </div>
  );
};

Breadcrumbs.propTypes = {
  arrayMap: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    url: PropTypes.string,
  })).isRequired,
};

export default Breadcrumbs;
