import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Container,
  Col,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
} from 'reactstrap';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import { PROFILES } from 'CONST';
import SafeBtn from '../../shared/SafeBtn';
import isUserHasProfile from '../../../helpers/profileValidator';

const EditMovimientoModal = ({ openModal, toggleModal, saveChanges, date }) => {
  const [monto, setMonto] = useState(0);

  const onCloseModal = () => {
    toggleModal();
    setMonto(0);
  };

  const onSave = () => {
    saveChanges(monto);
    onCloseModal();
  }

  const isValidPerfilRetroactividad = () => {
    // Sin límite de retroactividad.
    if (isUserHasProfile([PROFILES.ceo])) {
      return true;
    }
    const dateCierre = moment(date);
    const dateToday = moment();
    if (isUserHasProfile([PROFILES.gerenteOperaciones])) {
      // Máxima retroactividad de cambio de 180 días.
      if (dateToday.diff(dateCierre, 'days') <= 180) {
        return true;
      }
      return false;
    }
    if (isUserHasProfile([PROFILES.analistaOperaciones])) {
      // Máxima retroactividad de cambio de 60 días.
      if (dateToday.diff(dateCierre, 'days') <= 60) {
        return true;
      }
      return false;
    }
    return false;
  }

  return (
    <Modal isOpen={openModal} toggle={onCloseModal} type="modal-warning warning-container">
      <ModalHeader toggle={onCloseModal}>
        Modificación tasa de cambio
      </ModalHeader>
      <ModalBody>
        <Container>
          <Row style={{ marginBottom: '1rem' }}>
            <Col>
              <Label>Nueva tasa de cambio:</Label>
            </Col>
            <Col>
              <NumberFormat
                allowNegative={false}
                className="form-control"
                decimalScale="2"
                decimalSeparator=","
                fixedDecimalScale
                onValueChange={(values) => setMonto(values.floatValue)}
                suffix=" CLP"
                thousandSeparator="."
                value={monto}
              />
            </Col>
          </Row>
          {!isValidPerfilRetroactividad() && (
            <span className="text-danger error">
              * No posee los permisos necesarios para modificar la tasa de cambio. Supera la máxima retroactividad de días.
            </span>
          )}
        </Container>
      </ModalBody>
      <ModalFooter>
        <Button onClick={() => onCloseModal()} className="warning-cancel mr-auto">
          Cancelar
        </Button>
        <SafeBtn
          onClick={() => onSave()}
          disabled={!isValidPerfilRetroactividad() || !monto || monto <= 0}
          className="warning-accept"
        >
          Aceptar
        </SafeBtn>
      </ModalFooter>
    </Modal>
  );
};

EditMovimientoModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  saveChanges: PropTypes.func.isRequired,
  date: PropTypes.string.isRequired,
};

export default EditMovimientoModal;
