import {
  ESTADO_CHANGE,
  CLEAN_ESTADO_DIARIO,
  GET_ESTADO,
  GET_ESTADO_LIST,
} from '../constants/action-types';

const initialState = {
  estadoList: [],
  detalleEstado: undefined,
  movimientosDiarios: [],
  errorMessage: undefined,
  successMessage: false,
};

const executeGetEstadoSuccess = (state, action) => {
  return {
    ...state,
    detalleEstado: action.payload,
    movimientosDiarios: action.payload.movimientos_diarios,
    successMessage: undefined,
    errorMessage: undefined,
  }
};

const executeGetEstadoListSuccess = (state, action) => {
  return {
    ...state,
    estadoList: action.payload,
    successMessage: undefined,
    errorMessage: undefined,
  }
};

const executeChangeEstadoSuccess = (state, action) => {
  const { estadoList } = state;
  const list = [...estadoList];
  const updatedEstado = action.payload;
  const estado = list.find(node => node.id === updatedEstado.id);
  const index = list.indexOf(estado);
  list.splice(index, 1, updatedEstado);
  return {
    ...state,
    estadoList: list,
    successMessage: undefined,
    errorMessage: undefined,
  }
};

const executeCleanEstadoSuccess = (state) => {
  return {
    ...state,
    detalleEstado: undefined,
    movimientosDiarios: [],
    errorMessage: undefined,
    successMessage: false
  }
};

function estados(state = initialState, action) {
  switch (action.type) {
    case GET_ESTADO:
      return executeGetEstadoSuccess(state, action);
    case GET_ESTADO_LIST:
      return executeGetEstadoListSuccess(state, action);
    case ESTADO_CHANGE:
      return executeChangeEstadoSuccess(state, action);
    case CLEAN_ESTADO_DIARIO:
      return executeCleanEstadoSuccess(state, action);
    default:
      return state;
  }
}

export default estados;
