import React from 'react';
import _ from 'lodash';

const useEasyState = (initialState) => {
  const [state, setState] = React.useState(initialState);

  const clearState = () => setState(initialState);

  const setStateValue = (name, value) => {
    const stateCopy = _.cloneDeep(state);
    _.set(stateCopy, name, value);
    setState(stateCopy);
  };

  return {
    state,
    setState,
    clearState,
    setStateValue,
  };
};

export default useEasyState;
