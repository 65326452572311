import React from 'react';
import PageContainer from 'components/UI/PageContainer';
import { Link } from 'react-router-dom';
import { Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import ContratosTable from './ContratosTable';
import isUserHasProfile from '../../../helpers/profileValidator';
import { REASEGURO_WRITE } from '../../../helpers/profilePermission';

const ContratoContainer = () => {
  return (
    <PageContainer
      breadcrumbs={[
        { id: 0, name: 'REASEGURO', url: '/reaseguros/contratos' },
        { id: 1, name: 'CONTRATOS' },
      ]}
    >
      {isUserHasProfile(REASEGURO_WRITE) && (
        <Col md={{ offset: 10 }}>
          <Link
            className="btn btn-orsan"
            disabled={false}
            style={{
              width: '100%',
              backgroundColor: '#F98724',
              border: '#F98724',
            }}
            to="/reaseguros/contratos/crear"
          >
            <FontAwesomeIcon icon={faPlus} style={{ marginRight: '5px' }} />
            Crear Contrato
          </Link>
        </Col>
      )}
      <ContratosTable />
    </PageContainer>
  );
};

export default ContratoContainer;
