import auth from './auth';

export const request = (method, url, body = null, binary = false, headers = null) => {
  const config = {
    method,
    headers: headers || {
      'Content-Type': 'application/json',
    },
  };
  if (body) {
    config.body = binary ? body : JSON.stringify(body)
  }
  return auth.fetch(url, config)
};

export const get = (url) => auth.fetch(url);

export const post = (url, body) => request('POST', url, body);

export const put = (url, body) => request('PUT', url, body);

export const patch = (url, body) => request('PATCH', url, body);

export const rDelete = (url) => request('DELETE', url);

export const uploadFile = (url, filename, file) => request('PUT', url, file, true, {
  'Content-Disposition': `attachment; filename="${encodeURI(filename)}"`,
});
