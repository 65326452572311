import React from 'react';
import PropTypes from 'prop-types';
import '../../styles/alerts.css';

const Estado = ({ estado, trimestral }) => {
  let alertClass = 'alert-open';
  if (estado === 'Cerrado') {
    alertClass = 'alert-closed';
  } else if (estado === 'En_Curso') {
    alertClass = 'alert-course';
  } else if (estado === 'Pendiente') {
    alertClass = 'alert-pending';
  }
  return (
    <div className={`alert ${alertClass} text-center`} role="alert" style={{width: trimestral ? '75%' : '100%'}}>
      {estado === 'En_Curso' ? 'En Curso' : estado}
    </div>
  )
};

Estado.propTypes = {
  estado: PropTypes.string,
  trimestral: PropTypes.bool,
};

Estado.defaultProps = {
  estado: '',
  trimestral: false,
};

export default Estado;
