import React from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { BASE_URL, API } from 'CONST';
import PageContainer from 'components/UI/PageContainer';
import EmisionesDiariasResumen from '../EmisionesDiariasResumen';
import EmisionesDiariasMovimientos from '../EmisionesDiariasMovimientos';

const EmisionesDiariasGarantiaDetail = () => {
  const { date } = useParams();

  return (
    <PageContainer
      breadcrumbs={[
        { id: 0, name: 'CRÉDITO', url: '/reservas/credito/estados_mensuales' },
        { id: 1, name: 'EMISIONES DIARIAS', url: '/reaseguros/credito/emisiones_diarias' },
        { id: 2, name: `CIERRE ${moment(date).format('DD-MM-YYYY')}` },
      ]}
    >
      <EmisionesDiariasResumen endpoint={`${BASE_URL}${API.cierreDiarioCredito}`} />
      <EmisionesDiariasMovimientos
        garantia={false}
        endpoint={`${BASE_URL}${API.cierreDiarioCredito}`}
      />
    </PageContainer>
  );
};

export default EmisionesDiariasGarantiaDetail;
