import React from 'react';
import PropTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';
import moment from 'moment'
import Acciones from '../../UI/Acciones';
import Estado from '../../UI/Estado';
import DeprecatedBaseTable from '../../UI/DeprecatedBaseTable';
import { cellNumOperation, cellOperation, headerOperation } from '../../../helpers/tables';


const GarantiaEmListTable = ({ activeEstado, handleChangeWarning }) => {
  const columns = [
    {
      Header: headerOperation('Periodo'),
      headerClassName: 'fixed',
      id: 'periodo',
      accessor: i => cellOperation(
        moment(i.periodo).format('MMM-YYYY').replace(/\./g, '')
      ),
      style: {
        borderRight: '1px solid #CCCCCC'
      },
      sortable: false
    },
    {
      Header: headerOperation('MO'),
      id: 'moneda',
      accessor: i => cellOperation(i.moneda),
      width: 60,
      sortable: false
    },
    {
      Header: headerOperation('Movimientos'),
      id: 'movimientos',
      accessor: i => cellNumOperation(i.movimientos),
      minWidth: 100,
      sortable: false

    },
    {
      Header: headerOperation('Estado'),
      id: 'estado',
      accessor: i => cellOperation(
        <Estado estado={i.estado} />
      ),
      minWidth: 130,
      sortable: false
    },
    {
      Header: headerOperation('Acciones'),
      id: 'acciones',
      accessor: (i => (i.estado !== 'Pendiente'
          ? (
            <Acciones
              handleChangeWarning={handleChangeWarning}
              node={i}
              warning={i === activeEstado}
            />
            ) : cellOperation()
        )
      ),
      width: 212,
      sortable: false
    },
  ];
  return (
    <div className="table-container">
      <DeprecatedBaseTable
        className="eMensual-list"
        columns={columns}
        data={[]}
        resizable={false}
        style={{ height: "50vh" }}
      />
    </div>
  )
};


GarantiaEmListTable.propTypes = {
  activeEstado: PropTypes.node,
  estadosMensuales: PropTypes.shape({
    estadoList: PropTypes.arrayOf(PropTypes.object)
  }).isRequired,
  handleChangeWarning: PropTypes.func.isRequired,
};

GarantiaEmListTable.defaultProps = {
  activeEstado: undefined,
};

const mapStateToProps = state => ({
  estadosMensuales: state.estadosMensuales,
});

export default connect(mapStateToProps)(GarantiaEmListTable);
