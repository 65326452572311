import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import BaseModal from './BaseModal';

const moment = require('moment');
require('moment/locale/es');

class DeprecatedWarningModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      awaitingResponse: false,
    };
    this.confirmText = this.confirmText.bind(this);
    this.handleSuccess = this.handleSuccess.bind(this);
  }

  componentDidUpdate() {
    const { awaitingResponse } = this.state;
    const { modalOpen } = this.props;
    if (modalOpen && awaitingResponse === true) {
      this.handleSuccess();
    }
  }

  warningMessage = (node, year) => {
    const date = moment(year ? node.periodo : node.fecha).format(year ? 'MMMM-YYYY'  : 'DD-MMM-YYYY');
    return `¿Está seguro/a de querer cerrar el estado del ${year ? 'periodo' : 'día'} ${date}?`;
  };

  confirmText = (node) => {
    const action = node.activo ? 'Cerrar' : 'Abrir';
    return `${action} estado`;
  };

   handleSuccess() {
    this.setState({ awaitingResponse: false })
  }

  handleSubmit() {
    const { handleSubmit, node } = this.props;

    this.setState({ awaitingResponse: true });
    handleSubmit(
      node,
      () => this.setState({ awaitingResponse: false }),
    );
  }

  render() {
    const {
      handleModal, modalOpen, node, year
    } = this.props;
    const { awaitingResponse } = this.state;

    return (
      <div>
        <BaseModal
          handleModal={handleModal}
          modalOpen={modalOpen}
          type="modal-warning warning-container"
        >
          <div className="warning-section text">
            <div className="icon-container">
              <FontAwesomeIcon icon={faExclamationTriangle} />
            </div>
            <div>{this.warningMessage(node, year)}</div>
          </div>
          <div className="button-container warning-section">
            <div>
              <Button
                className="warning-cancel"
                disabled={awaitingResponse}
                onClick={handleModal}
              >
                Cancelar
              </Button>
            </div>
            <div>
              <Button
                className="warning-accept"
                disabled={awaitingResponse}
                onClick={() => this.handleSubmit()}
              >
                {this.confirmText(node)}
              </Button>
            </div>
          </div>
        </BaseModal>
      </div>
    );
  }
}

DeprecatedWarningModal.propTypes = {
  handleModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  node: PropTypes.shape({
    activo: PropTypes.bool,
    fecha: PropTypes.string,
    periodo: PropTypes.string,
  }).isRequired,
  modalOpen: PropTypes.bool.isRequired,
  year: PropTypes.bool,
};

DeprecatedWarningModal.defaultProps = {
  year: null,
};

export default DeprecatedWarningModal;
