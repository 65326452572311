import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import DeprecatedWarningModal from 'components/UI/DeprecatedWarningModal';
import { cleanEstadoDiario, getData, patchData } from '../../../redux-store/actions';
import { ESTADO_CHANGE, GET_ESTADO_LIST, } from '../../../redux-store/constants/action-types';
import { API, BASE_URL } from '../../../CONST';
import PageContainer from '../../UI/PageContainer';
import EstadoFilter from '../../EstadoFilter';
import CierresOperacionalesTable from './CierresOperacionalesTable';
import { fileDownload, getEstadosUrl } from "../../../helpers/helpers";


class CierresOperacionales extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeEstado: undefined,
      date: moment(),
      warningOpen: false,
    };
    this.url = `${BASE_URL}${API.estados_diarios}`;
  }

  componentDidMount() {
    const { resetData, getEstados } = this.props;
    resetData();
    getEstados({
      url: this.url,
      type: GET_ESTADO_LIST,
    });
  }

  componentDidUpdate(prevProps) {
    const { estados } = this.props;
    const { activeEstado } = this.state;
    if(activeEstado && (prevProps.estados !== estados)) {
      this.handleUpdate();
    }
  }

  handleUpdate = () => {
    this.setState({
      warningOpen: false,
      activeEstado: undefined,
    })
  };

  handleDate = (date) => {
    const { getEstados } = this.props;
    if (date <= moment()) {
      getEstados({
        url: `${this.url}?month=${date.format('YYYY-MM')}`,
        type: GET_ESTADO_LIST,
      });
      this.setState({ date })
    }
  };

  handleChangeWarning = (e, node) => {
    const { warningOpen } = this.state;
    e.preventDefault();
    this.setState({
      warningOpen: !warningOpen,
      activeEstado: !warningOpen ? node : undefined,
    })
  };

  handleEstadoChange = (node, onFailedReq = () => {}) => {
    const { estadoChange } = this.props;
    estadoChange({
      url: `${this.url}${node.id}/`,
      type: ESTADO_CHANGE,
      onFailedReq,
      config: { activo: !(node.activo) }
    });
  };

  handleDownload = (type) => {
    const { date } = this.state;
    fileDownload(getEstadosUrl(true, date.format('YYYY-MM'), type));
  };

  render() {
    const { activeEstado, date, warningOpen } = this.state;
    const { estados } = this.props;
    return(
      <PageContainer
        breadcrumbs={[
          { id: 1, name: 'GARANTÍA', url: '/reservas/garantia/estados_diarios' },
          { id: 2, name: 'CIERRE OPERACIONAL' }
        ]}
      >
        <EstadoFilter
          date={date}
          handleDate={this.handleDate}
          handleDownload={this.handleDownload}
          loading={false}
        />
        <CierresOperacionalesTable
          estados={estados}
        />
        {activeEstado && (
          <DeprecatedWarningModal
            handleModal={this.handleChangeWarning}
            handleSubmit={this.handleEstadoChange}
            node={activeEstado}
            modalOpen={warningOpen}
          />
        )}
      </PageContainer>
    )
  }
}

const mapStateToProps = state => ({
  estados: state.estados.estadoList,
});

const mapDispatchToProps = dispatch => ({
  estadoChange: policy => dispatch(patchData(policy)),
  getEstados: policy => dispatch(getData(policy)),
  resetData: policy => dispatch(cleanEstadoDiario(policy)),
});

CierresOperacionales.propTypes = {
  estadoChange: PropTypes.func.isRequired,
  estados: PropTypes.arrayOf(PropTypes.object).isRequired,
  getEstados: PropTypes.func.isRequired,
  resetData: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(CierresOperacionales);
