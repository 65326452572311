import React, { useState, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Col, FormGroup, Input, Label, Button, FormFeedback } from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import Select from 'react-select';
import moment from 'moment';
import { API, BASE_URL, FRONT_DF_1, BACKEND_DF, CLASIFICACION_TECNICA, ROLES, TIPO_CONTRATO } from 'CONST';
import { useHistory } from 'react-router';
import Datetime from 'react-datetime';
import FormSection from 'components/UI/FormSection';
import { roundFloat } from 'helpers/helpers';
import useEasyState from 'helpers/useEasyState';
import { get, post, put } from 'helpers/requests';
import { objectsToOptions, objectToOption } from 'helpers/selects';
import WarningModal from 'components/UI/WarningModal';
import ReaseguradoresForm from './ReaseguradoresForm';
import ReaseguradoresTable from './ReaseguradoresTable';
import isUserHasProfile from '../../../helpers/profileValidator';
import { REASEGURO_WRITE } from '../../../helpers/profilePermission';

const ContratosForm = () => {
  // Constantes relacionadas a contratos
  const [idOrsan, setIdOrsan] = useState('');
  const [nombreContrato, setNombreContrato] = useState('');
  const [ramoFecu, setRamoFecu] = useState('');
  const [fechaInicio, setFechaInicio] = useState('');
  const [fechaTermino, setFechaTermino] = useState('');
  const [montoMaximo, setMontoMaximo] = useState('');
  const [plazoMaximo, setPlazoMaximo] = useState('');
  const [diasGracia, setDiasGracia] = useState('');
  const [reaseguroPct, setReaseguroPct] = useState(null);
  const [montoContrato, setMontoContrato] = useState(null);
  const [prioridadContrato, setPrioridadContrato] = useState(null);
  const [reinstalacionesContrato, setReinstalacionesContrato] = useState(null);
  const [limiteContrato, setLimiteContrato] = useState(null);
  const [tipoContrato, setTipoContrato] = useState(null);
  const [capaContrato, setCapaContrato] = useState(null);
  const [clasificacionTecnica, setClasificacionTecnica] = useState('');
  const [tipoReaseguro, setTipoReaseguro] = useState('');
  const [impuestoTotal, setImpuestoTotal] = useState(0);
  const [participacionTotal, setParticipacionTotal] = useState(0);
  const [comisionTotal, setComisionTotal] = useState(0);
  const [optionsRamosFecu, setOptionsRamosFecu] = useState([]);
  const [optionsTipoContrato, setOptionsTipoContrato] = useState([]);
  const [optionsCapaContrato, setOptionsCapaContrato] = useState([]);
  const [optionsClasificacionTecnica, setOptionsClasificacionTecnica] = useState([]);
  const [optionsTipoReaseguro, setOptionsTipoReaseguro] = useState([]);
  const [rol, setRol] = useState('');
  const [rolCorredor, setRolCorredor] = useState('');
  const { id } = useParams();
  const history = useHistory();
  // Icono de carga
  const [loading, setLoading] = useState(false);
  // Constantes para validación de Id Orsan
  const [responseIdOrsan, setResponseIdOrsan] = useState(0);
  // Constantes para validación de Tipo Contrato
  const [responseTipoContrato, setResponseTipoContrato] = useState(0);
  // Constantes relacionadas a reaseguradores
  const [tableData, setTableData] = useState([]);
  const [reaseguradorHasChanged, setReaseguradorHasChanged] = useState(false);
  const reaseguradorInitialState = { id: '', rut: '', nombre: '' };
  const reaseguradorContratoInitialState = {
    reasegurador: { ...reaseguradorInitialState },
    impuesto: '',
    participacion: '',
    comision: '',
  };
  const {
    state: reaseguradorContrato,
    setState: setReaseguradorContrato,
    setStateValue: setReaseguradorContratoProperty,
  } = useEasyState(reaseguradorContratoInitialState);
  const [edicion, setEdicion] = useState(false);
  const [corredorReaseguro, setCorredorReaseguradorContrato] = useState(null);
  const [optionsCorredorReasegurador, setOptionsCorredorReaseguradores] = useState([]);
  // Constantes para edición de contrato
  const [ramoFecuName, setRamoFecuName] = useState('');
  // Constantes para validación de contrato default
  const [displayModal, setDisplayModal] = useState(false);
  const [displayModalWarning, setDisplayModalWarning] = useState(false);
  const [msgModal, setMsgModal] = useState('');
  // Constantes para validación de perfil
  const disabledByProfile = !isUserHasProfile(REASEGURO_WRITE);

  // Constantes para validación de Edición
  const getEditable = () => {
    let result = false;
    if (id !== undefined) {
      result = true;
    }
    return result;
  };

  const editable = getEditable();

  // Efecto para opciones de Select
  useEffect(() => {
    get(`${BASE_URL}${API.ramosFecu}`).then((response) =>
      setOptionsRamosFecu(objectsToOptions(response.results)),
    );

    get(`${BASE_URL}${API.tipoContrato}`).then((response) =>
      setOptionsTipoContrato(objectsToOptions(response.results, false, false, 'nombre')),
    );

    get(`${BASE_URL}${API.capaContrato}`).then((response) =>
      setOptionsCapaContrato(objectsToOptions(response.results, false, false, 'nombre')),
    );

    get(`${BASE_URL}${API.clasificacionTecnica}`).then((response) =>
      setOptionsClasificacionTecnica(objectsToOptions(response.results, false, false, 'nombre')),
    );

  }, []);

  useEffect(() => {
    if (idOrsan && ramoFecu) {
      get(
        `${BASE_URL}${API.contratos}?orsan_id=${idOrsan}&ramo_fecu_id=${ramoFecu &&
          ramoFecu.value &&
          ramoFecu.value.nemotecnico}`,
      ).then((response) => setResponseIdOrsan(response.count));
    }
  }, [idOrsan, ramoFecu]);

  const setResponseTipoContratoEnFechas = (contratos) => {
    if (contratos.count !== 0) {
      const inicio = !editable ? fechaInicio : moment(fechaInicio, FRONT_DF_1);
      const termino = !editable ? fechaTermino : moment(fechaTermino, FRONT_DF_1);
      contratos.results.forEach(contrato => {
        const limiteInferior = inicio.diff(moment(contrato.fecha_termino, BACKEND_DF)) > 0;
        const limiteSuperior = termino.diff(moment(contrato.fecha_inicio, BACKEND_DF)) > 0;
        if ((limiteInferior !== limiteSuperior) && idOrsan !== contrato.orsan_id) {
          setResponseTipoContrato(1);
        }
      });
    }
  };

  useEffect(() => {
    if ((tipoContrato && tipoContrato.value && tipoContrato.value.slug === TIPO_CONTRATO.automatico)
    && ramoFecu
    && fechaTermino
    && fechaInicio
    && capaContrato) {
      setResponseTipoContrato(0);
      get(
        `${BASE_URL}${API.contratos}?tipo=${tipoContrato.value.id}&ramo_fecu_id=${ramoFecu &&
          ramoFecu.value &&
          ramoFecu.value.nemotecnico}&capa_contrato__slug=${capaContrato &&
          capaContrato.value &&
          capaContrato.value.slug}`,
      ).then((response) => setResponseTipoContratoEnFechas(response));
    } else {
      setResponseTipoContrato(0);
    }
  }, [tipoContrato, capaContrato, ramoFecu, fechaInicio, fechaTermino]);

  useEffect(() => {
    if (rolCorredor !== '') {
      get(`${BASE_URL}${API.clientes}?roles=${rolCorredor}`).then((response) =>
        setOptionsCorredorReaseguradores(objectsToOptions(response, false, false, 'nombre')),
      );
    }
  }, [rolCorredor]);

  const paramByParticipation = (param) => {
    return tableData.map((elem) =>
      elem[param] * elem.participacion / 100
    ).reduce((a, b) => a + b, 0);
  };

  const getTotalParticipation = (table) => table.map((elem) => elem.participacion).reduce((a, b) => a + b, 0);

  useEffect(() => {
    if (tableData && reaseguradorHasChanged) {
      const impuesto = paramByParticipation('impuesto');
      const comision = paramByParticipation('comision');
      const participacion = getTotalParticipation(tableData);
      setImpuestoTotal(impuesto);
      setComisionTotal(comision);
      setParticipacionTotal(roundFloat(participacion));
      setReaseguradorHasChanged(false);
    }
  }, [tableData]);

  // Tipo de reaseguro según clasificación técnica
  useEffect(() => {
    if (clasificacionTecnica) {
      get(`${BASE_URL}${API.tipoReaseguro}/?clasificacion=${clasificacionTecnica.value.id}`)
      .then((response) => {
        setOptionsTipoReaseguro(objectsToOptions(response.results, false, false, 'nombre'))
      });
    }
  }, [clasificacionTecnica]);

  const isClasificacionNoProporcional = () => {
    let result = false;
    if (clasificacionTecnica && clasificacionTecnica.value.slug === CLASIFICACION_TECNICA.noProporcional) {
      result = true;
    }
    return result;
  };

  const validOrsanId = () => !editable ? responseIdOrsan === 0 : true;

  const validTipoContrato = () => responseTipoContrato === 0;

  const validParticipation = () => participacionTotal <= 100;

  const changeRamo = (event) => {
    if (editable) {
      if (event === 'Garantía') {
        setRol(ROLES.reaseguradorGarantia);
        setRolCorredor(ROLES.corredorReaseguroGarantia);
      } else {
        setRol(ROLES.reaseguradorCredito);
        setRolCorredor(ROLES.corredorReaseguroCredito);
      }
    } else {
      setRamoFecu(event);
      setReaseguradorContratoProperty('reasegurador', reaseguradorInitialState);
      setCorredorReaseguradorContrato(null);
      setTableData([]);
      if (event.value.descripcion === 'Garantía') {
        setRol(ROLES.reaseguradorGarantia);
        setRolCorredor(ROLES.corredorReaseguroGarantia);
      } else {
        setRol(ROLES.reaseguradorCredito);
        setRolCorredor(ROLES.corredorReaseguroCredito);
      }
    }
  };

  const resetForm = () => {
    setEdicion(false);
    setReaseguradorContrato({
      ...reaseguradorContratoInitialState,
    });
  };

  const handleEditReaseguradorContrato = (reaseguradorToEdit) => {
    setEdicion(true);
    setReaseguradorContrato(reaseguradorToEdit);
    setReaseguradorHasChanged(true);
  };

  const handleDeleteReaseguradorContrato = (reaseguradorToDelete) => {
    setTableData(
      tableData.filter(
        (elem) => elem.reasegurador.value.id !== reaseguradorToDelete.reasegurador.value.id,
      ),
    );
    setReaseguradorHasChanged(true);
  };

  const mapReaseguradores = () => {
    setTableData(
      tableData.map((elem) =>
        elem.reasegurador.value.id === reaseguradorContrato.reasegurador.value.id
          ? { ...reaseguradorContrato }
          : elem,
      ),
    );
  };

  const handleSaveReaseguradorContrato = (e) => {
    e.preventDefault();

    if (edicion) {
      mapReaseguradores();
    } else {
      const findReasegurador = (elem) =>
        elem.reasegurador.value.id === reaseguradorContrato.reasegurador.value.id;
      const exists = tableData.some(findReasegurador);

      if (exists) {
        mapReaseguradores();
      } else {
        setTableData([reaseguradorContrato, ...tableData]);
      }
    }
    setReaseguradorHasChanged(true);
    resetForm();
  };

  const saveContrato = () => {
    setLoading(true);

    const data = {
      ramo_fecu_id: ramoFecu.value.nemotecnico,
      fecha_inicio: fechaInicio.format(BACKEND_DF),
      fecha_termino: fechaTermino.format(BACKEND_DF),
      monto_maximo: montoMaximo,
      plazo_maximo: plazoMaximo,
      dias_gracia: diasGracia,
      correcto: true,
      reaseguradores: tableData.map((elem) => ({
        reasegurador_id: elem.reasegurador.value.id,
        comision_pct: elem.comision,
        impuesto_pct: elem.impuesto,
        participacion_pct: elem.participacion,
      })),
      tipo: tipoContrato.value.id,
      clasificacion_tecnica: clasificacionTecnica.value.id,
      tipo_reaseguro: tipoReaseguro.value.id,
      nombre_contrato_reaseguro: nombreContrato,
      impuesto_total: impuestoTotal,
      comision_total: comisionTotal,
      capa_contrato: capaContrato.value.id,
    };

    // En caso de ser No Proporcional
    if (isClasificacionNoProporcional()){
      data.prioridad = prioridadContrato;
      data.reinstalaciones = reinstalacionesContrato;
      data.monto = montoContrato;
      data.limite = limiteContrato;
    } else {
      data.reaseguro_pct = reaseguroPct;
    }
    if (corredorReaseguro) {
      data.corredor_reaseguro_id = corredorReaseguro.value.id;
    }

    post(`${BASE_URL}${API.contratos}`, data)
      .then(() => history.push(`/reaseguros/contratos`))
      .then(() => setLoading(false))
      .then(() => window.location.reload());
  };

  const giveFormatClientData = (dataClient) => {
    let mapped = [];
    if (dataClient !== undefined) {
      mapped = dataClient.map((elem) => ({
        comision: elem.comision_pct,
        participacion: elem.participacion_pct,
        impuesto: elem.impuesto_pct,
        reasegurador: {
          label: elem.nombre,
          value: {
            id: elem.reasegurador_id,
            rut: elem.rut.toString(),
            nombre: elem.nombre,
          },
        },
      }));
    }
    return mapped;
  };

  const setEditCorredorReaseguradorContrato = (dataCorredorReaseguro) => {
    setCorredorReaseguradorContrato(
      dataCorredorReaseguro ?
      objectToOption(dataCorredorReaseguro, 'nombre') :
      null
    )
  };

  const setEditReaseguroPct = (dataReaseguroPct) => {
    if (dataReaseguroPct === undefined) {
      setReaseguroPct(null);
    }
    setReaseguroPct(dataReaseguroPct);
  };

  const setEditLimite = (dataLimite) => {
    if (dataLimite === undefined) {
      setLimiteContrato(null);
    }
    setLimiteContrato(dataLimite);
  };

  const setEditMonto = (dataMonto) => {
    if (dataMonto === undefined) {
      setMontoContrato(null);
    }
    setMontoContrato(dataMonto);
  };

  const setEditPrioridad = (dataPrioridad) => {
    if (dataPrioridad === undefined) {
      setPrioridadContrato(null);
    }
    setPrioridadContrato(dataPrioridad);
  };

  const setEditReinstalaciones = (dataReinstalaciones) => {
    if (dataReinstalaciones === undefined) {
      setReinstalacionesContrato(null);
    }
    setReinstalacionesContrato(dataReinstalaciones);
  };

  const changeTipoContrato = (tipoContratoToChange) => {
    if (tipoContrato) {
      if (tipoContratoToChange.value.id === 1 && tipoContrato.value.id === 2) {
        setMontoContrato(null);
      }
    }
    setTipoContrato(tipoContratoToChange);
  };

  const changeClasificacionTecnica = (clasificacionTecnicaToChange) => {
    setTipoReaseguro(null);
    setClasificacionTecnica(clasificacionTecnicaToChange);
  };

  const setDataEdition = (data) => {
    const tableReaseguro = giveFormatClientData(data.reaseguradores);
    setIdOrsan(data.orsan_id);
    setNombreContrato(data.nombre_contrato_reaseguro);
    setRamoFecu({value: {nemotecnico: data.ramo_fecu_id}});
    setFechaInicio(moment(data.fecha_inicio).format(FRONT_DF_1));
    setFechaTermino(moment(data.fecha_termino).format(FRONT_DF_1));
    setMontoMaximo(data.monto_maximo);
    setDiasGracia(data.dias_gracia);
    setPlazoMaximo(data.plazo_maximo);
    setRamoFecuName(data.ramo_fecu_nombre);
    setTableData(tableReaseguro);
    changeRamo(data.ramo_fecu_nombre);
    setTipoContrato(objectToOption(data.tipo_contrato_data, 'nombre'));
    setTipoReaseguro(objectToOption(data.tipo_reaseguro_data, 'nombre'));
    setCapaContrato(objectToOption(data.capa_contrato_data, 'nombre'));
    setClasificacionTecnica(objectToOption(data.clasificacion_tecnica_data, 'nombre'));
    setEditCorredorReaseguradorContrato(data.corredor_reaseguro_data);
    setEditReaseguroPct(data.reaseguro_pct);
    setEditLimite(data.limite);
    setEditMonto(data.monto);
    setEditPrioridad(data.prioridad);
    setEditReinstalaciones(data.reinstalaciones)
    setImpuestoTotal(data.impuesto_total);
    setComisionTotal(data.comision_total);
    setParticipacionTotal(roundFloat(getTotalParticipation(tableReaseguro)));
  };

  useEffect(() => {
    if (editable) {
      get(`${BASE_URL}${API.contratos}${id}`).then((response) => setDataEdition(response));
    }
  }, []);

  const saveEditContrato = () => {
    setLoading(true);

    const data = {
      ramo_fecu_id: ramoFecu.value.nemotecnico,
      fecha_inicio: moment(fechaInicio, FRONT_DF_1).format(BACKEND_DF),
      fecha_termino: moment(fechaTermino, FRONT_DF_1).format(BACKEND_DF),
      monto_maximo: montoMaximo,
      plazo_maximo: plazoMaximo,
      dias_gracia: diasGracia,
      correcto: true,
      nombre_contrato_reaseguro: nombreContrato,
      reaseguradores: tableData.map((elem) => ({
        reasegurador_id: elem.reasegurador.value.id,
        comision_pct: elem.comision,
        impuesto_pct: elem.impuesto,
        participacion_pct: elem.participacion,
      })),
      tipo: tipoContrato.value.id,
      clasificacion_tecnica: clasificacionTecnica.value.id,
      tipo_reaseguro: tipoReaseguro.value.id,
      impuesto_total: impuestoTotal,
      comision_total: comisionTotal,
      capa_contrato: capaContrato.value.id,
    };

    // En caso de ser No Proporcional
    if (isClasificacionNoProporcional()){
      data.prioridad = prioridadContrato;
      data.reinstalaciones = reinstalacionesContrato;
      data.monto = montoContrato;
      data.limite = limiteContrato;
    } else {
      data.reaseguro_pct = reaseguroPct;
    }
    if (corredorReaseguro) {
      data.corredor_reaseguro_id = corredorReaseguro.value.id;
    }

    put(`${BASE_URL}${API.contratos}${id}/`, data)
      .then(history.push(`/reaseguros/contratos`))
      .then(() => setLoading(false))
      .then(window.location.reload());
  };

  const changeStateModal = (state, msg) => {
    setDisplayModal(state);
    setMsgModal(msg);
  }

  const saveWithLessParticipation = () => {
    setDisplayModalWarning(false);
    return editable ? saveEditContrato() : saveContrato();
  }

  const isDisabled = () => {
    return !validOrsanId() ||
    !ramoFecu ||
    !fechaInicio ||
    !fechaTermino ||
    !montoMaximo ||
    montoMaximo < 0 ||
    !plazoMaximo ||
    plazoMaximo < 0 ||
    !diasGracia ||
    diasGracia < 0 ||
    !tipoContrato ||
    (isClasificacionNoProporcional() &&
      (
        !montoContrato ||
        !prioridadContrato ||
        !reinstalacionesContrato ||
        !limiteContrato
      )
    )
  }

  return (
    <div>
      <Col md={{ offset: 2, size: 8 }}>
        <FormSection title="Información Contrato">
          <div
            style={{
              display: 'grid',
              gridGap: '20px 40px',
              gridTemplateColumns: '1fr 1fr 1fr',
              marginTop: '15px',
              padding: '15px 0',
            }}
          >
            {editable ? (
              <FormGroup>
                <Label for="idOrsan">
                  Id Orsan
                  <span className="required text-danger">*</span>
                </Label>
                <p className="form-values">{idOrsan}</p>
              </FormGroup>
            ) : ('')}
            <FormGroup>
              <Label for="nombreContrato">
                Nombre de Contrato
                <span className="required text-danger">*</span>
              </Label>
              <Input
                name="nombreContrato"
                id="nombreContrato"
                value={nombreContrato}
                onChange={(event) => setNombreContrato(event.target.value)}
                placeholder="Ingrese Nombre de Contrato"
                invalid={nombreContrato.length > 50}
              />
              <FormFeedback style={{ fontSize: '12px', margin: '0 0 5px 5px' }}>
                Ingrese un nombre con menos de 50 caracteres.
              </FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="tipoContrato">
                Tipo de Contrato
                <span className="required text-danger">*</span>
              </Label>
              <Select
                id="tipoContrato"
                name="tipoContrato"
                className="custom-policy-select"
                options={optionsTipoContrato}
                onChange={(event) => changeTipoContrato(event)}
                value={tipoContrato}
                placeholder="Seleccione Tipo de Contrato"
                isDisabled={disabledByProfile}
              />
            </FormGroup>
            <FormGroup>
              <Label for="capaContrato">
                Capa de Contrato
                <span className="required text-danger">*</span>
              </Label>
              <Select
                id="capaContrato"
                name="capaContrato"
                className="custom-policy-select"
                options={optionsCapaContrato}
                onChange={(event) => setCapaContrato(event)}
                value={capaContrato}
                placeholder="Seleccione Capa de Contrato"
                isDisabled={disabledByProfile}
              />
            </FormGroup>
            <FormGroup>
              <Label for="clasificacionTecnica">
                Clasificación Técnica
                <span className="required text-danger">*</span>
              </Label>
              <Select
                id="clasificacionTecnica"
                name="clasificacionTecnica"
                className="custom-policy-select"
                options={optionsClasificacionTecnica}
                onChange={(event) => changeClasificacionTecnica(event)}
                value={clasificacionTecnica}
                placeholder="Seleccione Clasificación Técnica"
                isDisabled={disabledByProfile}
              />
            </FormGroup>
            <FormGroup>
              <Label for="tipoReaseguro">
                Tipo de Reaseguro
                <span className="required text-danger">*</span>
              </Label>
              <Select
                id="tipoReaseguro"
                name="tipoReaseguro"
                className="custom-policy-select"
                options={optionsTipoReaseguro}
                onChange={(event) => setTipoReaseguro(event)}
                value={tipoReaseguro}
                placeholder="Seleccione Tipo de Reaseguro"
                isDisabled={disabledByProfile}
              />
            </FormGroup>
            <FormGroup>
              <Label for="idOrsan">
                Ramo FECU
                <span className="required text-danger">*</span>
              </Label>
              {editable ? (
                <p className="form-values">{ramoFecuName}</p>
              ) : (
                <Select
                  id="ramoFecu"
                  name="ramoFecu"
                  className="custom-policy-select"
                  options={optionsRamosFecu}
                  onChange={(event) => changeRamo(event)}
                  value={ramoFecu}
                  placeholder="Seleccione Ramo FECU"
                  isDisabled={disabledByProfile}
                />
              )}
            </FormGroup>
            <FormGroup>
              <Label for="corredorReaseguro">
                Corredor de Reaseguro
              </Label>
              <Select
                id="corredorReaseguro"
                name="corredorReaseguro"
                className="custom-policy-select"
                options={optionsCorredorReasegurador}
                onChange={(event) => setCorredorReaseguradorContrato(event)}
                value={corredorReaseguro}
                placeholder="Seleccione Reasegurador"
              />
            </FormGroup>
            <FormGroup>
              <Label for="fechaInicio">
                Fecha de Inicio
                <span className="required text-danger">*</span>
              </Label>
              <Datetime
                id="fechaInicio"
                onChange={(value) => setFechaInicio(value)}
                value={fechaInicio}
                dateFormat={FRONT_DF_1}
                timeFormat={false}
                closeOnSelect
                inputProps={{ disabled: disabledByProfile }}
              />
            </FormGroup>
            <FormGroup>
              <Label for="fechaTermino">
                Fecha de Término
                <span className="required text-danger">*</span>
              </Label>
              <Datetime
                id="fechaTermino"
                onChange={(value) => setFechaTermino(value)}
                value={fechaTermino}
                dateFormat={FRONT_DF_1}
                timeFormat={false}
                closeOnSelect
                viewDate={fechaInicio}
                isValidDate={(date) =>
                  moment(date, FRONT_DF_1).isAfter(moment(fechaInicio, FRONT_DF_1), 'date')
                }
                inputProps={{ disabled: disabledByProfile }}
              />
            </FormGroup>
            <FormGroup>
              <Label for="montoMaximo">
                Monto Máximo Cobertura (UF)
                <span className="required text-danger">*</span>
              </Label>
              <NumberFormat
                name="montoMaximo"
                id="montoMaximo"
                className="form-control"
                value={montoMaximo}
                onValueChange={({floatValue}) => setMontoMaximo(floatValue)}
                placeholder="Ingrese Monto Máximo Cobertura"
                thousandSeparator="."
                decimalSeparator=","
                decimalScale="2"
                fixedDecimalScale
                allowNegative={false}
                isAllowed={() => !disabledByProfile}
                renderText={(value) => <p className="small-form-value">{value}</p>}
              />
              <FormFeedback style={{ fontSize: '12px', margin: '0 0 5px 5px' }}>
                Ingrese un Monto Maximo válido.
              </FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="plazoMaximo">
                Plazo Máximo de Emisión (días)
                <span className="required text-danger">*</span>
              </Label>
              <Input
                name="plazoMaximo"
                id="plazoMaximo"
                value={plazoMaximo}
                onChange={(event) => setPlazoMaximo(event.target.value)}
                placeholder="Ingrese Plazo Máximo de Emisión"
                invalid={parseInt(plazoMaximo, 10) < 0}
                disabled={disabledByProfile}
              />
              <FormFeedback style={{ fontSize: '12px', margin: '0 0 5px 5px' }}>
                Ingrese un Plazo Maximo válido.
              </FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="diasGracia">
                Retroactividad Máxima (días)
                <span className="required text-danger">*</span>
              </Label>
              <Input
                name="diasGracia"
                id="diasGracia"
                value={diasGracia}
                onChange={(event) => setDiasGracia(event.target.value)}
                placeholder="Ingrese Retroactividad Máxima"
                invalid={parseInt(diasGracia, 10) < 0}
                disabled={disabledByProfile}
              />
              <FormFeedback style={{ fontSize: '12px', margin: '0 0 5px 5px' }}>
                Ingrese una cantidad de Retroactividad Máxima válida.
              </FormFeedback>
            </FormGroup>
            {(!clasificacionTecnica ? (
              false
            ) : (
              !isClasificacionNoProporcional()
            )) ? (
              <FormGroup>
                <Label for="reaseguroPct">% Cesión</Label>
                <span className="required text-danger">*</span>
                <NumberFormat
                  className="form-control"
                  name="reaseguroPct"
                  id="reaseguroPct"
                  value={reaseguroPct}
                  onValueChange={({floatValue}) => setReaseguroPct(floatValue)}
                  placeholder="Ingrese % Cesión"
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale="2"
                  suffix="%"
                  fixedDecimalScale
                  allowNegative={false}
                  isAllowed={() => !disabledByProfile}
                  renderText={(value) => <p className="small-form-value">{value}</p>}
                />
                <FormFeedback style={{ fontSize: '12px', margin: '0 0 5px 5px' }}>
                  Ingrese un % Cesión válido.
                </FormFeedback>
              </FormGroup>
            ) : (
              ''
            )}
            {(!clasificacionTecnica ? (
              false
            ) : (
              isClasificacionNoProporcional()
            )) ? (
              <FormGroup>
                <Label for="montoContrato">Prima</Label>
                <span className="required text-danger">*</span>
                <NumberFormat
                  className="form-control"
                  name="montoContrato"
                  id="montoContrato"
                  value={montoContrato}
                  onValueChange={({floatValue}) => setMontoContrato(floatValue)}
                  placeholder="Ingrese Prima"
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale="2"
                  fixedDecimalScale
                  allowNegative={false}
                  isAllowed={() => !disabledByProfile}
                  renderText={(value) => <p className="small-form-value">{value}</p>}
                />
                <FormFeedback style={{ fontSize: '12px', margin: '0 0 5px 5px' }}>
                  Ingrese una Prima válida.
                </FormFeedback>
              </FormGroup>
            ) : (
              ''
            )}
            {(!clasificacionTecnica ? (
              false
            ) : (
              isClasificacionNoProporcional()
            )) ? (
              <FormGroup>
                <Label for="limiteContrato">Límite Cobertura</Label>
                <span className="required text-danger">*</span>
                <NumberFormat
                  className="form-control"
                  name="limiteContrato"
                  id="limiteContrato"
                  value={limiteContrato}
                  onValueChange={({floatValue}) => setLimiteContrato(floatValue)}
                  placeholder="Ingrese Limite Cobertura"
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale="2"
                  fixedDecimalScale
                  allowNegative={false}
                  isAllowed={() => !disabledByProfile}
                  renderText={(value) => <p className="small-form-value">{value}</p>}
                />
                <FormFeedback style={{ fontSize: '12px', margin: '0 0 5px 5px' }}>
                  Ingrese un Límite Cobertura válido.
                </FormFeedback>
              </FormGroup>
            ) : (
              ''
            )}
            {(!clasificacionTecnica ? (
              false
            ) : (
              isClasificacionNoProporcional()
            )) ? (
              <FormGroup>
                <Label for="prioridadContrato">Prioridad</Label>
                <span className="required text-danger">*</span>
                <NumberFormat
                  className="form-control"
                  name="prioridadContrato"
                  id="prioridadContrato"
                  value={prioridadContrato}
                  onValueChange={({floatValue}) => setPrioridadContrato(floatValue)}
                  placeholder="Ingrese Prioridad"
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale="2"
                  fixedDecimalScale
                  allowNegative={false}
                  isAllowed={() => !disabledByProfile}
                  renderText={(value) => <p className="small-form-value">{value}</p>}
                />
                <FormFeedback style={{ fontSize: '12px', margin: '0 0 5px 5px' }}>
                  Ingrese una Prioridad válida.
                </FormFeedback>
              </FormGroup>
            ) : (
              ''
            )}
            {(!clasificacionTecnica ? (
              false
            ) : (
              isClasificacionNoProporcional()
            )) ? (
              <FormGroup>
                <Label for="reinstalacionesContrato">Número de Reinstalaciones</Label>
                <span className="required text-danger">*</span>
                <NumberFormat
                  className="form-control"
                  name="reinstalacionesContrato"
                  id="reinstalacionesContrato"
                  value={reinstalacionesContrato}
                  onValueChange={({floatValue}) => setReinstalacionesContrato(floatValue)}
                  placeholder="Ingrese Número de Reinstalaciones"
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale="2"
                  fixedDecimalScale
                  allowNegative={false}
                  isAllowed={() => !disabledByProfile}
                  renderText={(value) => <p className="small-form-value">{value}</p>}
                />
                <FormFeedback style={{ fontSize: '12px', margin: '0 0 5px 5px' }}>
                  Ingrese un Número de Reinstalaciones válido.
                </FormFeedback>
              </FormGroup>
            ) : (
              ''
            )}
          </div>
        </FormSection>
      </Col>
      <ReaseguradoresForm
        rol={rol}
        reaseguradorContrato={reaseguradorContrato}
        setReaseguradorContratoProperty={setReaseguradorContratoProperty}
        handleSaveReaseguradorContrato={handleSaveReaseguradorContrato}
        clearReasegurador={() => {
          setReaseguradorContrato({...reaseguradorContratoInitialState});
        }}
        editable={editable}
      />
      <ReaseguradoresTable
        tableData={tableData}
        handleEditReaseguradorContrato={handleEditReaseguradorContrato}
        handleDeleteReaseguradorContrato={handleDeleteReaseguradorContrato}
        impuestoTotal={impuestoTotal}
        comisionTotal={comisionTotal}
        participacionTotal={participacionTotal}
      />
      {disabledByProfile ? (
        <Col
          md={{ offset: 2, size: 8 }}
          style={{
            padding: 15,
          }}
        >
          <Col md={{ offset: 10 }}>
            <div
              style={{
                display: 'grid',
                gridGap: '10px',
                gridTemplateColumns: '1fr',
              }}
            >
              <div>
                <Link
                  style={{
                    background: 'transparent',
                    color: '#F98724',
                    border: '1px solid #F98724',
                    width: '100%',
                  }}
                  className="btn btn-secondary"
                  to="/reaseguros/contratos/"
                >
                  Volver
                </Link>
              </div>
            </div>
          </Col>
        </Col>
      ) : (
        <Col
          md={{ offset: 2, size: 8 }}
          style={{
            padding: 15,
          }}
        >
          <Col md={{ offset: 8 }}>
            <div
              style={{
                display: 'grid',
                gridGap: '10px',
                gridTemplateColumns: '1fr 1fr',
              }}
            >
              <div>
                <Link
                  style={{
                    background: 'transparent',
                    color: '#F98724',
                    border: '1px solid #F98724',
                    width: '100%',
                  }}
                  className="btn btn-secondary"
                  to="/reaseguros/contratos/"
                >
                  Cancelar
                </Link>
              </div>
              <div>
                <Button
                  style={{
                    background: '#F98724',
                    color: '#FFFFFF',
                    border: '1px solid #F98724',
                    width: '100%',
                  }}
                  className="btn btn-primary"
                  to="/reaseguros/contratos/"
                  disabled={isDisabled()}
                  onClick={() => {
                    if (!validTipoContrato()) {
                      return changeStateModal(
                        true,
                        `Ya existe un contrato automático de ${capaContrato &&
                        capaContrato.value &&
                        capaContrato.value.nombre} para el periodo ingresado, por favor ingrese otro tipo de contrato`,
                      )
                    }
                    if (!validParticipation()) {
                      return changeStateModal(
                        true,
                        "La participación total no puede superar el 100%",
                      )
                    }
                    if (participacionTotal < 100) {
                      return setDisplayModalWarning(true);
                    }
                    return editable ? saveEditContrato() : saveContrato();
                  }}
                >
                  {loading ?
                    (
                      <FontAwesomeIcon
                        spin={loading}
                        icon={faSpinner}
                      />
                    ) : "Guardar"
                  }
                </Button>
              </div>
            </div>
          </Col>
        </Col>
      )}
      <WarningModal
        isOpen={displayModal}
        toggle={() => setDisplayModal(false)}
        handleOk={() => setDisplayModal(false)}
        okBtnText="Aceptar"
        confirmMsg={msgModal}
      />
      <WarningModal
        isOpen={displayModalWarning}
        toggle={() => setDisplayModalWarning(false)}
        handleOk={() => saveWithLessParticipation()}
        okBtnText="Aceptar"
        confirmMsg="Advertencia: está creando un contrato con participación total menor a 100% ¿está seguro de que quiere guardarlo?"
      />
    </div>
  );
};

export default ContratosForm;
