import React, { useState, useEffect } from 'react';
import BaseTable from 'components/UI/BaseTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEye } from '@fortawesome/free-solid-svg-icons';
import useTableFilters from 'helpers/useTableFilters';
import { fdate } from 'helpers/helpers';
import { get } from 'helpers/requests';
import { Link } from 'react-router-dom';
import DateTableFilter from 'components/shared/DateTableFilter';
import FilterSelect from 'components/shared/FilterSelect';
import {
  headerOperation,
  cellOperation,
  cellNumOperation,
  headerDoubleRowOperation,
} from 'helpers/tables';
import { BASE_URL, API, FRONT_DF_1 } from 'CONST';
import isUserHasProfile from '../../../helpers/profileValidator';
import { REASEGURO_WRITE } from '../../../helpers/profilePermission';

const DEFAULT_VALUE = -1;

const ContratosTable = () => {
  const [optionsTipoContrato, setOptionsTipoContrato] = useState([]);
  const [optionsCapaContrato, setOptionsCapaContrato] = useState([]);
  const [optionsClasificacionTecnica, setOptionsClasificacionTecnica] = useState([]);
  const [optionsTipoReaseguro, setOptionsTipoReaseguro] = useState([]);
  const [optionsRamosFecu, setOptionsRamosFecu] = useState([]);

  const optionsById = (options, accesor, id) => options.map((elem) => ({label: elem[accesor], value: elem[id]}));

  useEffect(() => {
    get(`${BASE_URL}${API.tipoContrato}`).then((response) =>
      setOptionsTipoContrato(optionsById(response.results, 'nombre', 'id')),
    );

    get(`${BASE_URL}${API.capaContrato}`).then((response) =>
      setOptionsCapaContrato(optionsById(response.results, 'nombre', 'id')),
    );

    get(`${BASE_URL}${API.clasificacionTecnica}`).then((response) =>
      setOptionsClasificacionTecnica(optionsById(response.results, 'nombre', 'id')),
    );

    get(`${BASE_URL}${API.tipoReaseguro}`).then((response) =>
      setOptionsTipoReaseguro(optionsById(response.results, 'nombre', 'id')),
    );

    get(`${BASE_URL}${API.ramosFecu}`).then((response) =>
      setOptionsRamosFecu(optionsById(response.results, 'descripcion', 'nemotecnico')),
    );
  }, []);

  const [onFilteredChange, onFetchData, loading, data] = useTableFilters(
    `${BASE_URL}${API.contratos}`,
    false,
    '',
    [{ id: 'fecha_inicio', format: FRONT_DF_1 }, { id: 'fecha_termino', format: FRONT_DF_1 }],
    0,
  );

  // Constantes para validación de perfil
  const disabledByProfile = !isUserHasProfile(REASEGURO_WRITE);

  const getNameByParam = (dataParam) => {
    return dataParam.nombre
  };

  const columns = [
    {
      Header: headerOperation('ID Orsan'),
      id: 'orsan_id__icontains',
      accessor: 'orsan_id',
      Cell: (row) => cellOperation(row.value),
      sortable: false,
      minWidth: 80,
    },
    {
      Header: headerOperation('Nombre de Contrato'),
      id: 'nombre_contrato__icontains',
      accessor: 'nombre_contrato_reaseguro',
      Cell: (row) => cellOperation(row.value),
      sortable: false,
      minWidth: 120,
    },
    {
      Header: headerDoubleRowOperation('Tipo', 'Contrato'),
      id: 'tipo',
      accessor: 'tipo_contrato_data',
      Cell: (row) => cellOperation(getNameByParam(row.value)),
      sortable: false,
      minWidth: 100,
      Filter: (obj) => {
        return (
          <FilterSelect
            onFilterChange={obj.onChange}
            filter={obj.filter}
            options={optionsTipoContrato}
            defaultOption={{ label: 'Todos', value: DEFAULT_VALUE }}
          />
        )
      },
    },
    {
      Header: headerDoubleRowOperation('Capa', 'Contrato'),
      id: 'capa_contrato',
      accessor: 'capa_contrato_data',
      Cell: (row) => cellOperation(getNameByParam(row.value)),
      sortable: false,
      Filter: (obj) => {
        return (
          <FilterSelect
            onFilterChange={obj.onChange}
            filter={obj.filter}
            options={optionsCapaContrato}
            defaultOption={{ label: 'Todos', value: DEFAULT_VALUE }}
          />
        )
      },
      minWidth: 100,
    },
    {
      Header: headerDoubleRowOperation('Clasificación', 'Técnica'),
      id: 'clasificacion_tecnica',
      accessor: 'clasificacion_tecnica_data',
      Cell: (row) => cellOperation(getNameByParam(row.value)),
      sortable: false,
      Filter: (obj) => {
        return (
          <FilterSelect
            onFilterChange={obj.onChange}
            filter={obj.filter}
            options={optionsClasificacionTecnica}
            defaultOption={{ label: 'Todos', value: DEFAULT_VALUE }}
          />
        )
      },
      minWidth: 100,
    },
    {
      Header: headerOperation('Tipo Reaseguro'),
      id: 'tipo_reaseguro',
      accessor: 'tipo_reaseguro_data',
      Cell: (row) => cellOperation(getNameByParam(row.value)),
      sortable: false,
      Filter: (obj) => {
        return (
          <FilterSelect
            onFilterChange={obj.onChange}
            filter={obj.filter}
            options={optionsTipoReaseguro}
            defaultOption={{ label: 'Todos', value: DEFAULT_VALUE }}
          />
        )
      },
      minWidth: 100,
    },
    {
      Header: headerOperation('Ramo FECU'),
      id: 'ramo_fecu_id',
      accessor: 'ramo_fecu_nombre',
      Cell: (row) => cellOperation(row.value),
      sortable: false,
      Filter: (obj) => {
        return (
          <FilterSelect
            onFilterChange={obj.onChange}
            filter={obj.filter}
            options={optionsRamosFecu}
            defaultOption={{ label: 'Todos', value: DEFAULT_VALUE }}
          />
        )
      },
      minWidth: 100,
    },
    {
      Header: headerOperation('Fecha de Inicio'),
      id: 'fecha_inicio',
      accessor: 'fecha_inicio',
      Cell: (row) => cellOperation(fdate(row.value)),
      // eslint-disable-next-line react/prop-types
      Filter: ({ filter, onChange }) => (
        <DateTableFilter filter={filter} onChange={onChange} format={FRONT_DF_1} />
      ),
      sortable: false,
      minWidth: 100,
    },
    {
      Header: headerOperation('Fecha de Término'),
      id: 'fecha_termino',
      accessor: 'fecha_termino',
      Cell: (row) => cellOperation(fdate(row.value)),
      // eslint-disable-next-line react/prop-types
      Filter: ({ filter, onChange }) => (
        <DateTableFilter filter={filter} onChange={onChange} format={FRONT_DF_1} />
      ),
      sortable: false,
      minWidth: 100,
    },
    {
      Header: headerOperation('Prioridad'),
      id: 'prioridad',
      accessor: 'prioridad',
      Cell: (row) => cellOperation(row.value),
      sortable: false,
      minWidth: 100,
    },
    {
      Header: headerOperation('% Cesión'),
      id: 'reaseguro_pct',
      accessor: 'reaseguro_pct',
      Cell: (row) => cellOperation(row.value),
      sortable: false,
      minWidth: 100,
    },
    {
      Header: headerOperation('Prima'),
      id: 'monto',
      accessor: 'monto',
      Cell: (row) => cellNumOperation(row.value),
      sortable: false,
      minWidth: 100,
    },
    {
      Header: headerDoubleRowOperation('Monto Máximo', 'Cobertura'),
      id: 'monto_maximo',
      accessor: 'monto_maximo',
      Cell: (row) => cellNumOperation(row.value),
      sortable: false,
      minWidth: 100,
    },
    {
      Header: headerOperation('Acciones'),
      id: 'actions',
      accessor: (i) => (
        <div
          style={{ display: 'grid', gridTemplateColumns: '1fr', gridGap: '20px' }}
          className="table-cell"
        >
          <div style={{ textAlign: 'center' }}>
            <Link to={`/reaseguros/contratos/editar/${i.id}`} className="table-link">
              {disabledByProfile ? (
                <FontAwesomeIcon icon={faEye} />
              ) : (
                <FontAwesomeIcon icon={faEdit} />
              )}
            </Link>
          </div>
        </div>
      ),
      filterable: false,
      sortable: false,
      minWidth: 80,
    },
  ];
  return (
    <>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr auto auto',
          gridGap: '10px',
          marginTop: '15px',
          marginBottom: '6px',
        }}
      />
      <BaseTable
        columns={columns}
        data={data.data}
        filterable
        loading={loading}
        onFetchData={onFetchData}
        onFilteredChange={onFilteredChange}
        pages={data.pages}
        resultCount={data.count}
        resizable={false}
        showPagination
        noBorders
      />
    </>
  );
};

export default ContratosTable;
