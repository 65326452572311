import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { Col, Row } from 'reactstrap';
import { cleanEstadoDiario, getData } from '../../../redux-store/actions';
import { GET_ESTADO } from '../../../redux-store/constants/action-types';
import { API, BASE_URL } from '../../../CONST';
import PageContainer from '../../UI/PageContainer';
import CierreOpDetailResumen from './CierreOpDetailResumen';
import CierreOpMovimientosTable from './CierreOpMovimientosTable';

class CierreOperacionalDetail extends Component {
  componentDidMount() {
    const {
      dispatch,
      match: {
        params: { id: cierreDiarioId },
      },
    } = this.props;
    dispatch(cleanEstadoDiario());
    dispatch(
      getData({
        url: `${BASE_URL}${API.cierre_diario}${cierreDiarioId}`,
        type: GET_ESTADO,
      }),
    );
  }

  render() {
    const {
      match: {
        params: { id: cierreDiarioId, date: fechaCierreOp },
      },
    } = this.props;
    return (
      <PageContainer
        breadcrumbs={[
          { id: 1, name: 'GARANTÍA', url: '/reservas/garantia/estados_diarios' },
          { id: 2, name: 'CIERRE OPERACIONAL', url: '/reservas/garantia/cierre_operacional' },
          { id: 3, name: `CIERRE ${moment(fechaCierreOp).format('DD-MM-YYYY')}` },
        ]}
      >
        <CierreOpDetailResumen />
        <div id="movimientos" className="table-container">
          <Row>
            <Col className="title-container">
              <h5>Movimientos</h5>
            </Col>
          </Row>
          <CierreOpMovimientosTable cierreDiarioId={cierreDiarioId} fechaCierreOp={fechaCierreOp} />
        </div>
      </PageContainer>
    );
  }
}

CierreOperacionalDetail.propTypes = {
  dispatch: PropTypes.func.isRequired,
  login: PropTypes.shape({
    user_token: PropTypes.string,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      date: PropTypes.string,
      id: PropTypes.string,
    }),
  }).isRequired,
};

const mapStateToProps = (state) => ({
  login: state.login,
});

export default connect(mapStateToProps)(CierreOperacionalDetail);
