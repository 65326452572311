import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { cellNumOperation, cellOperation, headerOperation, } from '../../../helpers/tables';
import Estado from '../../UI/Estado';
import BaseTable from '../../UI/BaseTable';


const CierresOperacionalesTable = ({ estados }) => {
  const columns = [
    {
      Header: headerOperation('Fecha'),
      id: 'fecha',
      accessor: i => cellOperation(i.fecha),
      sortable: false,
      minWidth: 100,
    },
    {
      Header: headerOperation('Movimientos'),
      id: 'movimientos',
      accessor: i => cellNumOperation(i.movimientos_operacionales),
      sortable: false,
      minWidth: 100,
    },
    {
      Header: headerOperation('Emisiones'),
      id: 'emisiones',
      accessor: i => cellNumOperation(i.emisiones_operacionales),
      sortable: false,
      minWidth: 90,
    },
    {
      Header: headerOperation('Endosos'),
      id: 'endosos',
      accessor: i => cellNumOperation(i.endosos_operacionales),
      sortable: false,
      minWidth: 90,
    },
    {
      Header: headerOperation('Estado'),
      id: 'estado',
      accessor: i => cellOperation(
        <Estado estado={i.estado} />
      ),
      sortable: false,
      width: 180,
    },
    {
      Header: headerOperation('Acciones'),
      id: 'acciones',
      accessor: i => i.estado !== 'Pendiente' ? (
        <div
          className="table-cell"
          style={{ textAlign: 'center' }}
        >
          <span title="ver">
            <Link
              to={`/reservas/garantia/cierre_operacional/${i.id}/${i.fecha}`}
              className="table-link policy"
            >
              <FontAwesomeIcon icon={faEye} />
            </Link>
          </span>
        </div>
      ) : cellOperation(),
      sortable: false,
      width: 200,
    },
  ];

  return (
    <div className="table-container">
      <BaseTable
        columns={columns}
        data={estados}
        resizable={false}
        style={{ height: "50vh" }}
        noBorders
      />
    </div>
  )
};

CierresOperacionalesTable.propTypes = {
  estados: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default CierresOperacionalesTable;
