import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { Row, Col, Button, Modal, ModalBody, ModalFooter, ModalHeader, Label } from 'reactstrap';
import PropTypes from 'prop-types';
import { API, BASE_URL, BACKEND_DF } from 'CONST';
import { get, patch } from 'helpers/requests';
import { objectsToOptions, objectToOption } from 'helpers/selects';
import moment from 'moment';

const ModalForm = ({
  handleOpen,
  handleClose,
  poliza,
  disabled,
  history,
  id,
  date,
  garantia,
  getMovimientos,
}) => {
  const [polizaNumber, setPolizaNumber] = useState('');
  const [primeraCapa, setPrimeraCapa] = useState('');
  const [segundaCapa, setSegundaCapa] = useState('');
  const [optionsContrato, setOptionsContrato] = useState([]);
  const endpoint = garantia ? API.movimientoDiarioCalculado : API.movimientoDiarioCreditoCalculado;

  // Efecto para poliza actual a modificar
  useEffect(() => {
    if (poliza) {
      const fechaVigencia = moment(poliza.fecha_emision, 'DD-MMM-YYYY').format(BACKEND_DF);
      setPolizaNumber(poliza.poliza);

      get(`${BASE_URL}${endpoint}/${poliza.id_calculado}`).then((response) =>
        setSegundaCapa(
          response.contratos_segunda_capa.map((obj) => objectToOption(obj, 'nombre_contrato')),
        ),
      );

      get(`${BASE_URL}${API.contratos}${poliza.contrato_reaseguro_num}`).then((response) =>
        setPrimeraCapa({ label: response.nombre_contrato, value: response }),
      );

      get(
        `${BASE_URL}${API.contratos}?fecha_inicio__gte=${fechaVigencia}&fecha_termino__gte=${fechaVigencia}&ramo_fecu_id=${poliza.ramo_fecu_nemo}`,
      ).then((response) =>
        setOptionsContrato(objectsToOptions(response.results, false, false, 'nombre_contrato')),
      );
    }
  }, [poliza]);

  const savePoliza = () => {
    const urlRedirect = `/reaseguros/${
      garantia ? 'garantia' : 'credito'
    }/emisiones_diarias/editar/${id}/${date}`;

    patch(`${BASE_URL}${endpoint}/${poliza.id_calculado}/`, {
      contrato_reaseguro_num: primeraCapa.value.id,
      contrato_segunda_capa_ids: [...new Set(segundaCapa.map((obj) => obj.value.id))],
    }).then(() => {
      handleClose();
      history.push(urlRedirect);
      getMovimientos();
    });
  };

  return (
    <Modal isOpen={handleOpen} toggle={handleClose} size="lg">
      <ModalHeader
        className="endoso-form"
        toggle={handleClose}
        style={{ padding: '30px 30px 0px 30px' }}
      >
        <h5 style={{ fontWeight: 'bold', gridTemplateColumns: '1fr 1fr', gridGap: '20px' }}>
          {`Contratos Póliza ${polizaNumber}`}
        </h5>
      </ModalHeader>

      <ModalBody className="endoso-form">
        <Row>
          <Col>
            <Label for="primeraCapaContrato" style={{ fontWeight: '600', fontSize: '14px' }}>
              Contrato de Primera Capa
            </Label>
            <Select
              id="primeraCapaContrato"
              name="primeraCapaContrato"
              className="custom-policy-select"
              options={optionsContrato}
              onChange={(event) => setPrimeraCapa(event)}
              value={primeraCapa}
              placeholder="Seleccione Primera Capa de Contrato"
            />
          </Col>
          <Col>
            <Label for="segundaCapaContrato" style={{ fontWeight: '600', fontSize: '14px' }}>
              Contrato de Segunda Capa
            </Label>
            <Select
              id="segundaCapaContrato"
              name="segundaCapaContrato"
              className="custom-policy-select"
              classNamePrefix="select"
              isMulti
              options={optionsContrato}
              onChange={(event) => setSegundaCapa(event)}
              value={segundaCapa}
              placeholder="Seleccione Segunda Capa de Contrato"
            />
          </Col>
        </Row>
      </ModalBody>

      <ModalFooter className="text-right endoso-form">
        <Col md={{ offset: 6 }}>
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '10px' }}>
            <Button
              style={{
                background: 'transparent',
                border: '1px solid #F98724',
                color: '#F98724',
                fontSize: '14px',
                width: '100%',
              }}
              onClick={handleClose}
            >
              Cancelar
            </Button>
            <Button
              style={{
                fontSize: '14px',
              }}
              color="btn btn-orsan"
              onClick={() => {
                savePoliza();
              }}
              disabled={disabled}
            >
              Guardar
            </Button>
          </div>
        </Col>
      </ModalFooter>
    </Modal>
  );
};

ModalForm.propTypes = {
  handleOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  poliza: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  history: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  garantia: PropTypes.bool.isRequired,
  getMovimientos: PropTypes.func.isRequired,
};

export default ModalForm;
