import React from 'react';
import PropTypes from 'prop-types';
import BaseTable from 'components/UI/BaseTable';
import { Col, Button } from 'reactstrap';
import { headerOperation, cellOperation, cellNumOperation } from 'helpers/tables';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import isUserHasProfile from '../../../helpers/profileValidator';
import { REASEGURO_WRITE } from '../../../helpers/profilePermission';

const ReaseguradoresTable = ({
  tableData,
  handleEditReaseguradorContrato,
  handleDeleteReaseguradorContrato,
  impuestoTotal,
  comisionTotal,
  participacionTotal,
}) => {
  // Constantes para validación de perfil
  const disabledByProfile = !isUserHasProfile(REASEGURO_WRITE);

  const columns = [
    {
      Header: headerOperation('Reasegurador'),
      id: 'nombre',
      accessor: 'reasegurador.value.nombre',
      Cell: (row) => cellOperation(row.value),
      filterable: false,
      sortable: false,
      minWidth: 120,
    },
    {
      Header: headerOperation('% Participación'),
      id: 'participacion',
      accessor: 'participacion',
      Cell: (row) => cellNumOperation(row.value),
      filterable: false,
      sortable: false,
      minWidth: 120,
      Footer: cellNumOperation(participacionTotal),
    },
    {
      Header: headerOperation('% Impuesto'),
      id: 'impuesto',
      accessor: 'impuesto',
      Cell: (row) => cellNumOperation(row.value),
      filterable: false,
      sortable: false,
      minWidth: 120,
      Footer: cellNumOperation(impuestoTotal),
    },
    {
      Header: headerOperation('% Comisión'),
      id: 'comision',
      accessor: 'comision',
      Cell: (row) => cellNumOperation(row.value),
      filterable: false,
      sortable: false,
      minWidth: 120,
      Footer: cellNumOperation(comisionTotal),
    },
    {
      Header: headerOperation('Acciones'),
      id: 'actions',
      accessor: (i) => (
        <div
          style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '20px' }}
          className="table-cell"
        >
          <div style={{ textAlign: 'right' }}>
            {disabledByProfile ? (
              ''
            ) : (
              <Button
                onClick={() => handleEditReaseguradorContrato(i)}
                className="table-link"
                title="Editar"
              >
                <FontAwesomeIcon icon={faEdit} />
              </Button>
            )}
          </div>
          <div style={{ textAlign: 'left' }}>
            {disabledByProfile ? (
              ''
            ) : (
              <Button
                onClick={() => handleDeleteReaseguradorContrato(i)}
                className="table-link"
                title="Eliminar"
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </Button>
            )}
          </div>
        </div>
      ),
      filterable: false,
      sortable: false,
      minWidth: 60,
    },
  ];
  return (
    <>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr auto auto',
          gridGap: '10px',
          marginTop: '15px',
          marginBottom: '6px',
        }}
      />
      <Col md={{ offset: 2, size: 8 }}>
        <BaseTable columns={columns} data={tableData} style={{ maxHeight: '63vh' }} />
      </Col>
    </>
  );
};

ReaseguradoresTable.propTypes = {
  tableData: PropTypes.arrayOf(
    PropTypes.shape({
      reasegurador: PropTypes.shape({
        id: PropTypes.string,
        rut: PropTypes.string,
        nombre: PropTypes.string,
      }),
      impuesto: PropTypes.string,
      participacion: PropTypes.string,
      comision: PropTypes.string,
    }),
  ).isRequired,
  handleEditReaseguradorContrato: PropTypes.func.isRequired,
  handleDeleteReaseguradorContrato: PropTypes.func.isRequired,
  impuestoTotal: PropTypes.number.isRequired,
  comisionTotal: PropTypes.number.isRequired,
  participacionTotal: PropTypes.number.isRequired,
};

export default ReaseguradoresTable;
