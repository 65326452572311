import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap';
import { DOCS_URL, POLIZA_URL, RISK_URL, USERS_URL, WORKFLOW_URL, ANALYST_URL } from '../../CONST';
import { signout } from '../../helpers/auth';
import '../../styles/navbar.css';
import isUserHasProfile from '../../helpers/profileValidator';
import {
  CIERRE_DIARIO_READ,
  CIERRE_MENSUAL_READ,
  CIERRE_OPERACIONAL_READ,
} from '../../helpers/profilePermission';

class ReservaNavBar extends Component {
  constructor(props) {
    super(props);

    this.toggleApps = this.toggleApps.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.toggleMDropdown = this.toggleMDropdown.bind(this);
    this.state = {
      appsOpen: false,
      cierresDiarios: false,
      cierresMensuales: false,
    };
  }

  handleSignOut = (event) => {
    event.preventDefault();
    signout();
  };

  toggleApps() {
    const { appsOpen } = this.state;
    this.setState({
      appsOpen: !appsOpen,
    });
  }

  toggleDropdown(e) {
    e.preventDefault();
    const { cierresDiarios } = this.state;
    this.setState({
      cierresDiarios: !cierresDiarios,
    });
  }

  toggleMDropdown(e) {
    e.preventDefault();
    const { cierresMensuales } = this.state;
    this.setState({
      cierresMensuales: !cierresMensuales,
    });
  }

  render() {
    const { user } = this.props;
    const { appsOpen, cierresDiarios, cierresMensuales } = this.state;
    let canAccesGarantia = false;

    if (user && user.profiles) {
      canAccesGarantia =
        isUserHasProfile(CIERRE_DIARIO_READ) ||
        isUserHasProfile(CIERRE_OPERACIONAL_READ) ||
        isUserHasProfile(CIERRE_OPERACIONAL_READ);
    }

    return (
      user && (
        <ul className="nav-links-group">
          <Dropdown nav className="nav-link-element" isOpen={appsOpen} toggle={this.toggleApps}>
            <DropdownToggle nav caret className="navbar-header">
              RESERVAS
            </DropdownToggle>
            <DropdownMenu>
              {user.apps.includes('users') && (
                <DropdownItem>
                  <a className="nav-link navbar-header" href={USERS_URL}>
                    USUARIOS
                  </a>
                </DropdownItem>
              )}
              {user.apps.includes('docs') && (
                <DropdownItem>
                  <a className="nav-link navbar-header" href={DOCS_URL}>
                    DOCUMENTOS
                  </a>
                </DropdownItem>
              )}
              {user.apps.includes('policies') && (
                <DropdownItem>
                  <a className="nav-link navbar-header" href={POLIZA_URL}>
                    PÓLIZAS
                  </a>
                </DropdownItem>
              )}
              {user.apps.includes('reaseguro') && (
                <DropdownItem>
                  <a className="nav-link navbar-header" href='/reaseguros'>
                    REASEGURO
                  </a>
                </DropdownItem>
              )}
              {user.apps.includes('risk') && (
                <DropdownItem>
                  <a className="nav-link navbar-header" href={RISK_URL}>
                    RIESGO
                  </a>
                </DropdownItem>
              )}
              {user.apps.includes('analista') && (
                <DropdownItem>
                  <a className="nav-link navbar-header" href={ANALYST_URL}>
                    RIESGO DE CRÉDITO
                  </a>
                </DropdownItem>
              )}
              {user.apps.includes('workflow') && (
                <DropdownItem>
                  <a className="nav-link navbar-header" href={WORKFLOW_URL}>
                    WORKFLOW
                  </a>
                </DropdownItem>
              )}
            </DropdownMenu>
          </Dropdown>
          {canAccesGarantia && (
            <Dropdown
              nav
              className="nav-link-element"
              isOpen={cierresDiarios}
              toggle={(e) => this.toggleDropdown(e)}
            >
              <DropdownToggle nav caret>
                Garantía
              </DropdownToggle>
              <DropdownMenu>
                {user.profiles && isUserHasProfile(CIERRE_DIARIO_READ) && (
                  <DropdownItem>
                    <Link className="nav-link" to="/reservas/garantia/estados_diarios">
                      Cierres Diarios
                    </Link>
                  </DropdownItem>
                )}
                {user.profiles && isUserHasProfile(CIERRE_MENSUAL_READ) && (
                  <DropdownItem>
                    <Link className="nav-link" to="/reservas/garantia/estados_mensuales">
                      Cierres Mensuales
                    </Link>
                  </DropdownItem>
                )}
                {user.profiles && isUserHasProfile(CIERRE_OPERACIONAL_READ) && (
                  <DropdownItem>
                    <Link className="nav-link" to="/reservas/garantia/cierre_operacional">
                      Cierres Operacionales
                    </Link>
                  </DropdownItem>
                )}
              </DropdownMenu>
            </Dropdown>
          )}
          <Dropdown
            nav
            className="nav-link-element"
            isOpen={cierresMensuales}
            toggle={(e) => this.toggleMDropdown(e)}
          >
            <DropdownToggle nav caret>
              Crédito
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem>
                <Link className="nav-link" to="/reservas/credito/estados_mensuales">
                  Cierres Mensuales
                </Link>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </ul>
      )
    );
  }
}

ReservaNavBar.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string,
    username: PropTypes.string,
    full_name: PropTypes.string,
    has_access: PropTypes.bool,
    apps: PropTypes.arrayOf(PropTypes.string),
    organization: PropTypes.number,
    sub: PropTypes.string,
    profiles: PropTypes.array,
  }).isRequired,
};

const mapStateToProps = (state) => ({ user: state.login.user });

export default withRouter(connect(mapStateToProps)(ReservaNavBar));
