import React from 'react';
import PageContainer from 'components/UI/PageContainer';
import { BASE_URL, API } from 'CONST';

import EmisionesDiariasTable from '../EmisionesDiariasTable';

const EmisionesDiariasCreditoContainer = () => {
  return (
    <PageContainer
      breadcrumbs={[
        { id: 0, name: 'CRÉDITO', url: '/reservas/credito/estados_mensuales' },
        { id: 1, name: 'EMISIONES DIARIAS' },
      ]}
    >
      <EmisionesDiariasTable endpoint={`${BASE_URL}${API.estadoDiarioCredito}`} garantia={false} />
    </PageContainer>
  );
};

export default EmisionesDiariasCreditoContainer;
