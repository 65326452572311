import React from 'react';
import { components } from 'react-select';
import '../../styles/modal.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";


const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <FontAwesomeIcon icon={faSpinner} className="datetime-icon fa-spin" />
  </components.DropdownIndicator>
);

export default DropdownIndicator;
