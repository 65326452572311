import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Button, Col, Row } from "reactstrap";
import Datetime from 'react-datetime';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCaretDown,
  faFileContract,
  faSpinner,
  faFileInvoiceDollar
} from '@fortawesome/free-solid-svg-icons';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import '../styles/datetime.css';
import DropdownIndicator from './UI/DropdownIndicator';
import SafeBtn from './shared/SafeBtn';

const EstadoFilter = ({
  date,
  dateFilter,
  handleChange,
  handleDate,
  handleDownload,
  handleFilter,
  loading,
  reportBtns,
  reportUtilities,
  reporte,
  reportes,
  year,
  quarter,
  creditMonthly,
  }) => (
    <Row className="filter-container">
      <Col md={2} className="estado-filter">
        <Datetime
          closeOnSelect
          dateFormat={year ? 'YYYY' : 'YYYY-MM'}
          id="estado-filter"
          locale="es"
          onChange={handleDate}
          renderInput={props => (
            <div className="datetime-wrapper">
              <input {...props} className="rs-input" />
              <FontAwesomeIcon 
                icon={faCaretDown}
                className="datetime-icon"
                style={{ position: 'absolute', margin: '10px -20px' }}
              />
            </div>
          )}
          timeFormat={false}
          value={moment(date).format(year ? 'YYYY' :'MMMM-YYYY')}
        />
      </Col>
      {reportBtns && (
        <Col className="text-right">
          <div className="button-container">
            <Button
              className="btn btn-orsan"
              onClick={() => handleDownload('libro_contable_produccion_mensual')}
            >
              <FontAwesomeIcon
                icon={faFileInvoiceDollar}
              />
              <span className="text">
                Reporte Libro Prod.
              </span>
            </Button>
            <Button
              className="btn btn-orsan"
              onClick={() => handleDownload('libro_produccion_operation_mensual')}
            >
              <FontAwesomeIcon
                icon={faFileContract}
              />
              <span className="text">
                Libro Prod. Operaciones
              </span>
            </Button>
            <Button
              className="btn btn-orsan"
              onClick={() => handleDownload('libro_contable_mensual')}
            >
              <FontAwesomeIcon
                icon={faFileInvoiceDollar}
              />
              <span className="text">
                Libro Contable
              </span>
            </Button>
          </div>
        </Col>
      )}
      {reportUtilities && (
        <Col className="text-right">
          <div className="button-container">
            <Button
              className="btn btn-orsan" 
              onClick={() => handleDownload('reporte_utilidades')}
              disabled={loading}
            >
              <FontAwesomeIcon
                spin={loading}
                icon={loading ? faSpinner : faFileContract}
              />
              <span className="text">
                Reporte de Utilidades
              </span>
            </Button>
          </div>
        </Col>
      )}
      {creditMonthly && (
        <Col className="text-right">
          <div className="button-container">
            <SafeBtn
              className="btn btn-orsan"
              onClick={() => handleDownload()}
              disabled={loading}
              loading={loading}
            >
              <FontAwesomeIcon
                icon={faFileContract}
              />
              <span className="text">
                Reporte de Crédito
              </span>
            </SafeBtn>
          </div>
        </Col>
      )}
      {year && !quarter && (
        <Col className="fecha-arbitraria d-flex justify-content-end">
          <div className="title group">
          Descargar reporte para fecha arbitraria
          </div>
          <div className={`group${loading ? ' disabled' : ''}`}>
            <Datetime
              closeOnSelect
              dateFormat="DD-MM-YYYY"
              id="estado-date"
              inputProps={{ disabled: loading, readOnly: loading }}
              locale="es"
              onChange={handleFilter}
              renderInput={props => (
                <div className="datetime-wrapper">
                  <input {...props} className="rs-input" />
                  <FontAwesomeIcon
                    icon={faCalendar}
                    className="datetime-icon"
                  />
                </div>
              )}
              timeFormat={false}
              value={moment(dateFilter).format('DD-MM-YYYY')}
            />
          </div>
          <div className="group">
            <Select
              components={loading ? {DropdownIndicator} : undefined}
              id="reporte"
              isDisabled={loading}
              name="reporte"
              options={reportes}
              onChange={handleChange}
              placeholder="Seleccione reporte"
              value={reporte}
            />
          </div>
          <div className="button-container group">
            <Button
              className="btn-orsan"
              onClick={handleDownload}
              disabled={loading}
            >
              Descargar
            </Button>
          </div>
        </Col>
      )}
    </Row>
);

EstadoFilter.propTypes = {
  date: PropTypes.shape({}).isRequired,
  dateFilter: PropTypes.string,
  handleChange: PropTypes.func,
  handleDate: PropTypes.func.isRequired,
  handleDownload: PropTypes.func,
  reportBtns: PropTypes.bool,
  reportUtilities: PropTypes.bool,
  creditMonthly: PropTypes.bool,
  handleFilter: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  reporte: PropTypes.string,
  reportes: PropTypes.arrayOf(PropTypes.object),
  year: PropTypes.bool,
  quarter: PropTypes.bool,
};

EstadoFilter.defaultProps = {
  handleChange: () => {},
  handleDownload: () => {},
  handleFilter: () => {},
  reportBtns: false,
  reportUtilities: false,
  creditMonthly: false,
  reporte: '',
  reportes: [],
  year: false,
  quarter: false,
  dateFilter: null,
}
;

export default EstadoFilter;
