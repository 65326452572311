import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { cleanEstadoDiario, getData, patchData } from '../../../redux-store/actions';
import {
  GET_ESTADO_LIST,
  ESTADO_CHANGE,
} from '../../../redux-store/constants/action-types';
import { API, BASE_URL } from '../../../CONST';
import PageContainer from '../../UI/PageContainer';
import EstadoFilter from '../../EstadoFilter';
import EstadoListTable from './EstadoListTable';
import DeprecatedWarningModal from '../../UI/DeprecatedWarningModal';
import { fileDownload, getEstadosUrl } from "../../../helpers/helpers";

const moment = require('moment');

function mapDispatchToProps(dispatch) {
  return {
    estadoChange: policy => dispatch(patchData(policy)),
    getEstados: policy => dispatch(getData(policy)),
    resetData: policy => dispatch(cleanEstadoDiario(policy)),
  }
}

class EstadoList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeEstado: undefined,
      date: moment(),
      warningOpen: false,
    };
    this.url = `${BASE_URL}${API.estados_diarios}`;
    this.handleChangeWarning = this.handleChangeWarning.bind(this);
    this.handleDate = this.handleDate.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
    this.handleEstadoChange = this.handleEstadoChange.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
  }

  componentDidMount() {
    const { resetData, getEstados } = this.props;
    resetData();
    getEstados({
      url: this.url,
      type: GET_ESTADO_LIST,
    });
  }

  componentDidUpdate(prevProps) {
    const { estados } = this.props;
    const { activeEstado } = this.state;
    if(activeEstado && (prevProps.estados !== estados)) {
      this.handleUpdate();
    }
  }

  handleUpdate() {
    this.setState({
      warningOpen: false,
      activeEstado: undefined,
    })
  }

  handleDate(date) {
    const { getEstados } = this.props;
    if (date <= moment()) {
      getEstados({
        url: `${this.url}?month=${date.format('YYYY-MM')}`,
        type: GET_ESTADO_LIST,
      });
      this.setState({ date })
    }
  }

  handleChangeWarning(e, node) {
    const { warningOpen } = this.state;
    e.preventDefault();
    this.setState({
      warningOpen: !warningOpen,
      activeEstado: !warningOpen ? node : undefined,
    })
  }

  handleEstadoChange(node, onFailedReq = () => (null)) {
    const { estadoChange } = this.props;
    estadoChange({
      url: `${this.url}${node.id}/`,
      type: ESTADO_CHANGE,
      onFailedReq,
      config: { activo: !(node.activo) }
    });
  }

  handleDownload(type) {
    const { date } = this.state;
    fileDownload(getEstadosUrl(true, date.format('YYYY-MM'), type));
  }

  render() {
    const { activeEstado, date, warningOpen } = this.state;
    return(
      <PageContainer
        breadcrumbs={[
          { id: 1, name: 'GARANTÍA', url: '/reservas/garantia/estados_diarios' },
          { id: 2, name: 'CIERRES DIARIOS' }
        ]}
      >
        <EstadoFilter
          date={date}
          handleDate={this.handleDate}
          handleDownload={this.handleDownload}
          reportBtns
          loading={false}
        />
        <EstadoListTable
          handleChangeWarning={this.handleChangeWarning}
          activeEstado={activeEstado}
          modalOpen={warningOpen}
        />
        {activeEstado && (
          <DeprecatedWarningModal
            handleModal={this.handleChangeWarning}
            handleSubmit={this.handleEstadoChange}
            node={activeEstado}
            modalOpen={warningOpen}
          />
        )}
      </PageContainer>
    )
  }
}

const mapStateToProps = state => {
  return {
    estados: state.estados.estadoList,
  }
};

EstadoList.propTypes = {
  estadoChange: PropTypes.func.isRequired,
  estados: PropTypes.arrayOf(PropTypes.object).isRequired,
  getEstados: PropTypes.func.isRequired,
  resetData: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(EstadoList);
