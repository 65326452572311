import {
  CLEAN_ESTADO_MENSUAL,
  ESTADO_MENSUAL_CHANGE,
  GET_ESTADO_MENSUAL,
  GET_ESTADO_MENSUAL_LIST,
  LOADING_CHANGE,
} from '../constants/action-types';

const initialState = {
  estadoList: [],
  detalleEstado: undefined,
  cuentas: [],
  errorMessage: undefined,
  loading: false,
  successMessage: false
};

const executeLoading = (state) => {
  return {
    ...state,
    loading: !state.loading,
  }
};

const executeGetEstadoListSuccess = (state, action) => {
  return {
    ...state,
    estadoList: action.payload,
    successMessage: undefined,
    errorMessage: undefined,
  }
};

const executeGetEstadoSuccess = (state, action) => {
  return {
    ...state,
    detalleEstado: action.payload,
    cuentas: action.payload.cuentas,
    successMessage: undefined,
    errorMessage: undefined,
  }
};

const executeChangeEstadoSuccess = (state, action) => {
  const { estadoList } = state;
  const updatedEstado = action.payload;
  const estado = state.estadoList.find(node => node.id === updatedEstado.id);
  const index = state.estadoList.indexOf(estado);
  state.estadoList.splice(index, 1, updatedEstado);
  return {
    ...state,
    estadoList,
    successMessage: undefined,
    errorMessage: undefined,
  }
};

const executeCleanEstadoSuccess = (state) => {
  return {
    ...state,
    selectedEstado: undefined,
    errorMessage: undefined,
    successMessage: false
  }
};

function estadosMensuales(state = initialState, action) {
  switch (action.type) {
    case GET_ESTADO_MENSUAL:
      return executeGetEstadoSuccess(state, action);
    case GET_ESTADO_MENSUAL_LIST:
      return executeGetEstadoListSuccess(state, action);
    case ESTADO_MENSUAL_CHANGE:
      return executeChangeEstadoSuccess(state, action);
    case CLEAN_ESTADO_MENSUAL:
      return executeCleanEstadoSuccess(state, action);
    case LOADING_CHANGE:
      return executeLoading(state, action);
    default:
      return state;
  }
}

export default estadosMensuales;
