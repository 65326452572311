import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  cleanEstadoDiario,
  getData,
  handleLoading,
  patchData,
} from '../../../redux-store/actions';
import {
  GET_ESTADO_MENSUAL_LIST,
  ESTADO_MENSUAL_CHANGE,
} from '../../../redux-store/constants/action-types';
import { API, BASE_URL } from '../../../CONST';
import PageContainer from '../../UI/PageContainer';
import EstadoFilter from '../../EstadoFilter';
import EstadoMensualListTable from './GarantiaEmListTable';
import DeprecatedWarningModal from '../../UI/DeprecatedWarningModal';
import { fileDownload, getEstadosUrl } from '../../../helpers/helpers';

const moment = require('moment');

function mapDispatchToProps(dispatch) {
  return {
    resetData: policy => dispatch(cleanEstadoDiario(policy)),
    loading: () => dispatch(handleLoading()),
    getEstados: policy => dispatch(getData(policy)),
    estadoChange: policy => dispatch(patchData(policy)),
  }
}

class GarantiaEmList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeEstado: undefined,
      dateFilter: moment(),
      date: moment(),
      reporte: undefined,
      warningOpen: false,
    };
    this.url = `${BASE_URL}${API.estados_mensuales}`;
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeWarning = this.handleChangeWarning.bind(this);
    this.handleDate = this.handleDate.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.handleEstadoChange = this.handleEstadoChange.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
  }

  componentDidMount() {
    const { getEstados, resetData } = this.props;
    resetData();
    getEstados({
      url: this.url,
      type: GET_ESTADO_MENSUAL_LIST,
    });
  }

  componentDidUpdate(prevProps) {
    const { activeEstado } = this.state;
    if(activeEstado) {
      const updatedEstado = prevProps.estadosMensuales.estadoList.find(
        node => node.id === activeEstado.id);
      if(updatedEstado !== activeEstado) {
        this.handleUpdate();
      }
    }
  }

  handleUpdate() {
    this.setState({
      warningOpen: false,
      activeEstado: undefined,
    });
  }

  handleChange(option) {
    this.setState({
      reporte: option
    })
  }

  handleDate(date) {
    const { getEstados } = this.props;
    if (date <= moment()) {
      getEstados({
        url: `${this.url}?year=${date.format('YYYY')}`,
        type: GET_ESTADO_MENSUAL_LIST,
      });
      this.setState({ date })
    }
  }

  handleFilter(dateFilter) {
    this.setState({
      dateFilter,
    })
  }

  handleDownload() {
    const { dateFilter, reporte } = this.state;
    const { loading } = this.props;
    fileDownload(
      getEstadosUrl(false, reporte.value, moment(dateFilter).format('YYYY-MM-DD')),
      loading
    );
    loading();
  }

  handleChangeWarning(e, node) {
    const { warningOpen } = this.state;
    e.preventDefault();
    this.setState({
      warningOpen: !warningOpen,
      activeEstado: !warningOpen ? node : undefined,
    })
  }

  handleEstadoChange(node, onFailedReq = () => (null)) {
    const { estadoChange } = this.props;
    estadoChange({
      url: `${this.url}${node.id}/`,
      type: ESTADO_MENSUAL_CHANGE,
      onFailedReq,
      config: { activo: !(node.activo) }
    });
  }

  render() {
    const {
      activeEstado,
      date,
      dateFilter,
      reporte,
      warningOpen,
    } = this.state;
    const { estadosMensuales } = this.props;
    const reportes = [
      {
        label: 'Reporte Descuento Cesión',
        value: 'reporte_desc_cesion_fecha',
      },
      {
        label: 'Reporte OYNR',
        value: 'reporte_oynr_fecha',
      },
      {
        label: 'Reporte RRC',
        value: 'reporte_rcc_fecha',
      },
      {
        label: 'Voucher Operaciones',
        value: 'voucher_contable_fecha',
      },

    ];
    return(
      <PageContainer
        breadcrumbs={[
          { id: 1, name: 'GARANTÍA', url: '/reservas/garantia/estados_diarios' },
          { id: 2, name: 'CIERRES MENSUALES' }
        ]}
      >
        <EstadoFilter
          date={date}
          dateFilter={dateFilter}
          handleChange={this.handleChange}
          handleDate={this.handleDate}
          handleDownload={this.handleDownload}
          handleFilter={this.handleFilter}
          loading={estadosMensuales.loading}
          reporte={reporte}
          reportes={reportes}
          year
        />
        <EstadoMensualListTable
          activeEstado={activeEstado}
          handleChangeWarning={this.handleChangeWarning}
          modalOpen={warningOpen}
        />
        {activeEstado && (
          <DeprecatedWarningModal
            handleModal={this.handleChangeWarning}
            handleSubmit={this.handleEstadoChange}
            node={activeEstado}
            modalOpen={warningOpen}
            year
          />
        )}
      </PageContainer>
    )
  }
}

GarantiaEmList.propTypes = {
  estadoChange: PropTypes.func.isRequired,
  estadosMensuales: PropTypes.shape({
    estadoList: PropTypes.arrayOf(PropTypes.object),
    loading: PropTypes.bool,
  }).isRequired,
  getEstados: PropTypes.func.isRequired,
  loading: PropTypes.func.isRequired,
  resetData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  estadosMensuales: state.estadosMensuales
});

export default connect(mapStateToProps, mapDispatchToProps)(GarantiaEmList);
