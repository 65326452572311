export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const GET_USER_INFO = "GET_USER_INFO";
export const TOKEN_ERROR = "TOKEN_ERROR";

export const CLEAN_ESTADO_DIARIO = "CLEAN_ESTADO_DIARIO";
export const ESTADO_CHANGE = "ESTADO_CHANGE";
export const GET_ESTADO_LIST = "GET_ESTADO_LIST";

export const GET_ESTADO = "GET_ESTADO";
export const GET_ESTADO_MENSUAL = "GET_ESTADO_MENSUAL";

export const CLEAN_ESTADO_MENSUAL = "CLEAN_ESTADO_MENSUAL";
export const ESTADO_MENSUAL_CHANGE = "ESTADO_MENSUAL_CHANGE";
export const GET_ESTADO_MENSUAL_LIST = "GET_ESTADO_MENSUAL_LIST";

export const CLEAN_ESTADO_TRIMESTRAL = "CLEAN_ESTADO_TRIMESTRAL";
export const ESTADO_TRIMESTRAL_CHANGE = "ESTADO_TRIMESTRAL_CHANGE";
export const GET_ESTADO_TRIMESTRAL = "GET_ESTADO_TRIMESTRAL";
export const GET_ESTADO_TRIMESTRAL_LIST = "GET_ESTADO_TRIMESTRAL_LIST";

export const LOADING_CHANGE = "LOADING_CHANGE";
