import React from "react";
import Numeral from "numeral";
import "numeral/locales/es";


export function formatValue(value, mo) {
  Numeral.locale('es');
  return mo ? Numeral(value).format('0,0.00') : value.toLocaleString('es-cl');
}

export const headerOperation = (instance, align = 'center') => (
  <div className={`text-${align} table-header`}>
    {instance}
  </div>
);

export const headerDoubleRowOperation = (instance1, instance2) => (
  headerOperation((
    <>
      <div>{instance1}</div>
      <div>{instance2}</div>
    </>
  ))
);

export const cellOperation = (instance, align = 'center', wrap = true, centerComponent = false) => (
  <div 
    className={`text-${align} table-cell ${wrap ? 'wrap' : ''}`}
    style={centerComponent ? {display: 'flex', justifyContent: 'center', alignItems: 'center'} : {}}
  >
    {(instance || instance === 0) ? instance : '-'}
  </div>
);

export const cellNumOperation = (instance, mo = true, center = false) => cellOperation(
  instance && formatValue(instance, mo),
  center ? 'center' : 'right',
);

export const cellDobleRowOperation = (row1, row2) => cellOperation((
  <>
    <div>{row1}</div>
    <div>{row2}</div>
  </>
), 'left');
