import React from 'react';
import {
  Modal,
  ModalBody,
} from 'reactstrap';
import PropTypes from 'prop-types';
import '../../styles/modal.css';

const BaseModal =
  ({
     children,
     handleModal,
     modalOpen,
     type,
  }) => (
    <Modal className={type} isOpen={modalOpen} toggle={handleModal} size="lg">
      <ModalBody className={type}>
        {children}
      </ModalBody>
    </Modal>
);

BaseModal.propTypes = {
  children: PropTypes.node.isRequired,
  handleModal: PropTypes.func.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  type: PropTypes.string,
};

BaseModal.defaultProps = {
  type: '',
};

export default BaseModal;
