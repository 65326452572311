import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  cleanEstadoDiario,
  getData,
  handleLoading,
} from '../../redux-store/actions';
import {
  GET_ESTADO_TRIMESTRAL_LIST,
} from '../../redux-store/constants/action-types';
import { API, BASE_URL } from '../../CONST';
import PageContainer from '../UI/PageContainer';
import EstadoFilter from '../EstadoFilter';
import EstadoTrimestralListTable from './EmisionesTrimestralesListTable';
import { fileDownload, getEstadosUrl } from "../../helpers/helpers";

const moment = require('moment');

function mapDispatchToProps(dispatch) {
  return {
    resetData: policy => dispatch(cleanEstadoDiario(policy)),
    loading: () => dispatch(handleLoading()),
    getEstados: policy => dispatch(getData(policy)),
  }
}

const EmisionesTrimestrales = ({
  estadosTrimestrales,
  getEstados,
  resetData,
}) => {
  const [activeEstado, setActiveEstado] = useState(undefined);
  const [date, setDate] = useState(moment());
  const [warningOpen, setWarningOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const url = `${BASE_URL}${API.estados_trimestrales}`;

  const handleUpdate = () => {
    setWarningOpen(false);
    setActiveEstado(undefined);
  };

  useEffect(() => {
    resetData();
    getEstados({url, type: GET_ESTADO_TRIMESTRAL_LIST});
  },[]);

  useEffect(() => {
    if(activeEstado) {
      const updatedEstado = estadosTrimestrales.estadoList.find(
        node => node.id === activeEstado.id);
      if(updatedEstado !== activeEstado) {
        handleUpdate();
      }
    }
  }, [activeEstado]);

  const handleDate = (dateUpdate) => {
    if (dateUpdate <= moment()) {
      getEstados({
        url: `${url}?year=${dateUpdate.format('YYYY')}`,
        type: GET_ESTADO_TRIMESTRAL_LIST,
      });
      setDate(dateUpdate)
    }
  };

  const handleChangeWarning = (e, node) => {
    e.preventDefault();
    setWarningOpen(!warningOpen);
    setActiveEstado(!warningOpen ? node : undefined);
  };

  const handleLoadingStatus = () => {
    setLoading(false);
  }

  const handleDownload = (type) => {
    setLoading(true);
    fileDownload(getEstadosUrl(false, false, type, false), handleLoadingStatus);
  }

  return (
    <PageContainer
      breadcrumbs={[
        { id: 1, name: 'REASEGUROS', url: '/reaseguros/contratos' },
        { id: 2, name: 'REPORTES' }
      ]}
    >
      <EstadoFilter
        date={date}
        handleDate={handleDate}
        handleDownload={handleDownload}
        loading={loading}
        reportUtilities
        quarter
        year
      />
      <EstadoTrimestralListTable
        activeEstado={activeEstado}
        handleChangeWarning={handleChangeWarning}
        modalOpen={warningOpen}
      />
    </PageContainer>
  )
}

EmisionesTrimestrales.propTypes = {
  estadosTrimestrales: PropTypes.shape({
    estadoList: PropTypes.arrayOf(PropTypes.object),
    loading: PropTypes.bool,
  }).isRequired,
  getEstados: PropTypes.func.isRequired,
  resetData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  estadosTrimestrales: state.estadosTrimestrales
});

export default connect(mapStateToProps, mapDispatchToProps)(EmisionesTrimestrales);
