import React from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import PageContainer from 'components/UI/PageContainer';
import { BASE_URL, API } from 'CONST';
import EmisionesDiariasResumen from '../EmisionesDiariasResumen';
import EmisionesDiariasMovimientos from '../EmisionesDiariasMovimientos';

const EmisionesDiariasGarantiaDetail = () => {
  const { date } = useParams();

  return (
    <PageContainer
      breadcrumbs={[
        { id: 0, name: 'GARANTÍA', url: '/reservas/garantia/estados_diarios' },
        { id: 1, name: 'EMISIONES DIARIAS', url: '/reaseguros/garantia/emisiones_diarias' },
        { id: 2, name: `CIERRE ${moment(date).format('DD-MM-YYYY')}` },
      ]}
    >
      <EmisionesDiariasResumen endpoint={`${BASE_URL}${API.cierre_diario}`} />
      <EmisionesDiariasMovimientos garantia endpoint={`${BASE_URL}${API.cierre_diario}`} />
    </PageContainer>
  );
};

export default EmisionesDiariasGarantiaDetail;
