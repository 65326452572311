import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';

import EstadoList from '../components/ediarios/garantia/EstadoList';
import EstadoDetail from '../components/ediarios/garantia/EstadoDetail';
import GarantiaEstadoMList from '../components/emensuales/garantia/GarantiaEmList';
import CreditoEstadoMList from '../components/emensuales/credito/CreditoEmList';
import EmisionesTrimestrales from '../components/emisionesTrimestrales/EmisionesTrimestralesList';
import EstadoMDetail from '../components/emensuales/garantia/EstadoMDetail';
import AppRedirect from '../components/shared/AppRedirect';
import CierresOperacionales from '../components/ediarios/garantia/CierresOperacionales';
import CierreOperacionalDetail from '../components/ediarios/garantia/CierreOperacionalDetail';
import Contratos from '../components/reaseguros/contratos/Contratos';
import ContratosCreate from '../components/reaseguros/contratos/ContratosCreate';
import ContratosEdit from '../components/reaseguros/contratos/ContratosEdit';
import EmisionesDiariasGarantia from '../components/emisionesDiarias/garantia/EmisionesDiariasGarantia';
import EmisionesDiariasGarantiaDetail from '../components/emisionesDiarias/garantia/EmisionesDiariasGarantiaDetail';
import EmisionesDiariasCredito from '../components/emisionesDiarias/credito/EmisionesDiariasCredito';
import EmisionesDiariasCreditoDetail from '../components/emisionesDiarias/credito/EmisionesDiariasCreditoDetail';
import isUserHasProfile from '../helpers/profileValidator';
import {
  CIERRE_DIARIO_READ,
  CIERRE_MENSUAL_READ,
  CIERRE_OPERACIONAL_READ,
  REASEGURO_READ,
  REASEGURO_WRITE,
} from '../helpers/profilePermission';

const LoggedInRoutes = ({ user }) => {
  let homeComponent = CreditoEstadoMList;

  if (isUserHasProfile(CIERRE_DIARIO_READ)) {
    homeComponent = EstadoList;
  } else if (isUserHasProfile(CIERRE_OPERACIONAL_READ)) {
    homeComponent = GarantiaEstadoMList;
  }

  return !user.has_access ? (
    <AppRedirect />
  ) : (
    <Switch>
      <Route exact path="/" component={homeComponent} />
      <Route exact path="/reservas" component={homeComponent} />

      {isUserHasProfile(CIERRE_DIARIO_READ) && (
        <Route exact path="/reservas/garantia/estados_diarios" component={EstadoList} />
      )}
      {isUserHasProfile(CIERRE_DIARIO_READ) && (
        <Route path="/reservas/garantia/estados_diarios/:id/:date" component={EstadoDetail} />
      )}
      {isUserHasProfile(CIERRE_MENSUAL_READ) && (
        <Route exact path="/reservas/garantia/estados_mensuales" component={GarantiaEstadoMList} />
      )}
      {isUserHasProfile(CIERRE_MENSUAL_READ) && (
        <Route
          exact
          path="/reservas/garantia/estados_mensuales/:id/:date"
          component={EstadoMDetail}
        />
      )}
      <Route exact path="/reservas/credito/estados_mensuales" component={CreditoEstadoMList} />
      <Route exact path="/reaseguros/credito/emisiones_diarias" component={EmisionesDiariasCredito} />
      <Route exact path="/reaseguros/reportes" component={EmisionesTrimestrales} />
      <Route
        exact
        path="/reaseguros/credito/emisiones_diarias/editar/:id/:date"
        component={EmisionesDiariasCreditoDetail}
      />
      {isUserHasProfile(CIERRE_OPERACIONAL_READ) && (
        <Route
          exact
          path="/reservas/garantia/cierre_operacional"
          component={CierresOperacionales}
        />
      )}
      {isUserHasProfile(CIERRE_OPERACIONAL_READ) && (
        <Route
          exact
          path="/reservas/garantia/cierre_operacional/:id/:date"
          component={CierreOperacionalDetail}
        />
      )}
      <Route
        exact
        path="/reaseguros/garantia/emisiones_diarias"
        component={EmisionesDiariasGarantia}
      />
      <Route
        exact
        path="/reaseguros/garantia/emisiones_diarias/editar/:id/:date"
        component={EmisionesDiariasGarantiaDetail}
      />
      {isUserHasProfile(REASEGURO_READ) && (
        <Route exact path="/reaseguros" component={Contratos} />
      )}
      {isUserHasProfile(REASEGURO_READ) && (
        <Route exact path="/reaseguros/contratos" component={Contratos} />
      )}
      {isUserHasProfile(REASEGURO_WRITE) && (
        <Route exact path="/reaseguros/contratos/crear" component={ContratosCreate} />
      )}
      {isUserHasProfile(REASEGURO_READ) && (
        <Route exact path="/reaseguros/contratos/editar/:id" component={ContratosEdit} />
      )}
      <Route render={() => <Redirect to="/" />} />
    </Switch>
  );
};

LoggedInRoutes.propTypes = {
  user: PropTypes.shape({
    has_access: PropTypes.bool,
  }).isRequired,
};

const mapStateToProps = (state) => {
  return {
    userToken: state.login.userToken,
    user: state.login.user,
  };
};

export default withRouter(connect(mapStateToProps)(LoggedInRoutes));
