import React from 'react';
import PropTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';
import Acciones from '../../UI/Acciones';
import Estado from '../../UI/Estado';
import DeprecatedBaseTable from '../../UI/DeprecatedBaseTable';
import {
  cellNumOperation,
  cellOperation,
  headerOperation
} from '../../../helpers/tables';

const moment = require('moment');

function formatPeriod(date) {
  return moment(date).format('MMM-YYYY').replace(/\./g, '');
}

const GarantiaEmListTable = ({
  activeEstado,
  estadosMensuales,
  handleChangeWarning
}) => {
  const columns = [
    {
      Header: headerOperation('Periodo'),
      headerClassName: 'fixed',
      id: 'periodo',
      accessor: i => cellOperation(formatPeriod(i.periodo)),
      style: {
        borderRight: '1px solid #CCCCCC'
      },
      minWidth: 90,
      sortable: false,
    },
    {
      Header: headerOperation('MO'),
      id: 'moneda',
      accessor: i => cellOperation(i.moneda),
      minWidth: 60,
      sortable: false
    },
    {
      Header: headerOperation('Movimientos'),
      id: 'movimientos',
      accessor: i => cellNumOperation(i.movimientos),
      minWidth: 100,
      sortable: false

    },
    {
      Header: headerOperation('RRC'),
      id: 'rrc_mo_amt',
      accessor: i => cellNumOperation(i.rrc_mo_amt),
      minWidth: 100,
      sortable: false
    },
    {
      Header: headerOperation('RRC Cedida'),
      id: 'rrc_cedida_mo_amt',
      accessor: i => cellNumOperation(i.rrc_cedida_mo_amt),
      minWidth: 100,
      sortable: false
    },
    {
      Header: headerOperation('OYNR'),
      id: 'oynr_mo_amt',
      accessor: i => cellNumOperation(i.oynr_mo_amt),
      minWidth: 100,
      sortable: false
    },
    {
      Header: headerOperation('OYNR Cedida'),
      id: 'oynr_cedida_mo_amt',
      accessor: i => cellNumOperation(i.oynr_cedida_mo_amt),
      minWidth: 100,
      sortable: false
    },
    {
      Header: headerOperation('Pasivo Descuento Cesión'),
      id: 'descuento_cesion_mo_amt',
      accessor: i => cellNumOperation(i.pasivo_descuento_cesion_mo_amt),
      minWidth: 160,
      sortable: false
    },
    {
      Header: headerOperation('Estado'),
      id: 'estado',
      accessor: i => cellOperation(
        <Estado estado={i.estado} />
      ),
      minWidth: 130,
      sortable: false
    },
    {
      Header: headerOperation('Acciones'),
      id: 'acciones',
      accessor: (i => (i.estado !== 'Pendiente'
          ? (
            <Acciones
              handleChangeWarning={handleChangeWarning}
              node={i}
              warning={i === activeEstado}
            />
            ) : cellOperation()
        )
      ),
      width: 220,
      sortable: false
    },
  ];
  return (
    <div className="table-container">
      <DeprecatedBaseTable
        className="eMensual-list"
        columns={columns}
        data={estadosMensuales.estadoList}
        resizable={false}
        style={{ height: "50vh" }}
      />
    </div>
  )
};

const mapStateToProps = state => {
  return {
    estadosMensuales: state.estadosMensuales,
  }
};

const GarantiaEmListTableView = connect(mapStateToProps)(GarantiaEmListTable);

GarantiaEmListTable.propTypes = {
  activeEstado: PropTypes.node,
  estadosMensuales: PropTypes.shape({
    estadoList: PropTypes.arrayOf(PropTypes.object)
  }).isRequired,
  handleChangeWarning: PropTypes.func.isRequired,
};

GarantiaEmListTable.defaultProps = {
  activeEstado: undefined,
};

export default GarantiaEmListTableView;
