import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import moment from 'moment';

import DeprecatedBaseTable from '../../UI/DeprecatedBaseTable';
import {
  cellNumOperation,
  cellDobleRowOperation,
  cellOperation,
  headerOperation,
} from '../../../helpers/tables';
import SafeBtn from '../../shared/SafeBtn';
import { GET_ESTADO } from '../../../redux-store/constants/action-types';
import { postData } from '../../../redux-store/actions';
import { API, BASE_URL } from '../../../CONST';
import isUserHasProfile from '../../../helpers/profileValidator';
import {
  CIERRE_OPERACIONAL_WRITE,
  CIERRE_OPERACIONAL_CEO,
} from '../../../helpers/profilePermission';

const checkAllState = {
  unchecked: 0,
  checked: 1,
  indeterminate: 2,
};

class CierreOpMovimientosTable extends Component {
  static getDerivedStateFromProps(props, state) {
    const { movsToAccount } = state;
    const { movimientos } = props;
    let updatedState = {};

    if (Object.keys(movsToAccount).length === 0 && movimientos.length > 0) {
      updatedState = CierreOpMovimientosTable.updateChecks(movimientos);
    }

    return Object.keys(updatedState).length > 0 ? updatedState : null;
  }

  static updateChecks = (movimientos) => {
    const updatedState = {};

    updatedState.movsToAccount = movimientos.reduce(
      (acum, mov) => ({
        ...acum,
        [mov.id]: {
          enabled: !mov.contabilizado,
          selected: mov.contabilizado,
        },
      }),
      {},
    );
    updatedState.movsToBill = movimientos.reduce(
      (acum, mov) => ({
        ...acum,
        [mov.id]: {
          enabled: mov.facturado ? false : updatedState.movsToAccount[mov.id].selected,
          selected: mov.facturado,
        },
      }),
      {},
    );

    return updatedState;
  };

  constructor(props) {
    super(props);
    this.state = {
      movsToAccount: {},
      accountCheckAllState: checkAllState.unchecked,
      movsToBill: {},
      billCheckAllState: checkAllState.unchecked,
      loading: false,
    };
  }

  enabledAndSelectedMovs = (movsDict) =>
    Object.keys(movsDict)
      .map((movId) => ({ movId, ...movsDict[movId] }))
      .filter((mov) => mov.enabled && mov.selected);

  filterMovs = (movsDict, field) =>
    Object.keys(movsDict)
      .map((movId) => ({ movId, ...movsDict[movId] }))
      .filter((mov) => mov[field]);

  allSelected = (movsDict) =>
    this.enabledAndSelectedMovs(movsDict).length === this.filterMovs(movsDict, 'enabled').length;

  anySelected = (movsDict) => this.enabledAndSelectedMovs(movsDict).length > 0;

  noneSelected = (movsDict) => this.enabledAndSelectedMovs(movsDict).length === 0;

  getCheckAllState = (selected) => {
    let checkAllMovsState = checkAllState.indeterminate;
    if (this.noneSelected(selected)) {
      checkAllMovsState = checkAllState.unchecked;
    } else if (this.allSelected(selected)) {
      checkAllMovsState = checkAllState.checked;
    }
    return checkAllMovsState;
  };

  toggleMovToAccountCheck = (movId) => {
    this.toggleMovCheck('movsToAccount', 'accountCheckAllState', movId);

    const { movsToBill } = this.state;
    const updatedMovsToBill = {
      ...movsToBill,
      [movId]: {
        ...movsToBill[movId],
        enabled: !movsToBill[movId].enabled,
        selected: false,
      },
    };
    this.setState({
      movsToBill: updatedMovsToBill,
      billCheckAllState: this.getCheckAllState(updatedMovsToBill),
    });
  };

  toggleMovCheck = (checksField, checkAllField, movId) => {
    const { [checksField]: selectedMovs } = this.state;
    const newSelected = {
      ...selectedMovs,
      [movId]: {
        ...selectedMovs[movId],
        selected: !selectedMovs[movId].selected,
      },
    };

    this.setState({
      [checksField]: newSelected,
      [checkAllField]: this.getCheckAllState(newSelected),
    });
  };

  toggleAccountCheckAllState = () => {
    const { movsToBill, movsToAccount } = this.state;
    const mainCheckAllState = this.toggleCheckAll('movsToAccount', 'accountCheckAllState');
    const updatedMovsToBill = { ...movsToBill };

    Object.keys(updatedMovsToBill).forEach((movId) => {
      if (movsToAccount[movId].enabled) {
        updatedMovsToBill[movId] = {
          ...updatedMovsToBill[movId],
          enabled: mainCheckAllState,
        };
      }
      if (!mainCheckAllState) {
        updatedMovsToBill[movId].selected = false;
      }
    });

    this.setState({
      movsToBill: updatedMovsToBill,
      billCheckAllState: this.getCheckAllState(updatedMovsToBill),
    });
  };

  toggleCheckAll = (checksField, checkAllField) => {
    const { [checksField]: selected, [checkAllField]: checkAllMovsState } = this.state;
    const newSelected = { ...selected };

    const selectedState = checkAllMovsState === checkAllState.unchecked;
    Object.keys(newSelected).forEach((movId) => {
      if (newSelected[movId].enabled) {
        newSelected[movId] = {
          ...newSelected[movId],
          selected: selectedState,
        };
      }
    });

    this.setState((prevState) => ({
      [checksField]: newSelected,
      [checkAllField]:
        prevState[checkAllField] === checkAllState.unchecked
          ? checkAllState.checked
          : checkAllState.unchecked,
    }));

    return selectedState;
  };

  sendMovementsToFolder = () => {
    const { cierreDiarioId, dispatch } = this.props;
    const { movsToAccount, movsToBill } = this.state;

    const idsToAccount = this.enabledAndSelectedMovs(movsToAccount).map((mov) => mov.movId);
    const idsToBill = this.enabledAndSelectedMovs(movsToBill).map((mov) => mov.movId);

    this.setState({ loading: true });

    dispatch(
      postData({
        type: GET_ESTADO,
        url: `${BASE_URL}${API.cierre_diario}${cierreDiarioId}/${API.send_movs_diarios_to_folder}`,
        config: {
          idsToAccount,
          idsToBill,
        },
      }),
    ).then(() => {
      const { movimientos } = this.props;
      const updatedState = CierreOpMovimientosTable.updateChecks(movimientos);
      if (Object.keys(updatedState).length > 0) {
        this.setState({
          ...updatedState,
          loading: false,
        });
      } else {
        this.setState({ loading: false });
      }
    });
  };

  canEditDay = () => {
    const { fechaCierreOp } = this.props;
    const canEdit = isUserHasProfile(CIERRE_OPERACIONAL_WRITE);

    return moment(fechaCierreOp).isBefore(moment(), 'days')
      ? canEdit && isUserHasProfile(CIERRE_OPERACIONAL_CEO)
      : canEdit;
  };

  render() {
    const { movimientos } = this.props;
    const {
      movsToAccount,
      accountCheckAllState,
      movsToBill,
      billCheckAllState,
      loading,
    } = this.state;
    const canEdit = this.canEditDay();

    if (movimientos.length === 0) {
      return null;
    }

    const columns = [
      {
        Header: '',
        fixed: 'left',
        headerClassName: 'principal',
        columns: [
          {
            id: 'contabilizar',
            accessor: '',
            Cell: ({ original }) => {
              return (
                <div className="table-cell" style={{ textAlign: 'center' }}>
                  <input
                    type="checkbox"
                    className="checkbox"
                    checked={movsToAccount[original.id].selected}
                    disabled={!movsToAccount[original.id].enabled || !canEdit}
                    onChange={() => this.toggleMovToAccountCheck(original.id)}
                  />
                </div>
              );
            },
            Header: () => (
              <div className="table-header">
                <div style={{ marginBottom: '5px' }}>Contabilizar</div>
                {canEdit && (
                  <input
                    type="checkbox"
                    className="checkbox"
                    checked={accountCheckAllState === checkAllState.checked}
                    ref={(input) => {
                      if (input) {
                        // eslint-disable-next-line no-param-reassign
                        input.indeterminate = accountCheckAllState === checkAllState.indeterminate;
                      }
                    }}
                    onChange={() => this.toggleAccountCheckAllState()}
                  />
                )}
              </div>
            ),
            sortable: false,
            width: 100,
          },
          {
            id: 'facturar',
            accessor: '',
            Cell: ({ original }) => {
              return (
                <div className="table-cell" style={{ textAlign: 'center' }}>
                  <input
                    type="checkbox"
                    className="checkbox"
                    checked={movsToBill[original.id].selected}
                    disabled={!movsToBill[original.id].enabled || !canEdit}
                    onChange={() =>
                      this.toggleMovCheck('movsToBill', 'billCheckAllState', original.id)
                    }
                  />
                </div>
              );
            },
            Header: () => (
              <div className="table-header">
                <div style={{ marginBottom: '5px' }}>Facturar</div>
                {canEdit && (
                  <input
                    type="checkbox"
                    className="checkbox"
                    checked={billCheckAllState === checkAllState.checked}
                    ref={(input) => {
                      if (input) {
                        // eslint-disable-next-line no-param-reassign
                        input.indeterminate = billCheckAllState === checkAllState.indeterminate;
                      }
                    }}
                    onChange={() => this.toggleCheckAll('movsToBill', 'billCheckAllState')}
                  />
                )}
              </div>
            ),
            sortable: false,
            width: 80,
          },
          {
            Header: headerOperation('Emisión'),
            id: 'emision',
            accessor: (i) => cellDobleRowOperation(i.fecha_emision, i.emisor_username),
            minWidth: 120,
            sortable: false,
          },
          {
            Header: headerOperation('Póliza'),
            id: 'poliza',
            accessor: (i) => cellOperation(i.poliza),
            minWidth: 120,
            sortable: false,
          },
          {
            Header: headerOperation('Tipo Endoso'),
            id: 'tipo_endoso',
            accessor: (i) => cellOperation(i.tipo_endoso),
            minWidth: 120,
            sortable: false,
          },
        ],
      },
      {
        Header: '',
        headerClassName: 'principal',
        columns: [
          {
            Header: headerOperation('Contratante'),
            id: 'contratante',
            accessor: (i) => cellDobleRowOperation(i.contratante_rut, i.contratante_nombre),
            minWidth: 240,
            sortable: false,
          },
          {
            Header: headerOperation('Comuna Contratante'),
            id: 'contratante_comuna',
            accessor: (i) => cellOperation(i.contratante_comuna),
            minWidth: 175,
            sortable: false,
          },
          {
            Header: headerOperation('Dirección Contratante'),
            id: 'contratante_direccion',
            accessor: (i) => cellOperation(i.contratante_direccion),
            minWidth: 175,
            sortable: false,
          },
          {
            Header: headerOperation('Inicio Vigencia'),
            id: 'inicio_vigencia',
            accessor: (i) => cellOperation(i.inicio_vigencia),
            minWidth: 120,
            sortable: false,
          },
          {
            Header: headerOperation('Fin Vigencia'),
            id: 'fin_vigencia',
            accessor: (i) => cellOperation(i.fin_vigencia),
            minWidth: 120,
            sortable: false,
          },
          {
            Header: headerOperation('Moneda'),
            id: 'moneda',
            accessor: (i) => cellOperation(i.moneda),
            minWidth: 80,
            sortable: false,
          },
          {
            Header: headerOperation('Capital Asegurado'),
            id: 'capital_asegurado',
            accessor: (i) => cellNumOperation(i.capital_asegurado),
            minWidth: 175,
            sortable: false,
          },
          {
            Header: headerOperation('Prima Neta Afecta'),
            id: 'prima_neta_afecta',
            accessor: (i) => cellNumOperation(i.prima_neta_afecta),
            minWidth: 175,
            sortable: false,
          },
          {
            Header: headerOperation('Prima Exenta Neta'),
            id: 'prima_exenta_neta',
            accessor: (i) => cellNumOperation(i.prima_exenta_neta),
            minWidth: 175,
            sortable: false,
          },
          {
            Header: headerOperation('IVA'),
            id: 'iva',
            accessor: (i) => cellNumOperation(i.iva),
            minWidth: 80,
            sortable: false,
          },
          {
            Header: headerOperation('Prima Neta Afecta Cedida'),
            id: 'prima_neta_afecta_cedida',
            accessor: (i) => cellNumOperation(i.prima_neta_afecta_cedida),
            minWidth: 175,
            sortable: false,
          },
          {
            Header: headerOperation('Descuento Cesión'),
            id: 'descuento_cesion',
            accessor: (i) => cellNumOperation(i.descuento_cesion),
            minWidth: 175,
            sortable: false,
          },
          {
            Header: headerOperation('Impuesto Cesión'),
            id: 'impuesto_cesion',
            accessor: (i) => cellNumOperation(i.impuesto_cesion),
            minWidth: 175,
            sortable: false,
          },
          {
            Header: headerOperation('Tipo Intermediario'),
            id: 'tipo_intermediario',
            accessor: (i) => cellOperation(i.tipo_intermediario),
            minWidth: 175,
            sortable: false,
          },
          {
            Header: headerOperation('Comisión'),
            id: 'comision',
            accessor: (i) => cellNumOperation(i.comision),
            minWidth: 175,
            sortable: false,
          },
          {
            Header: headerOperation('Intermediario'),
            id: 'intermediario',
            accessor: (i) => cellDobleRowOperation(i.intermediario_rut, i.intermediario_nombre),
            minWidth: 240,
            sortable: false,
          },
        ],
      },
    ];
    return (
      <>
        <Row>
          <Col className="table-container">
            <DeprecatedBaseTable
              className="fixed-table movimientos-table"
              fixed
              columns={columns}
              data={movimientos}
              resizable={false}
            />
          </Col>
        </Row>
        {canEdit && (
          <Row>
            <Col>
              <SafeBtn
                color="orsan"
                disabled={!this.anySelected(movsToAccount) && !this.anySelected(movsToBill)}
                loading={loading}
                onClick={() => this.sendMovementsToFolder()}
                style={{ width: '120px' }}
              >
                Enviar a Folder
              </SafeBtn>
            </Col>
          </Row>
        )}
      </>
    );
  }
}

CierreOpMovimientosTable.propTypes = {
  dispatch: PropTypes.func.isRequired,
  cierreDiarioId: PropTypes.string.isRequired,
  fechaCierreOp: PropTypes.string.isRequired,
  movimientos: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = (state) => ({
  movimientos: state.estados.movimientosDiarios.filter((mov) => mov.operacional),
});

export default connect(mapStateToProps)(CierreOpMovimientosTable);
