import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col } from 'reactstrap';
import DeprecatedBaseTable from '../../UI/DeprecatedBaseTable';
import {
  cellNumOperation,
  cellOperation,
  headerOperation,
} from '../../../helpers/tables';

const MovimientoMTable = ({ estados }) => {
    const columns = [
      {
        Header: headerOperation('Código Cuenta'),
        id: 'codigo',
        accessor: i => cellOperation(i.codigo),
        width: 200,
        sortable: false
      },
      {
        Header: headerOperation('Cuenta Contable'),
        id: 'cuenta_contable',
        accessor: i => cellOperation(i.cuenta_contable),
        width: 500,
        sortable: false
      },
      {Header: headerOperation('Moneda Origen'),
        id: 'moneda_origen',
        accessor: i => cellNumOperation(i.moneda_origen),
        width: 250,
        sortable: false
      },
      {
        Header: headerOperation('CLP$'),
        id: 'pesos',
        accessor: i => cellNumOperation(i.pesos),
        minWidth: 80,
        sortable: false
      },
    ];
    return (
      <Col className="table-container">
        <DeprecatedBaseTable
          className="detalle-mensual"
          columns={columns}
          data={estados}
          resizable={false}
        />
      </Col>
    )
};

const mapStateToProps = state => {
  return {
    estados: state.estadosMensuales.cuentas,
  }
};

MovimientoMTable.propTypes = {
  estados: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default connect(mapStateToProps)(MovimientoMTable);
