import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import Datetime from 'react-datetime';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

const EmisionesDiariasGarantiaDateFilter = ({ date, handleDate, year }) => (
  <Row className="filter-container">
    <Col md={2} className="estado-filter">
      <Datetime
        closeOnSelect
        dateFormat={year ? 'YYYY' : 'YYYY-MM'}
        id="estado-filter"
        locale="es"
        onChange={handleDate}
        renderInput={(props) => (
          <div className="datetime-wrapper">
            <input {...props} className="rs-input" />
            <FontAwesomeIcon
              icon={faCaretDown}
              style={{ position: 'absolute', margin: '10px -20px' }}
            />
          </div>
        )}
        timeFormat={false}
        value={moment(date).format(year ? 'YYYY' : 'MMMM-YYYY')}
      />
    </Col>
  </Row>
);

EmisionesDiariasGarantiaDateFilter.propTypes = {
  date: PropTypes.isRequired,
  handleDate: PropTypes.func.isRequired,
  year: PropTypes.bool.isRequired,
};

export default EmisionesDiariasGarantiaDateFilter;
