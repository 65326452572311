import React from 'react';
import PageContainer from 'components/UI/PageContainer';
import ContratosForm from './ContratosForm';


const ContratoCreate = () => {
  return (
    <PageContainer
      breadcrumbs={[
        { id: 0, name: 'CONTRATOS', url: '/reaseguros/contratos' },
        { id: 1, name: 'CREAR CONTRATOS' },
      ]}
    >
      <ContratosForm />
    </PageContainer>
  );
};

export default ContratoCreate;
