import React from 'react';
import ReactTable from 'react-table';
import withFixedColumns from 'react-table-hoc-fixed-columns';
import 'react-table/react-table.css';
import 'react-table-hoc-fixed-columns/lib/styles.css';


const ReactTableWithFixedColumns = withFixedColumns(ReactTable);

const shouldShowPagination = (showPagination, resultCount, pageSize) => {
  if (showPagination !== undefined ? showPagination : true) {
    return resultCount > pageSize;
  }
  return false;
};

const BaseTable = ({
  tableRef,
  data,
  loading,
  pages,
  resultCount,
  filtered,
  filterable,
  filteredArgs,
  columns,
  defaultFilterMethod,
  sortable,
  defaultSorted,
  defaultSortDesc,
  onFetchData,
  showPagination,
  onFilteredChange,
  pageSize,
  resizable,
  hasFixedColumns,
  noBorders,
  getTrProps,
  xScroll,
  style,
}) => {

  let ReactTableComponent = ReactTable;
  if (hasFixedColumns) {
    ReactTableComponent = ReactTableWithFixedColumns;
  }

  const theadThProps = {
    style: {
      background: '#EFEFEF',
      padding: ' 12px'
    }
  };
  let theadProps = {};
  let tableProps = {};
  if (xScroll) {
    tableProps = {
      style: {
        overflowX: 'scroll'
      }
    }
  }
  if (noBorders) {
    theadThProps.style.border = 'none';
    theadProps = {
      style: { background: '#EFEFEF' }
    }
  }

  return (
    <ReactTableComponent
      manual
      className="-highlight"
      loadingText="Cargando..."
      pageSize={pageSize}
      minRows={1}
      pageText="Página"
      previousText="Anterior"
      nextText="Siguiente"
      noDataText="Sin resultados"
      rowsText="Filas"
      multiSort={false}
      showPageSizeOptions={false}
      showPagination={shouldShowPagination(showPagination, resultCount, pageSize)}
      ref={tableRef}
      resizable={resizable}
      data={data}
      loading={loading}
      pages={pages}
      filterable={filterable}
      filtered={filtered}
      filteredArgs={filteredArgs}
      columns={columns}
      defaultFilterMethod={defaultFilterMethod}
      sortable={sortable}
      defaultSorted={defaultSorted}
      defaultSortDesc={defaultSortDesc || false}
      onFetchData={onFetchData}
      onFilteredChange={onFilteredChange}
      getTheadProps={() => (theadProps)}
      getTheadThProps={() => (theadThProps)}
      getTrGroupProps={() => ({
        style: { borderBottom: '1px solid #CCCCCC' } }
      )}
      getTdProps={() => {
        const tdPropsStyle = {};
        if (noBorders) {
          tdPropsStyle.border = 'none';
        }
        return { style: tdPropsStyle };
      }}
      getTrProps={getTrProps}
      getTableProps={() => (tableProps)}
      style={style}
    />
  );
};

BaseTable.propTypes = [];

BaseTable.defaultProps = {
  pageSize: 10,
  sortable: true,
  xScroll: true,
};

export default BaseTable;
