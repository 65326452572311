import React, { useState, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import { Button, Col, FormGroup, Label, FormFeedback } from 'reactstrap';
import Select from 'react-select';
import { API, BASE_URL } from 'CONST';
import FormSection from 'components/UI/FormSection';
import { get } from 'helpers/requests';
import { objectsToOptions } from 'helpers/selects';
import SafeBtn from 'components/shared/SafeBtn';
import isUserHasProfile from '../../../helpers/profileValidator';
import { REASEGURO_WRITE } from '../../../helpers/profilePermission';

const ReaseguradorForm = ({
  rol,
  reaseguradorContrato,
  setReaseguradorContratoProperty,
  handleSaveReaseguradorContrato,
  clearReasegurador,
  editable,
}) => {
  const [optionsReaseguradores, setOptionsReaseguradores] = useState([]);
  const { reasegurador, impuesto, participacion, comision } = reaseguradorContrato;

  useEffect(() => {
    if (rol !== '') {
      get(`${BASE_URL}${API.clientes}?roles=${rol}`).then((response) =>
        setOptionsReaseguradores(objectsToOptions(response, false, false, 'nombre')),
      );
    }
  }, [rol]);

  const isDisabled = () => {      
    return (editable
    ? reasegurador.value === '' ||
      comision === '' ||
      participacion === '' ||
      impuesto === '' ||
      impuesto < 0 ||
      comision < 0 ||
      participacion < 0 ||
      participacion > 100
    : !reasegurador.value ||
      comision === '' ||
      participacion === '' ||
      impuesto === '' ||
      comision < 0 ||
      impuesto < 0 ||
      participacion < 0 ||
      participacion > 100)
  };

  // Constantes para validación de perfil
  const disabledByProfile = !isUserHasProfile(REASEGURO_WRITE);

  return (
    <div>
      {disabledByProfile ? (
        <Col md={{ offset: 2, size: 8 }}>
          <FormSection title="Reaseguradores" />
        </Col>
      ) : (
        <Col md={{ offset: 2, size: 8 }}>
          <FormSection title="Reaseguradores">
            <div
              style={{
                display: 'grid',
                gridGap: '20px 40px',
                gridTemplateColumns: '2fr 1fr 1fr 1fr',
                marginTop: '15px',
                padding: '15px 0',
              }}
            >
              <FormGroup>
                <Label for="Reasegurador">
                  Reasegurador
                  <span className="required text-danger">*</span>
                </Label>
                <Select
                  id="reasegurador"
                  name="reasegurador"
                  className="custom-policy-select"
                  options={optionsReaseguradores}
                  onChange={(event) => setReaseguradorContratoProperty('reasegurador', event)}
                  value={reasegurador}
                  placeholder="Seleccione Reasegurador"
                />
              </FormGroup>
              <FormGroup>
                <Label for="participacion">
                  % Participación
                  <span className="required text-danger">*</span>
                </Label>
                <NumberFormat 
                  className="form-control"
                  name="participacion"
                  id="participacion"
                  value={participacion}
                  onValueChange={({floatValue}) => setReaseguradorContratoProperty('participacion', floatValue)}
                  placeholder="Ingrese % Participación"
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale="2"
                  suffix="%"
                  fixedDecimalScale
                  allowNegative={false}
                  isAllowed={({floatValue}) => parseInt(floatValue, 10) <= 100}
                  renderText={(value) => <p className="small-form-value">{value}</p>}
                />
                <FormFeedback style={{ fontSize: '12px', margin: '0 0 5px 5px' }}>
                  Ingrese un % Participación válido.
                </FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="impuesto">
                  % Impuesto
                  <span className="required text-danger">*</span>
                </Label>
                <NumberFormat 
                  className="form-control"
                  name="impuesto"
                  id="impuesto"
                  value={impuesto}
                  onValueChange={({floatValue}) => setReaseguradorContratoProperty('impuesto', floatValue)}
                  placeholder="Ingrese % Impuesto"
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale="2"
                  suffix="%"
                  fixedDecimalScale
                  allowNegative={false}
                  isAllowed={({floatValue}) => parseInt(floatValue, 10) <= 100}
                  renderText={(value) => <p className="small-form-value">{value}</p>}
                />
                <FormFeedback style={{ fontSize: '12px', margin: '0 0 5px 5px' }}>
                  Ingrese un % Impuesto válido.
                </FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="comision">
                  % Comisión
                  <span className="required text-danger">*</span>
                </Label>
                <NumberFormat 
                  className="form-control"
                  name="comision"
                  id="comision"
                  value={comision}
                  onValueChange={({floatValue}) => setReaseguradorContratoProperty('comision', floatValue)}
                  placeholder="Ingrese % Comisión"
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale="2"
                  suffix="%"
                  fixedDecimalScale
                  allowNegative={false}
                  isAllowed={({floatValue}) => parseInt(floatValue, 10) <= 100}
                  renderText={(value) => <p className="small-form-value">{value}</p>}
                />
                <FormFeedback style={{ fontSize: '12px', margin: '0 0 5px 5px' }}>
                  Ingrese un % Comisión válido.
                </FormFeedback>
              </FormGroup>
            </div>
          </FormSection>
          <div className="mb-3 d-flex justify-content-end">
            <Button
              style={{
                background: 'transparent',
                color: '#315e90',
                border: '1px solid #315e90',
              }}
              disabled={false}
              onClick={clearReasegurador}
              className="ml-sm-2 btn btn-outline-orsan-primary"
            >
              Limpiar
            </Button>
            <SafeBtn
              className="ml-sm-2 btn btn-orsan-secondary"
              style={{
                background: '#315e90',
                color: '#FFFFFF',
                border: '1px solid #315e90',
              }}
              disabled={isDisabled()}
              onClick={handleSaveReaseguradorContrato}
            >
              Agregar
            </SafeBtn>
          </div>
        </Col>
      )}
    </div>
  );
};

ReaseguradorForm.propTypes = {
  rol: PropTypes.string.isRequired,
  reaseguradorContrato: PropTypes.shape({
    reasegurador: PropTypes.object,
    participacion: PropTypes.string,
    impuesto: PropTypes.string,
    comision: PropTypes.string,
  }).isRequired,
  setReaseguradorContratoProperty: PropTypes.func.isRequired,
  handleSaveReaseguradorContrato: PropTypes.func.isRequired,
  clearReasegurador: PropTypes.func.isRequired,
  editable: PropTypes.bool.isRequired,
};

export default ReaseguradorForm;
