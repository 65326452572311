import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import login from './login';
import estados from './estados';
import estadosMensuales from './estadosMensuales';
import estadosTrimestrales from './estadosTrimestrales';

const rootReducer = combineReducers({
  login,
  estados,
  estadosMensuales,
  estadosTrimestrales,
  routing: routerReducer,
});

export default rootReducer;
