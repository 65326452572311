import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { get } from 'helpers/requests';
import {
  cleanEstadoDiario,
  getData,
  handleLoading,
  patchData,
} from '../../../redux-store/actions';
import {
  ESTADO_MENSUAL_CHANGE,
  GET_ESTADO_MENSUAL_LIST,
} from '../../../redux-store/constants/action-types';
import { API, BASE_URL, ESTADO_DESCARGA } from '../../../CONST';
import PageContainer from '../../UI/PageContainer';
import EstadoFilter from '../../EstadoFilter';
import DeprecatedWarningModal from '../../UI/DeprecatedWarningModal';
import { fileDownload } from '../../../helpers/helpers';
import CreditoEmListTable from './CreditoEmListTable';


class CreditoEmList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      activeEstado: undefined,
      date: moment(),
      warningOpen: false,
    };
    this.url = `${BASE_URL}${API.estados_mensuales}`;
    this.handlerAsyncDownload = this.handlerAsyncDownload.bind(this);
  }

  componentDidMount() {
    const { getEstados, resetData } = this.props;
    resetData();
    getEstados({
      url: this.url,
      type: GET_ESTADO_MENSUAL_LIST,
    });
  }

  componentDidUpdate(prevProps) {
    const { activeEstado } = this.state;
    if (activeEstado) {
      const updatedEstado = prevProps.estadosMensuales.estadoList.find(
        node => node.id === activeEstado.id);
      if (updatedEstado !== activeEstado) {
        this.handleUpdate();
      }
    }
  }

  handleUpdate = () => {
    this.setState({
      warningOpen: false,
      activeEstado: undefined,
    });
  };

  handleDate = (date) => {
    const { getEstados } = this.props;
    if (date <= moment()) {
      getEstados({
        url: `${this.url}?year=${date.format('YYYY')}`,
        type: GET_ESTADO_MENSUAL_LIST,
      });
      this.setState({ date })
    }
  };

  handlerAsyncDownload = (id=null) => {
    const { toggleLoading } = this.props;
    const url = id ? `${BASE_URL}reporte_cartera_credito/${id}` : `${BASE_URL}reporte_cartera_credito`;
    return get(url).then((response) => {
      const isFileReadyToDownload = response && response.state && response.state === ESTADO_DESCARGA.SUCCESS;
      if (isFileReadyToDownload){
        return fileDownload(
          `${BASE_URL}${response.path}`,
          toggleLoading,
        );
      } 
      
      const isDownloadFailed = response && response.state && response.state === ESTADO_DESCARGA.FAILURE;
      if (isDownloadFailed){
        return this.handlerAsyncDownload();
      }
      
      const isDownloadPending = response && response.task;
      if (isDownloadPending) {
        return setTimeout(() => this.handlerAsyncDownload(response.task), 10000);
      }

      return setTimeout(() => this.handlerAsyncDownload(), 10000);
    })
  };

  handleDownload = () => {
    const { toggleLoading } = this.props;
    this.handlerAsyncDownload();
    toggleLoading();
  };

  handleChangeWarning = (e, node) => {
    const { warningOpen } = this.state;
    e.preventDefault();
    this.setState({
      warningOpen: !warningOpen,
      activeEstado: !warningOpen ? node : undefined,
    })
  };

  handleEstadoChange = (node, onFailedReq = () => {} ) => {
    const { estadoChange } = this.props;
    estadoChange({
      url: `${this.url}${node.id}/`,
      type: ESTADO_MENSUAL_CHANGE,
      onFailedReq,
      config: { activo: !(node.activo) }
    });
  };

  render() {
    const {
      activeEstado,
      date,
      warningOpen,
    } = this.state;
    const { estadosMensuales: { loading } } = this.props;
    return (
      <PageContainer
        breadcrumbs={[
          { id: 1, name: 'CRÉDITO', url: '/reservas/credito/estados_mensuales' },
          { id: 2, name: 'CIERRES MENSUALES' }
        ]}
      >
        <EstadoFilter
          date={date}
          handleDate={this.handleDate}
          handleDownload={this.handleDownload}
          loading={loading}
          creditMonthly
        />
        <CreditoEmListTable
          activeEstado={activeEstado}
          handleChangeWarning={this.handleChangeWarning}
          modalOpen={warningOpen}
        />
        {activeEstado && (
          <DeprecatedWarningModal
            handleModal={this.handleChangeWarning}
            handleSubmit={this.handleEstadoChange}
            node={activeEstado}
            modalOpen={warningOpen}
            year
          />
        )}
      </PageContainer>
    )
  }
}

CreditoEmList.propTypes = {
  estadoChange: PropTypes.func.isRequired,
  estadosMensuales: PropTypes.shape({
    estadoList: PropTypes.arrayOf(PropTypes.object),
    loading: PropTypes.bool,
  }).isRequired,
  getEstados: PropTypes.func.isRequired,
  toggleLoading: PropTypes.func.isRequired,
  resetData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  estadosMensuales: state.estadosMensuales
});

const mapDispatchToProps = (dispatch) => ({
  resetData: policy => dispatch(cleanEstadoDiario(policy)),
  toggleLoading: () => dispatch(handleLoading()),
  getEstados: policy => dispatch(getData(policy)),
  estadoChange: policy => dispatch(patchData(policy)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreditoEmList);
