import {
  CLEAN_ESTADO_DIARIO,
  LOADING_CHANGE,
  TOKEN_ERROR
} from '../constants/action-types';
import auth from '../../helpers/auth';

export function alertModal(payload) {
  return { type: TOKEN_ERROR, payload }
}

export function handleLoading() {
  return { type: LOADING_CHANGE }
}

export function cleanEstadoDiario(payload) {
  return { type: CLEAN_ESTADO_DIARIO, payload };
}

const handleError = (payload, props) => ({ type: 'ERROR', payload, error_type: props.type });

export const getData = (object) => {
  return (dispatch) => {
    return auth.fetch(object.url)
      .then(response => dispatch({ type: object.type, payload: response }));
  };
};

export const postData = object => {
  return (dispatch) => {
    return auth.fetch(object.url, {
      method: 'POST',
      body: JSON.stringify(object.config),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => dispatch({ type: object.type, payload: response }));
  };
};

export const patchData = object => {
  return (dispatch) => {
    return auth.fetch(object.url, {
      method: 'PATCH',
      body: JSON.stringify(object.config),
      headers: { 'Content-Type': 'application/json' },
    })
      .then(response => dispatch({ type: object.type, payload: response }))
      .catch((error) => {
        if (object.onFailedReq) {
          object.onFailedReq();
        }
        dispatch(handleError(error.response ? error.response.data : undefined, object));
      });
  };
};

export const putData = object => {
  return (dispatch) => {
    return auth.fetch(object.url, {
      method: 'PUT',
      body: JSON.stringify(object.config.body),
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(response => dispatch({ type: object.type, payload: response }))
    .catch(error => error.json()
      .then(errorData => dispatch(handleError(errorData, object)))
    );
  };
};

export const uploadFile = object => {
  return (dispatch) => {
    return auth.fetch(object.url, {
      method: 'PUT',
      body: object.config.body,
      headers: {
        'Content-Disposition': `attachment; filename="${encodeURI(object.config.name)}"`
      }
    })
    .then(response => dispatch({ type: object.type, payload: response }))
    .catch(error => error.json()
      .then(errorData => dispatch(handleError(errorData, object)))
    );
  };
};
