import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Breadcrumbs from '../Breadcrumbs';
import TimeDate from '../TimeDate';

const PageContainer = ({ children, breadcrumbs }) => (
  <div>
    <TimeDate />
    <Breadcrumbs arrayMap={breadcrumbs} />
    {children}
    <div className="footer text-left">
      {`© ${moment().year()} ORSAN. Encomenderos 113, Of. 801 Las Condes.`}
    </div>
  </div>
);

PageContainer.propTypes = {
  children: PropTypes.node.isRequired,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
     name: PropTypes.string,
     url: PropTypes.string,
   }),
  ).isRequired,
};

export default PageContainer;
