import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { cleanEstadoDiario, getData } from '../../../redux-store/actions';
import { GET_ESTADO_MENSUAL } from "../../../redux-store/constants/action-types";
import { API, BASE_URL } from '../../../CONST';
import PageContainer from '../../UI/PageContainer';
import EstadoMDetailResumen from './EstadoMDetailResumen';
import EstadoMDetailMovimientos from './EstadoMDetailMovimientos';

const moment = require('moment');

function mapDispatchToProps(dispatch) {
  return {
    resetData: policy => dispatch(cleanEstadoDiario(policy)),
    getEstados: policy => dispatch(getData(policy)),
  }
}

class EstadoMDetail extends Component {
  componentDidMount() {
    const { getEstados, idToken, match, resetData } = this.props;
    const { id } = match.params;
    resetData();
    getEstados({
      url:`${BASE_URL}${API.estados_mensuales}${id}`,
      token: idToken,
      type: GET_ESTADO_MENSUAL,
    });
  }

  render() {
    const { match } = this.props;
    const { date, id } = match.params;
    return(
      <PageContainer
        breadcrumbs={[
          { id: 1,name: 'GARANTÍA', url: '/reservas/garantia/estados_diarios' },
          { id: 2,name: 'CIERRES MENSUALES', url: '/reservas/garantia/estados_mensuales' },
          { id: 3,name: `CIERRE ${moment(date).format('MM-YYYY')}`},
        ]}
      >
        <EstadoMDetailResumen />
        <EstadoMDetailMovimientos id={id} />
      </PageContainer>
    )
  }
}

const mapStateToProps = state => {
  return {
    idToken: state.login.id_token,
  }
};

EstadoMDetail.propTypes = {
  getEstados: PropTypes.func.isRequired,
  idToken: PropTypes.string.isRequired,
  login: PropTypes.shape({
    user_token: PropTypes.string,
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      date: PropTypes.string,
      id: PropTypes.string,
    }),
  }).isRequired,
  resetData: PropTypes.func.isRequired,
};

EstadoMDetail.defaultProps = {
  login: undefined,
};

export default connect(mapStateToProps, mapDispatchToProps)(EstadoMDetail);

