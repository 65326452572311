import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { ORSAN_LOGO_URL } from 'CONST';
import ReaseguroNavBar from './navegacion/ReaseguroNavBar';
import ReservaNavBar from './navegacion/ReservaNavBar';
import { signout } from '../helpers/auth';
import '../styles/navbar.css';

class BaseNavbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      app: 'reserva',
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const app = location.pathname.split('/')[1];
    this.setState({ app });
  }

  handleSignOut = (event) => {
    event.preventDefault();
    signout();
  };

  render() {
    const { user } = this.props;
    const { app } = this.state;

    return (
      user && (
        <>
          <nav className="navbar-content">
            <div className="nav-logo-container">
              <Link to={app === 'reaseguros' ? "/reaseguros" : "/reservas"}>
                <img src={ORSAN_LOGO_URL} className="nav-logo" alt="" />
              </Link>
            </div>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr auto' }}>
              {app === 'reaseguros' ?
                <ReaseguroNavBar /> :
                <ReservaNavBar />
              }
              <ul className="nav-links-group">
                <li className="nav-link-element nav-ite dropdown">
                  <p className="nav-link">{user ? user.username : ''}</p>
                </li>
                <li className="nav-link-element nav-item dropdown">
                  <Button className="nav-link" onClick={this.handleSignOut}>
                    Cerrar Sesión
                  </Button>
                </li>
              </ul>
            </div>
          </nav>
        </>
      )
    );
  }
}

BaseNavbar.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  user: PropTypes.shape({
    email: PropTypes.string,
    username: PropTypes.string,
    full_name: PropTypes.string,
    has_access: PropTypes.bool,
    apps: PropTypes.arrayOf(PropTypes.string),
    organization: PropTypes.number,
    sub: PropTypes.string,
    profiles: PropTypes.array,
  }).isRequired,
};

const mapStateToProps = (state) => ({ user: state.login.user });

export default withRouter(connect(mapStateToProps)(BaseNavbar));
