import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import moment from 'moment';
import { get } from 'helpers/requests';
import Estado from 'components/UI/Estado';

const EmisionesDiariasResumen = ({ endpoint }) => {
  const [detalleEstado, setDetalleEstado] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    get(`${endpoint}${id}`).then((response) => setDetalleEstado(response));
  }, []);

  return (
    <div className="resumen-container">
      <h5 className="title">Resumen</h5>
      <div className="resumen-wrapper">
        <Row>
          <Col>
            <Row className="resumen-row">
              <Col xs="1" className="label">
                Estado:
              </Col>
              <Col xs="2" className="value d-inline-flex">
                <Estado estado={detalleEstado ? detalleEstado.estado : ''} />
              </Col>
            </Row>
            <Row className="resumen-row">
              <Col xs="1" className="label">
                Sincronización:
              </Col>
              <Col className="value">
                {detalleEstado &&
                  `${detalleEstado.sincronizacion} - ${moment(
                    detalleEstado.actualizacion_ts,
                  ).format('DD-MM-YYYY HH:mm')}`}
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

EmisionesDiariasResumen.propTypes = {
  endpoint: PropTypes.string.isRequired,
};

export default EmisionesDiariasResumen;
