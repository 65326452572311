import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment'
import Estado from '../../UI/Estado';

const CierreOpDetailResumen = ({ detalleEstado }) => (
  <div className="resumen-container">
    <h5 className="title">Resumen</h5>
    <div
      style={{ display: 'grid', gridTemplateColumns: '130px auto', gridGap: '5px' }}
      className="resumen-wrapper"
    >
      <div className="label">
        Estado:
      </div>
      <div className="value d-inline-flex">
        <Estado estado={detalleEstado ? detalleEstado.estado : ''} />
      </div>
      <div className="label">
        Sincronización:
      </div>
      <div className="value">
        {detalleEstado && (
          `${detalleEstado.sincronizacion} - ${moment(detalleEstado.actualizacion_ts).format('DD-MM-YYYY HH:mm')}`
        )}
      </div>
    </div>
  </div>
);

CierreOpDetailResumen.propTypes = {
  detalleEstado: PropTypes.shape({
    estado: PropTypes.string,
    sincronizacion: PropTypes.string,
    actualizacion_ts: PropTypes.string,
  }),
};

CierreOpDetailResumen.defaultProps = {
  detalleEstado: undefined,
};

const mapStateToProps = state => ({
  detalleEstado: state.estados.detalleEstado,
});

export default connect(mapStateToProps)(CierreOpDetailResumen);
